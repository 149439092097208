export const formatToIntegerWithCommas = (numberString: string | null) => {
    if (numberString === null || Math.floor(parseFloat(numberString)) === 0) {
        return "-";
    } else {
        let number = parseFloat(numberString);
        let integerNumber = Math.floor(number);
        let formattedNumber = integerNumber.toLocaleString("en-US");
        return formattedNumber;
    }
};
