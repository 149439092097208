import { memo } from "react";
import styled from "styled-components";

const MobileDistanceController = memo(({ controlList, distance, onChangeDistance }) => {
     return (
          <ItemDistanceWrap>
               {controlList.map(item => (
                    <ItemDistance key={`${item.text}-${item.value}`} active={item.value === distance} onClick={onChangeDistance(item.value)}>
                         {item.text}
                    </ItemDistance>
               ))}
          </ItemDistanceWrap>
     );
});

export default MobileDistanceController;

const ItemDistanceWrap = styled.div`
     display: flex;
     flex-direction: column;
     gap: 20px;
`;

const ItemDistance = styled.div`
     box-sizing: border-box;
     width: 200px;
     height: 44px;
     background-color: #ffffff;
     border-radius: 8px;
     border: 1px solid #dadce0;
     display: flex;
     justify-content: center;
     align-items: center;
     font-size: 12px;
     font-style: normal;
     font-weight: 600;
     line-height: normal;
     letter-spacing: 0.12px;
     color: ${({ active }) => (active ? "#3D4043" : "rgba(61, 64, 67, 0.50)")};
`;
