import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
	getReviewInfoApi,
	getReviewStatusApi,
} from '../api/store';
import aixos, { AxiosError } from 'axios';

export const reviewInfo = createAsyncThunk(
	'getReviewInfo',
	async (
		{ account }: { account: string },
		{ rejectWithValue }
	) => {
		try {
			const result = await getReviewInfoApi({ account });
			return { reviewInfo: result?.data };
		} catch (err) {
			const errors = err as Error | AxiosError;
			if (!aixos.isAxiosError(errors)) {
				return rejectWithValue('An error occurred');
			}
			console.log("errors >> ", errors.response?.data);
			return rejectWithValue(errors.response?.data);

		}
	}
);

export const reviewStatus = createAsyncThunk(
	'getPartsReviewStatus',
	async (
		{ account }: { account: string },
		{ rejectWithValue }
	) => {
		try {
			const result = await getReviewStatusApi({ account });
			return { reviewStatus: result.data?.data };
		} catch (err) {
			const errors = err as Error | AxiosError;
			if (!aixos.isAxiosError(errors)) {
				return rejectWithValue('An error occurred');
			}
			return rejectWithValue(errors.response?.data);
		}
	}
);

export type ReviewCardType = {
	bizpl_nm: string;
	bizpl_cd: string;
	part_mng_id: string;
	clova_sentiment: string;
	author_nickname: string;
	author_created: string;
	body: string;
	visit_count: number;
	author_visited: string;
}

export interface ReviewStoreType {
	status: string | null;
	team_nm: string | null;
	kor_name: string | null;
	review_count: number | null;
	positive: number | null;
	neutral: number | null;
	negative: number | null;
	match_yn: string | null;
	reviewList: ReviewCardType[] | null;
	reviewStatus: ReviewStatus[] | null;
}

export type ReviewStatus = {
	filter_nm: string | null;
	filter_id: string | null;
	positive: number | null;
	neutral: number | null;
	negative: number | null;
	match_yn: string | null;
}

const initialState: ReviewStoreType = {
	status: null,
	team_nm: null,
	kor_name: null,
	review_count: null,
	positive: null,
	neutral: null,
	negative: null,
	match_yn: null,
	reviewList: null,
	reviewStatus: null,
};

export const reviewSlice = createSlice({
	name: 'review',
	initialState,
	reducers: {
		reviewInfoReset: (state: ReviewStoreType, action: PayloadAction) => {
			state.status = null;
			state.team_nm = null;
			state.kor_name = null;
			state.review_count = null;
			state.positive = null;
			state.neutral = null;
			state.negative = null;
			state.match_yn = null;
			state.reviewList = null;
		},
		reviewCardReset: (state: ReviewStoreType) => {
			state.reviewList = null;
		},
		sortCreatedDesc: (state: ReviewStoreType) => {
			state.reviewList?.sort((a, b) => {
				return new Date(b.author_created).getTime() - new Date(a.author_created).getTime();
			});
		},
		sortBizplNmAsc: (state: ReviewStoreType) => {
			state.reviewList?.sort((a, b) => {
				return a.bizpl_nm.localeCompare(b.bizpl_nm);
			});
		}
	},
	extraReducers: {
		[reviewInfo.pending.type]: () => {},
		[reviewInfo.fulfilled.type]: (state, { payload }) => {
			state.status = payload.reviewInfo.resStatus;
			state.team_nm = payload.reviewInfo.data.team_nm;
			state.kor_name = payload.reviewInfo.data.kor_name;
			state.review_count = payload.reviewInfo.data.review_count;
			state.positive = payload.reviewInfo.data.positive;
			state.neutral = payload.reviewInfo.data.neutral;
			state.negative = payload.reviewInfo.data.negative;
			state.match_yn = payload.reviewInfo.data.match_yn;
			state.reviewList = payload.reviewInfo.data.reviewList;
		},
		[reviewInfo.rejected.type]: () => {},
		[reviewStatus.pending.type]: () => {},
		[reviewStatus.fulfilled.type]: (state, { payload }) => {
			state.reviewStatus = payload.reviewStatus;
		},
		[reviewStatus.rejected.type]: () => {},
	},
});

export const { reviewInfoReset, reviewCardReset, sortCreatedDesc, sortBizplNmAsc } = reviewSlice.actions;

export default reviewSlice.reducer;
