import styled from "@emotion/styled";

export const SearchBarWrap = styled.div`
     box-sizing: border-box;
     width: 380px;
     height: 100%;
     padding: 0px 0px 0;
     transition: all 350ms;
     display: flex;
     flex-direction: column;

     svg {
          vertical-align: middle;
     }
     .section {
          border-bottom: 1px solid #e5e7eb;
     }
     .search-header {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 22px;
     }
     .search-input-section {
          overflow: hidden;
     }
     .search-input-wrap {
          box-sizing: border-box;
          width: 100%;
          padding: 8px 8px 8px 32px;
          border: 1px solid #9e9e9e;
          box-sizing: border-box;
          border-radius: 1000px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 15px;

          input {
               width: 100%;
               font-size: 16px;
               line-height: 16px;
               font-weight: 500;
               border-width: 0;
               flex: 1;

               /* margin-right: 20px; */
               &::placeholder {
                    color: #9e9e9e;
               }
          }
          button {
               display: flex;
               align-items: center;
               justify-content: center;
               width: 48px;
               height: 48px;
               background-color: #0c4284;
               border-radius: 100%;
               flex-shrink: 0;

               > svg {
                    width: 18px;
                    height: 18px;
               }
               > svg path {
                    stroke: #fff;
               }
          }
     }

     #search-list {
          flex: 1;
          height: 100%;
          overflow-y: auto;
          padding-top: 15px;
          &::-webkit-scrollbar {
               width: 6px;
               height: 6px;
               background: #ffffff;
          }
          &::-webkit-scrollbar-thumb {
               border-radius: 3.5px;
               background-color: #ced4da;

               &:hover {
                    background-color: #adb5bd;
               }
          }
          &::-webkit-scrollbar-track {
               background: #ffffff;
          }
     }

     .search-item {
          cursor: pointer;
          padding: 20px 20px;
          border-bottom: 1px solid #e5e7eb;
     }
     /* .search-item:hover {
		background: #f7f9fa;
	} */
     /* .search-item:last-child {
			border-width: 0;
		} */
     .search-item-title {
          display: flex;
          align-items: flex-end;
     }
     .search-item-title h2 {
          font-size: 18px;
          font-weight: bold;
          margin-right: 8px;
          line-height: 18px;
     }
     .search-item-title .dst {
          font-size: 14px;
          line-height: 14px;
          color: #3e85e8;
          white-space: nowrap;
     }
     .search-item-title .dst svg {
          width: 14px;
          height: 17px;
          vertical-align: bottom;
     }
     .search-item-sub .category {
          display: inline-block;
          color: #777777;
     }
     .search-item-sub .phone {
          color: #777777;
          &:before {
               display: inline-block;
               width: 1px;
               height: 8px;
               background-color: #e2e2e2;
               margin: -1px 6px 1px;
               vertical-align: -1px;
               content: "";
          }
     }
     .search-item-sub .address {
          color: #969696;
     }
`;
