import styled from "@emotion/styled";

export const SidebarTobaccoStoreListWrap = styled.div`
    flex: 1;
    height: 90%;
    overflow-y: auto;

    padding-top:15px;
    padding-bottom:100px;
    width:380px;
    box-sizing:border-box;
    

    li {
    list-style: none;
    }


    li:hover{
      background:#F0F3F8;
    }

    .active{
      background:#F0F3F8;
    }



    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background: #ffffff;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3.5px;
      background-color: #ced4da;

      &:hover {
        background-color: #adb5bd;
      }
    }
    &::-webkit-scrollbar-track {
      background: #ffffff;
    }
  }

  .search-item {
    cursor: pointer;
    padding: 12px 26px 12px 20px !important;
    border-bottom: 1px solid #e5e7eb;
  }
  /* .search-item:hover {
		background: #f7f9fa;
	} */
  /* .search-item:last-child {
			border-width: 0;
		} */
  .search-item-title {
    display: flex;
    align-items: flex-end;

  }
  .search-item-title h2 {
    font-size: 18px;
    font-weight: bold;
    margin-right: 8px;
    line-height: 18px;
  }
  .search-item-title .dst {
    font-size: 14px;
    line-height: 14px;
    color: #3e85e8;
    white-space: nowrap;
  }
  .search-item-title .dst svg {
    width: 14px;
    height: 17px;
    vertical-align: bottom;
  }
  .search-item-sub .category {
    display: inline-block;
    color: #777777;
  }
  .search-item-sub .phone {
    color: #777777;
    &:before {
      display: inline-block;
      width: 1px;
      height: 8px;
      background-color: #e2e2e2;
      margin: -1px 6px 1px;
      vertical-align: -1px;
      content: '';
    }
  }
  .search-item-sub .address {
    color: #969696;
  }
`;
