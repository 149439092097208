const IconTobaccoFavorite = ({ active = false, size = "16" }) => {
     const color = active ? "#FFB316" : "rgba(61, 64, 67, 0.50)";
     return (
          <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                    d="M22 9.67002C21.9368 9.48711 21.822 9.32645 21.6693 9.20753C21.5167 9.0886 21.3328 9.01652 21.14 9.00002L15.45 8.17002L12.9 3.00002C12.8181 2.83095 12.6902 2.68837 12.5311 2.5886C12.3719 2.48883 12.1878 2.43591 12 2.43591C11.8121 2.43591 11.6281 2.48883 11.4689 2.5886C11.3097 2.68837 11.1819 2.83095 11.1 3.00002L8.54998 8.16002L2.85998 9.00002C2.6749 9.02633 2.5009 9.10399 2.35773 9.22418C2.21455 9.34438 2.10794 9.5023 2.04998 9.68002C1.99692 9.8537 1.99216 10.0385 2.03621 10.2147C2.08025 10.3909 2.17144 10.5517 2.29998 10.68L6.42998 14.68L5.42998 20.36C5.39428 20.5475 5.41297 20.7413 5.48385 20.9185C5.55473 21.0957 5.67483 21.2489 5.82998 21.36C5.98119 21.4681 6.15955 21.5319 6.34503 21.5443C6.5305 21.5567 6.71575 21.5171 6.87998 21.43L12 18.76L17.1 21.44C17.2403 21.5192 17.3988 21.5606 17.56 21.56C17.7718 21.5608 17.9784 21.4942 18.15 21.37C18.3051 21.2589 18.4252 21.1057 18.4961 20.9285C18.567 20.7513 18.5857 20.5575 18.55 20.37L17.55 14.69L21.68 10.69C21.8244 10.5677 21.9311 10.4069 21.9877 10.2264C22.0444 10.0458 22.0486 9.8529 22 9.67002ZM15.85 13.67C15.7327 13.7835 15.645 13.9239 15.5944 14.079C15.5439 14.2341 15.532 14.3993 15.56 14.56L16.28 18.75L12.52 16.75C12.3753 16.673 12.2139 16.6327 12.05 16.6327C11.8861 16.6327 11.7247 16.673 11.58 16.75L7.81998 18.75L8.53998 14.56C8.56791 14.3993 8.55609 14.2341 8.50554 14.079C8.45499 13.9239 8.36725 13.7835 8.24998 13.67L5.24998 10.67L9.45998 10.06C9.62198 10.0375 9.77598 9.97556 9.90848 9.87967C10.041 9.78379 10.1479 9.65686 10.22 9.51002L12 5.70002L13.88 9.52002C13.952 9.66686 14.059 9.79379 14.1915 9.88968C14.324 9.98556 14.478 10.0475 14.64 10.07L18.85 10.68L15.85 13.67Z"
                    fill={color}
               />
          </svg>
     );
};

export default IconTobaccoFavorite;
