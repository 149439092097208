import { memo } from "react";
import { ItemMobileFilter, MobileHomeTobaccoFilterWrap } from "./PcHomeFilter.styled";
import IconTobaccoOpen from "../MobileFilterV2/svgComponents/IconTobaccoOpen";
import IconTobaccoSuspended from "../MobileFilterV2/svgComponents/IconTobaccoSuspended";
import IconTobaccoClosed from "../MobileFilterV2/svgComponents/IconTobaccoClosed";
import IconTobaccoFavorite from "../MobileFilterV2/svgComponents/IconTobaccoFavorite";

const FILTERS = [
     {
          name: "즐겨찾기",
          active: filterOption => filterOption?.showFavorite,
          icon: active => <IconTobaccoFavorite active={active} />,
          color: "#FFB316",
          key: "favorite",
     },
     {
          name: "정상엽업",
          active: filterOption => filterOption?.showOpenStore && !filterOption?.showFavorite,
          icon: active => <IconTobaccoOpen active={active} />,
          color: "#216CF0",
          key: "open",
     },
     {
          name: "휴업",
          active: filterOption => filterOption?.showSuspendedStore && !filterOption?.showFavorite,
          icon: active => <IconTobaccoSuspended active={active} />,
          color: "#797979",
          key: "suspended",
     },
     {
          name: "폐업 3개월 이내",
          active: filterOption => filterOption?.includeClosedWithin > 2 && !filterOption?.showFavorite,
          icon: active => <IconTobaccoClosed active={active} />,
          color: "#FF0000",
          key: "closed3",
     },
];

const PcHomeTobaccoFilter = memo(({ tobaccoFilterOption, onClickChangeFilterOption, onClickFavorite, sidebarOpen }) => {
     return (
          <MobileHomeTobaccoFilterWrap sidebarOpen={sidebarOpen}>
               {FILTERS.map((el, i) => {
                    const active = el.active(tobaccoFilterOption);
                    const borderColor = active ? el.color : "rgba(61, 64, 67, 0.50)";
                    const textColor = active ? "#3d4043" : "rgba(61, 64, 67, 0.50)";
                    return (
                         <ItemMobileFilter
                              onClick={() => {
                                   if (el.key === "favorite") {
                                        onClickFavorite(el.key);
                                   } else {
                                        onClickChangeFilterOption(el.key);
                                   }
                              }}
                              color={borderColor}
                              textColor={textColor}
                              key={`filter-${i}-${el.color}`}
                         >
                              {el.icon(active)} {el.name}
                         </ItemMobileFilter>
                    );
               })}
          </MobileHomeTobaccoFilterWrap>
     );
});

export default PcHomeTobaccoFilter;
