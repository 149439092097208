import styled from "@emotion/styled";

export const MobileHomeTobaccoFilterWrap = styled.div`
     position: fixed;
     z-index: 2;
     display: flex;
     gap: 8px;
     top: 73px;
     left: 10px;
     width: 100%;
     overflow-x: auto; /* 스크롤 자동 생성 */
     white-space: nowrap; /* 자식 요소가 줄바꿈되지 않도록 설정 */
     scrollbar-width: none;
     background: transparent;

     &::-webkit-scrollbar {
          background: transparent;
     }
`;

interface IItemMobileFilter {
     color: boolean;
     textColor: string;
}

export const ItemMobileFilter = styled.div<IItemMobileFilter>`
     box-sizing: border-box;
     padding: 9px 13px 9px 8px;
     display: flex;
     justify-content: center;
     align-items: center;
     background: #ffffff;
     border-radius: 8px;
     gap: 4px;
     height: 28px;

     border: ${({ color }) => `1px solid ${color}`};
     color: ${({ textColor }) => textColor};
     font-size: 12px;
     font-weight: 500;

     svg {
          width: 16px;
          height: 16px;
     }
`;
