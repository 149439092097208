import { useState, useCallback } from "react";
import styled from "styled-components";

import { IMAGES_CODE, SUB_CATEGORY } from "../../data/code";
const BrandDistanceList = ({ handle = false, brand, value }) => {
     const [open, setOpen] = useState(handle);

     const onClickOpen = useCallback(() => {
          setOpen(prev => !prev);
     }, []);

     const onMouseLeaveList = useCallback(
          name => () => {
               const marker = document.getElementById(`${name}`);
               marker && marker.parentElement.classList.remove("hover");
          },
          [],
     );

     const onMouseEnterList = useCallback(
          name => () => {
               const marker = document.getElementById(`${name}`);
               marker && marker.parentElement.classList.add("hover");
          },
          [],
     );

     const getBrandImage = (imageSrc, brand) => {
          if (imageSrc) return <img src={imageSrc} alt={SUB_CATEGORY[brand]} />;

          const isStore = brand[1] === "1";
          const isMarket = brand[1] === "2";
          const isOil = brand[1] === "4";
          const isFood = brand[1] === "3";

          return (
               <div style={{ width: 26, height: 26, background: "transparent", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img
                         className="brand-other-icon"
                         src={`img/icons/svg/color_${isStore ? "store" : isMarket ? "mart-station" : isOil ? "gas-station" : isFood ? "side-dish-station" : "meat"}.svg`}
                         alt={brand}
                    />
               </div>
          );
     };

     return (
          <BrandDistanceListWrap>
               {value.length > 0 && (
                    <div>
                         <div className="store-brand-other">
                              <ItemAbout onClick={onClickOpen} open={open}>
                                   <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
                                        <ImageWrap>{getBrandImage(IMAGES_CODE[SUB_CATEGORY[brand]], brand)}</ImageWrap>
                                        {SUB_CATEGORY[brand]} ( {value.length} )
                                   </div>
                                   <button>
                                        {open ? (
                                             <img src={"img/icons/svg/arrow-up.svg"} alt="arrow-up" />
                                        ) : (
                                             <img src={"img/icons/svg/arrow-down.svg"} alt="arrow-down" />
                                        )}
                                   </button>
                              </ItemAbout>
                              {open && (
                                   <>
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                             <Line />
                                        </div>

                                        <ul className="store-brand-other-detail">
                                             {value.map((store, index) => (
                                                  <li
                                                       key={brand + index}
                                                       onMouseLeave={onMouseLeaveList(store.name)}
                                                       onMouseEnter={onMouseEnterList(store.name)}
                                                  >
                                                       <div>{store.name}</div>
                                                       <span>{store.dst.toLocaleString()} m</span>
                                                  </li>
                                             ))}
                                        </ul>
                                   </>
                              )}
                         </div>
                    </div>
               )}
          </BrandDistanceListWrap>
     );
};

export default BrandDistanceList;

const Line = styled.div`
     height: 1px;
     width: calc(100% - 16px);
     background-color: #bdbdbd;
`;

const ImageWrap = styled.div`
     width: 26px;
     height: 26px;
     overflow: hidden;
     border-radius: 26px;
     border: 1.5px solid #868782;

     .brand-other-icon {
          width: 12px !important;
          height: 12px !important;
     }

     img {
          width: 26px;
          height: 26px;
     }
`;

const ItemAbout = styled.div`
     cursor: pointer;
     box-sizing: border-box;
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 12px 16px;
     font-weight: bold;
     font-size: 14px;
     height: 52px;

     border-top-left-radius: 10px;
     border-top-right-radius: 10px;
     border-bottom-left-radius: ${({ open }) => (open ? "0px" : "10px")};
     border-bottom-right-radius: ${({ open }) => (open ? "0px" : "10px")};

     background: #fff;

     color: #1e2530;
     text-overflow: ellipsis;
     font-size: 14px;
     font-style: normal;
     font-weight: 600;
     line-height: normal;
`;

const BrandDistanceListWrap = styled.div`
     .store-brand-other {
          margin-bottom: 10px;
     }

     .brand-image {
          svg {
               width: 12px;
               height: 12px;
          }
     }

     .store-brand-other-detail {
          background-color: #fff;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          li {
               padding: 14px 20px;

               overflow: hidden;
               color: #1e2530;
               text-overflow: ellipsis;
               font-size: 14px;
               font-style: normal;
               font-weight: 400;
               line-height: normal;
               display: flex;
               align-items: center;
               justify-content: space-between;
               > span {
                    color: #3e85e8;
               }
          }
     }
`;
