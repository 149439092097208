import { Link, useLocation } from "react-router-dom";
import { userInfo } from "../../reducers/user";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { PcSidebarWrap } from "./PcSideBar.styled";

import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import IconStore from "../mobileBottomMenu/IconComponents/IconStore";
import IconCigarette from "../mobileBottomMenu/IconComponents/IconCigarette";
import IconReviews from "../mobileBottomMenu/IconComponents/IconReveiws";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../auth/authConfig";
import UserPhoto from "../userPhoto/UserPhoto";

interface PcSidebarProps {
     children?: React.ReactNode;
     open?: boolean;
     onChangeSideBar?: () => void;
}
const msalInstance = new PublicClientApplication(msalConfig);

const PcSidebar = ({ children, open, onChangeSideBar }: PcSidebarProps) => {
     let location = useLocation();

     const dispatch = useDispatch();
     const userInfoData = useSelector((state: any) => state.user);
     const { accounts } = useMsal();

     useEffect(() => {
          if (userInfoData?.kor_nm == null) {
               dispatch(userInfo({ account: accounts[0]["username"], kor_nm: accounts[0]["name"] }));
          }
     }, [accounts, dispatch, userInfoData.kor_nm, userInfoData]);

     return (
          <PcSidebarWrap open={open}>
               <div className="main-bar">
                    <h1>
                         <Link to="/">
                              <img alt="GS리테일" src="/sidebar-logo.png" />
                         </Link>
                    </h1>
                    <nav>
                         <ul>
                              <li>
                                   <Link to="/" className={location.pathname === "/" ? "active" : ""}>
                                        <IconStore active={location.pathname === "/"} />
                                        점포 지도
                                   </Link>
                              </li>
                              <li>
                                   <Link to="/tobacco" className={location.pathname === "/tobacco" ? "active" : ""}>
                                        <IconCigarette active={location.pathname === "/tobacco"} />
                                        담배권 지도
                                   </Link>
                              </li>

                              {userInfoData?.team_mng_yn === "Y" || userInfoData?.part_mng_yn === "Y" || userInfoData?.sm_mng_yn === "Y" ? (
                                   <li>
                                        <Link to="/report" className={location.pathname === "/report" ? "active" : ""}>
                                             <IconReviews active={location.pathname === "/report"} /> 담당점포 리뷰
                                        </Link>
                                   </li>
                              ) : null}
                         </ul>
                    </nav>
                    <div className="side-other">
                         <UserPhoto />
                         <div>{`${userInfoData.kor_nm}`}</div>
                         <div className="sign-out" onClick={() => msalInstance.logoutRedirect()}>
                              <img src="img/icons/svg/u_sign-out.svg" width={26} alt="sign-out" />
                         </div>
                    </div>
               </div>

               <>
                    <div className="sub-bar">{children}</div>
                    {location.pathname !== "/report" && (
                         <div className="change-open-btn" onClick={onChangeSideBar}>
                              <img
                                   style={{
                                        transform: open ? "rotate(0deg)" : "rotate(180deg)",
                                        transition: "transform 0.3s ease",
                                   }}
                                   src="img/icons/arrow-left.png"
                                   width={30}
                                   height={30}
                                   alt="arrow-open-close"
                              />
                         </div>
                    )}
               </>
          </PcSidebarWrap>
     );
};

export default PcSidebar;
