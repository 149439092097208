import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getPostsApi, postPostApi } from '../api/post';

export const getPosts = createAsyncThunk(
	'getPosts',
	async ({ shopId }, { dispatch, getState, rejectWithValue }) => {
		try {
			const result = await getPostsApi(shopId);

			return { postsList: result.data };
		} catch (e) {
			// errorAction();
			return rejectWithValue(e?.response?.data);
		}
	}
);

export const postPost = createAsyncThunk(
	'postPost',
	async (
		{ postData, successAction },
		{ dispatch, getState, rejectWithValue }
	) => {
		try {
			await postPostApi(postData);
			dispatch(getPosts({ shopId: postData['shopId'] }));
			successAction();
			return {};
		} catch (e) {
			// errorAction();
			return rejectWithValue(e?.response?.data);
		}
	}
);

const initialState = {
	postsList: null,
};

export const storeSlice = createSlice({
	name: 'store',
	initialState,
	reducers: {
		write: (state, action) => {
			const { key, value } = action.payload;
			state.check = true;
			state[key] = value;
		},
	},
	extraReducers: {
		[postPost.pending.type]: (state) => {},
		[postPost.fulfilled.type]: (state, { payload }) => {
			state.storeList = payload.recordset;
		},
		[postPost.rejected.type]: (state) => {},
		[getPosts.pending.type]: (state) => {},
		[getPosts.fulfilled.type]: (state, { payload }) => {
			state.postsList = payload.postsList;
		},
		[getPosts.rejected.type]: (state) => {},
	},
});

// export const { write, initCheck, init } = storeSlice.actions;

export default storeSlice.reducer;
