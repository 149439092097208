import { useState, useMemo, useCallback, useEffect } from "react";
import {
     MobileTobaccoDirectionWrap,
     DirectionResultWrap,
     MobileTobaccoDirectionBtnWrap,
     DirectionSearchWrap,
     TobaccoSearchResultWrap,
     ItemTobaccoSearchResult,
} from "./MobileDirection.styled";
import { Input } from "@mui/material";
import debounce from "lodash/debounce";
import { postStoreSearchApi } from "../../api/store";
import { INITIAL_LAT, INITIAL_LNG } from "../../constants/map";
import IconDirectionArrow from "../MobileFilterV2/svgComponents/IconDirectionArrow";
import IconMap from "../MobileFilterV2/svgComponents/IconMap";

const { naver } = window;

const MobileDirection = ({
     map,
     onClickCloseDirectionWithPoint,
     directionDrawerClose,
     account,
     searchCodeFilter,
     directionStart,
     directionEnd,
     setDirectionStart,
     setDirectionEnd,
     handleClickDirectionWithPoint,
     directionDrawerSearchMode,
     setDirectionDrawerSearchMode,
     showDirectionPointMessage,
}) => {
     const [searchValue, setSearchValue] = useState("");
     const [searchResult, setSearchResult] = useState(null);

     const handleClickDirection = () => {
          if (directionStart.coordinate && directionEnd.coordinate) {
               const url = `http://map.naver.com/index.nhn?slng=${directionStart.coordinate.x}&slat=${directionStart.coordinate.y}&stext=${directionStart.name ?? "출발지"}&elng=${directionEnd.coordinate.x}&elat=${directionEnd.coordinate.y}&etext=${directionEnd.name ?? "도착지"}&menu=route&pathType=3`;
               window.open(url, "_blank");
          }
     };

     const fetchSearchResults = async query => {
          if (!query.trim()) {
               setSearchResult(null);
               return;
          }

          const category = Object.entries(searchCodeFilter).reduce((acc, [key, value]) => {
               if (value?.length > 0) {
                    acc.push({ code: key, sub_code: value });
               }
               return acc;
          }, []);

          try {
               const result = await postStoreSearchApi({
                    storeName: query,
                    account,
                    offset: 0,
                    category,
               });
               setSearchResult(result.data.data);
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
               setSearchResult(null);
          }
     };

     const debouncedSearch = useMemo(() => debounce(fetchSearchResults, 300), [searchCodeFilter, account]);

     // 검색어 변경 핸들러
     const handleSearchChange = useCallback(
          e => {
               const query = e.target.value;
               setSearchValue(query);
               debouncedSearch(query);
          },
          [debouncedSearch],
     );

     const handleSelectSearchResult = store => {
          if (directionDrawerSearchMode.type === "START") setDirectionStart({ coordinate: { x: store.x, y: store.y }, name: store.name, isPlace: false });
          if (directionDrawerSearchMode.type === "END") setDirectionEnd({ coordinate: { x: store.x, y: store.y }, name: store.name, isPlace: false });

          setSearchValue("");
          setSearchResult(null);
          setDirectionDrawerSearchMode({ show: false, type: null });
     };

     useEffect(() => {
          return () => {
               debouncedSearch.cancel();
          };
     }, [debouncedSearch]);

     const handleChangeDirection = () => {
          const currentStart = { ...directionStart };
          const currentEnd = { ...directionEnd };

          setDirectionStart(currentEnd);
          setDirectionEnd(currentStart);
     };

     const handleClickSetMyPlace = () => {
          if (navigator?.geolocation) {
               navigator.geolocation.getCurrentPosition(
                    position => {
                         const golocationPosition = new naver.maps.LatLng(position.coords.latitude, position.coords.longitude);
                         map.current.setCenter(golocationPosition);
                         if (directionDrawerSearchMode.type === "START") {
                              setDirectionStart({ coordinate: { x: golocationPosition.x, y: golocationPosition.y }, name: "내 위치", isPlace: true });
                         }
                         if (directionDrawerSearchMode.type === "END") {
                              setDirectionEnd({ coordinate: { x: golocationPosition.x, y: golocationPosition.y }, name: "내 위치", isPlace: true });
                         }
                         setDirectionDrawerSearchMode({ show: false, type: null });
                    },
                    error => {
                         if (directionDrawerSearchMode.type === "START") {
                              setDirectionStart({ coordinate: { x: INITIAL_LNG, y: INITIAL_LAT }, name: "내 위치", isPlace: true });
                              return;
                         }
                         if (directionDrawerSearchMode.type === "END") {
                              setDirectionEnd({ coordinate: { x: INITIAL_LNG, y: INITIAL_LAT }, name: "내 위치", isPlace: true });
                              return;
                         }
                         setDirectionDrawerSearchMode({ show: false, type: null });
                    },
                    {
                         enableHighAccuracy: false,
                         maximumAge: 0,
                         timeout: Infinity,
                    },
               );
          } else {
               if (directionDrawerSearchMode.type === "START") {
                    setDirectionStart({ coordinate: { x: INITIAL_LNG, y: INITIAL_LAT }, name: "내 위치", isPlace: true });
                    return;
               }
               if (directionDrawerSearchMode.type === "END") {
                    setDirectionEnd({ coordinate: { x: INITIAL_LNG, y: INITIAL_LAT }, name: "내 위치", isPlace: true });
                    return;
               }
               setDirectionDrawerSearchMode({ show: false, type: null });
          }
     };

     return (
          <>
               <MobileTobaccoDirectionWrap>
                    <div className="top">
                         {!directionDrawerSearchMode.show && <div>도보거리 계산하기</div>}
                         {directionDrawerSearchMode.show && (
                              <div
                                   onClick={() => {
                                        setDirectionDrawerSearchMode({ show: false, type: null });
                                        onClickCloseDirectionWithPoint();
                                   }}
                              >
                                   <img src="img/icons/svg/arrow-back.svg" width={22} alt="back" />
                              </div>
                         )}
                         <div
                              onClick={() => {
                                   onClickCloseDirectionWithPoint();
                                   directionDrawerClose();
                              }}
                         >
                              <img src="img/icons/svg/u_x.svg" width={22} alt="close" />
                         </div>
                    </div>

                    {!directionDrawerSearchMode.show && (
                         <>
                              <DirectionResultWrap>
                                   <div className="results">
                                        <div className="start" onClick={() => setDirectionDrawerSearchMode({ show: true, type: "START" })}>
                                             <div className="title">출발</div>
                                             <div className="contents">{directionStart.name ?? "지역, 점포명으로 검색해 주세요"}</div>
                                        </div>
                                        <div className="end" onClick={() => setDirectionDrawerSearchMode({ show: true, type: "END" })}>
                                             <div className="title">도착</div>
                                             <div className="contents">{directionEnd.name ?? "지역, 점포명으로 검색해 주세요"}</div>
                                        </div>
                                   </div>

                                   <div className="change-btn" onClick={handleChangeDirection}>
                                        <img src="img/icons/svg/u_exchange.svg" alt="exchange" width={22} />
                                   </div>
                              </DirectionResultWrap>
                              <MobileTobaccoDirectionBtnWrap>
                                   <div onClick={handleClickDirection}>길찾기</div>
                              </MobileTobaccoDirectionBtnWrap>
                         </>
                    )}
                    {directionDrawerSearchMode.show && (
                         <DirectionSearchWrap showDirectionPointMessage={showDirectionPointMessage}>
                              <div className="search-wrap">
                                   <div className="input-wrap">
                                        <div className="title">{directionDrawerSearchMode.type === "START" ? "출발" : "도착"}</div>
                                        <Input
                                             sx={{
                                                  width: "100%",
                                             }}
                                             disableUnderline
                                             placeholder={
                                                  directionDrawerSearchMode.type === "START"
                                                       ? (directionStart?.name ?? "지역, 점포명으로 검색해 주세요")
                                                       : directionDrawerSearchMode.type === "END"
                                                         ? (directionEnd?.name ?? "지역, 점포명으로 검색해 주세요")
                                                         : ""
                                             }
                                             value={searchValue}
                                             onChange={handleSearchChange}
                                        />
                                   </div>

                                   <div className="search">
                                        <img src="img/icons/svg/u_search.svg" alt="search" width={22} />
                                   </div>
                              </div>
                              <div className="direction-btns">
                                   <div onClick={handleClickSetMyPlace}>
                                        <IconDirectionArrow /> 내 위치로 지정
                                   </div>
                                   <div
                                        onClick={() => {
                                             handleClickDirectionWithPoint(directionDrawerSearchMode.type);
                                        }}
                                   >
                                        <IconMap /> 지도에서 지정
                                   </div>
                              </div>

                              {searchResult ? (
                                   <TobaccoSearchResultWrap>
                                        {searchResult.map(item => {
                                             return (
                                                  <ItemTobaccoSearchResult onClick={() => handleSelectSearchResult(item)} key={item.id}>
                                                       <div className="title">{item.name}</div>
                                                       <div className="contact-number">{`${item.sub_category} ${item.phone ? ` | ${item.phone}` : ""}`}</div>
                                                       <div className="address">{item.full_address}</div>
                                                  </ItemTobaccoSearchResult>
                                             );
                                        })}
                                   </TobaccoSearchResultWrap>
                              ) : (
                                   <></>
                              )}
                         </DirectionSearchWrap>
                    )}
               </MobileTobaccoDirectionWrap>
          </>
     );
};

export default MobileDirection;
