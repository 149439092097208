import { useCallback, useRef, useState } from "react";
import { TAB } from "../../constants/menu";
import { SideBarContentsWrap, SideBarTabWrap, SideBarTabItem, SidebarTop, SearchWrap } from "./SidebarContents.styled";
import SidebarSearchList from "./sidebarSearchList/SidebarSearchList";
import SidebarStoreList from "./sidebarStoreList/SidebarStoreList";
import IconLine from "../MobileFilterV2/svgComponents/IconLine";
import IconList from "../mobileBottomMenu/IconComponents/IconList";
import IconStoreSearch from "../mobileBottomMenu/IconComponents/IconStoreSearch";
import { postStoreSearch, searchReset } from "../../reducers/store";
import { useDispatch } from "react-redux";

const SidebarContents = ({ tab, handleChangeTab, map, searchStoreList, defaultList, onClickStore, account, searchCodeFilter, activeStore }) => {
     const [searchValue, setSearchValue] = useState("");
     const dispatch = useDispatch();
     const [searchOffset, setSearchOffset] = useState(0);
     const listEl = useRef(null);

     const onSearch = useCallback(
          type => () => {
               handleChangeTab(TAB.SEARCH);
               const category = [];
               Object.entries(searchCodeFilter).map(([key, value]) => {
                    if (value?.length > 0) {
                         category.push({
                              code: key,
                              sub_code: value,
                         });
                    }
                    return null;
               });

               if (type === "scroll") {
                    setSearchOffset(prev => prev + 30);
               } else {
                    setSearchOffset(0);
                    listEl.current?.scrollTo({
                         top: 0,
                         left: 0,
                         behavior: "smooth",
                    });
               }

               dispatch(
                    postStoreSearch({
                         storeName: searchValue,
                         account: account,
                         category,
                         offset: type === "scroll" ? searchOffset + 30 : 0,
                    }),
               );
          },
          [account, dispatch, searchCodeFilter, searchOffset, searchValue],
     );

     const onChangeSearchValue = e => {
          if (e.target.value.trim() === "") {
               dispatch(searchReset());
          }
          setSearchOffset(0);
          setSearchValue(e.target.value);
     };

     return (
          <SideBarContentsWrap>
               <SidebarTop>
                    <SearchWrap>
                         <input
                              placeholder="점포명을 검색해 주세요"
                              value={searchValue}
                              onChange={onChangeSearchValue}
                              onKeyDown={e => {
                                   if (e.key === "Enter") {
                                        onSearch()();
                                   }
                              }}
                         />
                         <div
                              onClick={() => {
                                   onSearch()();
                              }}
                         >
                              <img src={"img/icons/search.png"} alt="search" width={22} />
                         </div>
                    </SearchWrap>

                    <SideBarTabWrap>
                         <SideBarTabItem selected={tab === TAB.LIST} onClick={() => handleChangeTab(TAB.LIST)}>
                              <IconList active={tab === TAB.LIST} /> 점포목록
                         </SideBarTabItem>
                         <IconLine />
                         <SideBarTabItem selected={tab === TAB.SEARCH} onClick={() => handleChangeTab(TAB.SEARCH)}>
                              <IconStoreSearch active={tab === TAB.SEARCH} /> 점포 검색
                         </SideBarTabItem>
                    </SideBarTabWrap>
               </SidebarTop>

               {tab === TAB.LIST && (
                    <SidebarStoreList
                         activeStore={activeStore}
                         storeList={defaultList}
                         onClickStore={onClickStore}
                         searchCodeFilter={searchCodeFilter}
                         map={map}
                    />
               )}
               {tab === TAB.SEARCH && (
                    <SidebarSearchList
                         map={map}
                         listEl={listEl}
                         searchValue={searchValue}
                         setSearchValue={setSearchValue}
                         searchStoreList={searchStoreList}
                         onClickStore={onClickStore}
                         account={account}
                         searchCodeFilter={searchCodeFilter}
                         onSearch={onSearch}
                    />
               )}
          </SideBarContentsWrap>
     );
};

export default SidebarContents;
