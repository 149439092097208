import styled from "@emotion/styled";

interface ITobaccoHomeWrap {
     isTabletOrMobile: boolean;
}

export const TobaccoHomeWrap = styled.div<ITobaccoHomeWrap>`
     display: flex;
     flex-direction: ${({ isTabletOrMobile }) => (!isTabletOrMobile ? "row;" : "column;")};
     height: 100%;

     .close-wrap {
          position: absolute;
          z-index: 15;
          top: 30px;
          left: 100%;
          box-shadow:
               0 0 5px 0 rgb(0 0 0 / 20%),
               5px 0 15px 0 rgb(0 0 0 / 10%);
          border-radius: 0 5px 5px 0;
          overflow: hidden;
          button {
               background-color: #fff;
               display: flex;
               justify-content: center;
               align-items: center;
               border: 1px solid #e0e0e0;
               border-left-width: 0;
          }
          svg {
               width: 24px;
               height: 24px;
          }
     }

     .left-container {
          box-sizing: border-box;
          position: relative;
          display: flex;
          z-index: 10;
          box-shadow:
               0 0 5px 0 rgb(0 0 0 / 20%),
               5px 0 15px 0 rgb(0 0 0 / 10%);
     }

     .store-detail-default {
          /* margin-bottom: 30px; */
          padding: 30px 20px;
          border-bottom: 6px solid #f0f2f5;
     }
     .store-detail-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
     }

     .store-detail-title h1 {
          font-size: 24px;
     }
     .store-detail-title button svg {
          width: 22px;
          height: 22px;

          path {
               color: #d9d9d9;
          }
     }

     .store-detail-title button.on svg path {
          color: #ffeb5a;
     }

     .store-admin {
          /* padding: 30px 0; */
          border-bottom: 6px solid #f0f2f5;
          > div {
               display: flex;
               align-items: center;
               justify-content: space-between;
               padding: 16px;
               border-bottom: 1px solid #e0e0e0;
          }
     }

     .custom-infow-window {
          background-color: #fff;
          color: #333;
          padding: 5px;
          border: #e0e0e0;
          border-radius: 5px;
          box-shadow:
               0 0 5px 0 rgb(0 0 0 / 20%),
               5px 0 15px 0 rgb(0 0 0 / 10%);
     }

     .label-textarea {
          display: block;
          font-size: 14px;
          color: #757575;
          margin-bottom: 8px;
     }

     #map {
          flex: 1;
     }

     #map .marker-wrap {
          padding-top: 8px;
          &.active .marker-button,
          &.hover .marker-button {
               padding: 4px 8px 4px 4px;
               z-index: 20;
          }
          &.active .marker-button > .marker-none-img,
          &.hover .marker-button > .marker-none-img {
               margin-right: 6px;
               margin-left: 2px;
          }
     }

     .marker-dot {
          box-sizing: border-box;
          position: absolute;
          bottom: -10px;
          left: 16.5px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: white;
     }

     .marker-dot.place {
          border: 1px solid #56cb98;
     }
     .marker-dot.open {
          border: 1px solid #3e85e8;
     }
     .marker-dot.closed {
          border: 1px solid #ff0000;
     }
     .marker-dot.suspended {
          border: 1px solid #666666;
     }

     #map .marker-button {
          position: relative;
          display: flex;
          padding: 4px;
          border-radius: 23px;
          background: #fff;
          align-items: center;
          white-space: nowrap;

          & > .marker-none-img {
               width: 30px;
               height: 30px;
               border-radius: 100%;

               display: flex;
               align-items: center;
               justify-content: center;
          }
          & > img {
               width: 30px;
          }
     }

     #map .marker-button.place {
          border: 1px solid #57cc98;

          &:after {
               box-shadow: -1px 1px 1px 0 #57cc98;
          }

          & .marker-none-img {
               background-color: #57cc98;
          }

          .marker-button-count {
               border: 1px solid #57cc98;
               color: #3d4043;
          }

          & svg {
               path: {
                    fill: white;
               }
          }
     }

     #map .marker-button.suspended {
          border: 1px solid #666666;
          &:after {
               box-shadow: -1px 1px 1px 0 #666666;
          }
          & .marker-none-img {
               background-color: #ffffff;
          }
          .marker-button-count {
               border: 1px solid #666666;
               color: #3d4043;
          }
          .marker-button-new {
               padding: 0px 2px;
               border: 1.5px solid #666666;
               color: #3d4043;
               font-size: 8px;
               font-style: normal;
               font-weight: 600;
          }
     }

     #map .marker-button.open {
          border: 1px solid #3e85e8;

          .marker-button-count {
               border: 1px solid #3e85e8;
               color: #3d4043;
          }

          .marker-button-new {
               padding: 0px 2px;
               border: 1.5px solid #3e85e8;
               color: #3d4043;
               font-size: 8px;
               font-style: normal;
               font-weight: 600;
          }
     }

     #map .marker-button.closed {
          border: 1px solid #ff0000;
          &:after {
               box-shadow: -1px 1px 1px 0 #ff0000;
          }
          & .marker-none-img {
               background-color: #ffffff;
          }
          .marker-button-count {
               border: 1px solid #ff0000;
               color: #3d4043;
          }

          .marker-button-new {
               padding: 0px 2px;
               border: 1.5px solid #ff0000;
               color: #3d4043;
               font-size: 8px;
               font-style: normal;
               font-weight: 600;
          }
     }

     #map .marker-button:after {
          background: linear-gradient(45deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
          box-shadow: -1px 1px 1px 0 #0475f4;
          content: "";
          height: 5px;
          left: 19px;
          position: absolute;
          top: 37px;
          transform: translate(-50%, -50%) rotate(-45deg);
          width: 5px;
     }

     #map .marker-wrap.active img,
     #map .marker-wrap.hover img {
          margin-right: 6px;
     }
     #map .marker-button .marker-button-name {
          /* width: 0;
		overflow: hidden; */
          display: none;
          color: #333;
          font-size: 16px;
          font-weight: 500;
          opacity: 0;
          font-family:
               "Pretendard Variable",
               Pretendard,
               -apple-system,
               BlinkMacSystemFont,
               system-ui,
               Roboto,
               "Helvetica Neue",
               "Segoe UI",
               "Apple SD Gothic Neo",
               "Noto Sans KR",
               "Malgun Gothic",
               "Apple Color Emoji",
               "Segoe UI Emoji",
               "Segoe UI Symbol",
               sans-serif !important;
     }
     #map .marker-wrap.active .marker-button .marker-button-name,
     #map .marker-wrap.hover .marker-button .marker-button-name {
          /* transition: all 400ms; */
          /* width: 100%; */
          display: inline-block;
          opacity: 1;
     }

     #map .marker-button-count {
          width: 20px;
          height: 15px;
          position: absolute;
          top: -6px;
          right: -4px;
          border-radius: 9px;
          background-color: #fff;
          vertical-align: middle;
          text-align: center;
          font-size: 11px;
          line-height: 15px;
          font-weight: bold;
     }
     #map .marker-button-new {
          width: 20px;
          height: 13px;
          position: absolute;
          top: -3px;
          right: -10px;
          border-radius: 9px;
          background-color: #fff;
          vertical-align: middle;
          text-align: center;
          font-size: 11px;
          line-height: 15px;
          font-weight: bold;
     }
`;
