import { useCallback, useRef } from "react";
import { SidebarTobaccoStoreListWrap } from "./SidebarTobaccoStoreList.styled";
import { RiPushpin2Fill } from "react-icons/ri";

const { naver } = window;

const SidebarTobaccoStoreList = ({ activeStore, storeList, onClickStore, map }) => {
     const listEl = useRef(null);

     const onClickStoreName = item => {
          map.current.setCenter(new naver.maps.LatLng(item.ylatitude, item.xlongitude));
          onClickStore(item);
     };

     const onMouseLeaveList = useCallback(
          managementNumber => () => {
               if (managementNumber) {
                    const marker = document.getElementById(`${managementNumber}`);
                    marker && marker.parentElement.classList.remove("hover");
               }
          },
          [],
     );

     const onMouseEnterList = useCallback(
          managementNumber => () => {
               if (managementNumber) {
                    const marker = document.getElementById(`${managementNumber}`);
                    marker && marker.parentElement.classList.add("hover");
               }
          },
          [],
     );

     return (
          <SidebarTobaccoStoreListWrap ref={listEl}>
               {storeList?.length > 0 &&
                    [...storeList]
                         .sort((a, b) => a.distance - b.distance)
                         .map((item, index) => {
                              const isActive = activeStore?.data?.managementNumber === item?.managementNumber;

                              return (
                                   <li
                                        key={"search-item" + item.managementNumber + index}
                                        className={`search-item ${isActive ? "active" : ""}`}
                                        onClick={() => {
                                             onClickStoreName(item);
                                        }}
                                        onMouseLeave={onMouseLeaveList(item.managementNumber)}
                                        onMouseEnter={onMouseEnterList(item.managementNumber)}
                                   >
                                        <div className="search-item-title">
                                             <h2>{item?.businessName ?? item?.fullRoadAddress ?? item?.fullLocationAddress ?? ""}</h2>
                                             {item?.distance && item.distance !== 0 ? (
                                                  <span className="dst">{item.distance.toLocaleString()} m</span>
                                             ) : item?.distance && item.distance === 0 ? (
                                                  <span className="distance">
                                                       <RiPushpin2Fill />
                                                  </span>
                                             ) : null}
                                        </div>

                                        <div className="search-item-sub">
                                             <p className="category">{item?.detailedBusinessStatusName}</p>
                                        </div>
                                        <p className="address">{item?.fullRoadAddress}</p>
                                   </li>
                              );
                         })}
          </SidebarTobaccoStoreListWrap>
     );
};

export default SidebarTobaccoStoreList;
