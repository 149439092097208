const IconMap = ({}) => {
     return (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
               <path
                    d="M15.99 3.78772L11.49 2.28772H11.4375C11.4026 2.28422 11.3674 2.28422 11.3325 2.28772H11.16H11.0625H11.01L6.75 3.75022L2.49 2.28772C2.37721 2.25053 2.25721 2.24066 2.13985 2.25891C2.0225 2.27717 1.91116 2.32303 1.815 2.39272C1.71807 2.46174 1.63895 2.55282 1.58418 2.65845C1.52941 2.76409 1.50055 2.88124 1.5 3.00022V13.5002C1.4996 13.6575 1.54862 13.8108 1.64013 13.9387C1.73165 14.0666 1.86104 14.1624 2.01 14.2127L6.51 15.7127C6.66109 15.762 6.82392 15.762 6.975 15.7127L11.25 14.2877L15.51 15.7502C15.5896 15.761 15.6704 15.761 15.75 15.7502C15.9068 15.7524 16.0599 15.7023 16.185 15.6077C16.2819 15.5387 16.3611 15.4476 16.4158 15.342C16.4706 15.2364 16.4995 15.1192 16.5 15.0002V4.50022C16.5004 4.34299 16.4514 4.18961 16.3599 4.06175C16.2684 3.9339 16.139 3.83804 15.99 3.78772ZM6 13.9577L3 12.9602V4.04272L6 5.04022V13.9577ZM10.5 12.9602L7.5 13.9577V5.04022L10.5 4.04272V12.9602ZM15 13.9577L12 12.9602V4.04272L15 5.04022V13.9577Z"
                    fill="#206DEF"
               />
          </svg>
     );
};

export default IconMap;
