import styled from "@emotion/styled";
import { Drawer } from "@mui/material";
import { memo } from "react";
import FilterControllerV2 from "../filterControllerV2";

const MobileFilterTop = memo(({ open, onClose, codeFilter, onClickFilterButton, handleClickFilterReset }) => {
     return (
          <CustomDrawer
               anchor="top"
               open={open}
               onClose={onClose}
               ModalProps={{
                    BackdropProps: {
                         style: {
                              backgroundColor: "transparent",
                         },
                    },
               }}
          >
               <DrawerWrap>
                    <DrawerTop>
                         <div onClick={onClose}>
                              <img src={"img/icons/svg/arrow-back.svg"} width={24} height={24} alt="filter-close" />
                         </div>
                         <DrawerTopRight onClick={handleClickFilterReset}>
                              <img src={"img/icons/svg/undo.svg"} width={24} height={24} alt="filter-remove" />
                              초기화
                         </DrawerTopRight>
                    </DrawerTop>
                    <FilterControllerV2
                         //@ts-ignore
                         codeFilter={codeFilter}
                         handleSetCodeFilter={onClickFilterButton}
                    />
               </DrawerWrap>
          </CustomDrawer>
     );
});

export default MobileFilterTop;

const CustomDrawer = styled(Drawer)`
     height: 100vh !important;
     overflow-y: auto;
     font-family:
          "Pretendard Variable",
          Pretendard,
          -apple-system,
          BlinkMacSystemFont,
          system-ui,
          Roboto,
          "Helvetica Neue",
          "Segoe UI",
          "Apple SD Gothic Neo",
          "Noto Sans KR",
          "Malgun Gothic",
          "Apple Color Emoji",
          "Segoe UI Emoji",
          "Segoe UI Symbol",
          sans-serif !important;
     .MuiDrawer-paper {
          background: #ffffff;
     }
`;

const DrawerWrap = styled.div`
     box-sizing: border-box;
     min-height: 300px;
     background: #ffffff;
     width: 100%;
     padding: 20px 10px 10px 10px;
`;

const DrawerTop = styled.div`
     display: flex;
     justify-content: space-between;
     align-items: center;
`;

const DrawerTopRight = styled.div`
     display: flex;
     align-items: center;
     gap: 5px;
     color: #484848;
     text-align: right;
     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
`;
