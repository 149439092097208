const IconOil = ({ active = false, size = "24" }) => {
     const color = active ? "#EA346F" : "rgba(61, 64, 67, 0.50)";
     return (
          <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                    d="M20.54 6.29L19 4.75L17.59 3.34C17.4968 3.24676 17.3861 3.1728 17.2642 3.12234C17.1424 3.07188 17.0119 3.04591 16.88 3.04591C16.7481 3.04591 16.6176 3.07188 16.4958 3.12234C16.3739 3.1728 16.2632 3.24676 16.17 3.34C16.0768 3.43324 16.0028 3.54393 15.9523 3.66575C15.9019 3.78757 15.8759 3.91814 15.8759 4.05C15.8759 4.18186 15.9019 4.31243 15.9523 4.43425C16.0028 4.55607 16.0768 4.66676 16.17 4.76L17.17 5.76L16.34 8.25C16.1645 8.77902 16.1398 9.34648 16.2687 9.88874C16.3977 10.431 16.6751 10.9266 17.07 11.32L20.02 14.32V19C20.02 19.2652 19.9146 19.5196 19.7271 19.7071C19.5396 19.8946 19.2852 20 19.02 20C18.7548 20 18.5004 19.8946 18.3129 19.7071C18.1254 19.5196 18.02 19.2652 18.02 19V17C18.02 16.2044 17.7039 15.4413 17.1413 14.8787C16.5787 14.3161 15.8156 14 15.02 14H14V5C14 4.20435 13.6839 3.44129 13.1213 2.87868C12.5587 2.31607 11.7956 2 11 2H5C4.20435 2 3.44129 2.31607 2.87868 2.87868C2.31607 3.44129 2 4.20435 2 5V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H11C11.7956 22 12.5587 21.6839 13.1213 21.1213C13.6839 20.5587 14 19.7956 14 19V16H15C15.2652 16 15.5196 16.1054 15.7071 16.2929C15.8946 16.4804 16 16.7348 16 17V19C16 19.7956 16.3161 20.5587 16.8787 21.1213C17.4413 21.6839 18.2044 22 19 22C19.7956 22 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 19V9.83C22.0012 9.17295 21.8728 8.52211 21.6223 7.91469C21.3718 7.30728 21.004 6.75519 20.54 6.29ZM12 19C12 19.2652 11.8946 19.5196 11.7071 19.7071C11.5196 19.8946 11.2652 20 11 20H5C4.73478 20 4.48043 19.8946 4.29289 19.7071C4.10536 19.5196 4 19.2652 4 19V12H12V19ZM12 10H4V5C4 4.73478 4.10536 4.48043 4.29289 4.29289C4.48043 4.10536 4.73478 4 5 4H11C11.2652 4 11.5196 4.10536 11.7071 4.29289C11.8946 4.48043 12 4.73478 12 5V10ZM20 11.42L18.46 9.88C18.3332 9.75068 18.2439 9.58934 18.2016 9.41322C18.1593 9.23709 18.1657 9.05279 18.22 8.88L18.73 7.34L19.12 7.74C19.6755 8.2947 19.9914 9.04503 20 9.83V11.42Z"
                    fill={color}
               />
          </svg>
     );
};

export default IconOil;
