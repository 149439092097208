import styled from "@emotion/styled";

export const MobileNavWrap = styled.div`
     position: fixed;
     top: 20px;
     width: 100%;
     display: flex;
     z-index: 500;
`;

export const MobileNavContents = styled.div`
     box-sizing: border-box;
     margin: 0px 10px;
     border-radius: 8px;
     width: 100%;
     height: 42px;
     background-color: #ffffff;
     border: 1px solid var(#dadce0);
     box-shadow:
          0px 1px 2px 0px rgba(0, 0, 0, 0.05),
          0px 1px 10px 0px rgba(0, 0, 0, 0.15);
     padding: 8px;
     display: flex;
     justify-content: space-between;
     align-items: center;
     gap: 10px;
`;

export const MobileNavLeft = styled.div``;
export const MobileCenter = styled.div`
     width: 100%;
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding-right: 6px;
`;

export const MobileNavRight = styled.div``;

export const MobileLogo = styled.img`
     width: 24px;
     height: 24px;
`;

export const MobileSearchText = styled.div`
     color: #71757a;
     font-size: 14px;
     font-weight: 400;
     opacity: 0.5;
`;
