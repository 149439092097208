import styled from "@emotion/styled";
import Modal from "..";
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../../auth/authConfig";
import UserPhoto from "../../userPhoto/UserPhoto";

const msalInstance = new PublicClientApplication(msalConfig);

const MobileUserInfoModal = ({ open, onClose }) => {
     const { accounts } = useMsal();

     const userInfoData = useSelector(state => state.user);

     return (
          <Modal open={open} onClose={onClose} noHeader headCloseButton={false} width={240}>
               <UserInfoModalContents>
                    <ModalUpperContents>
                         <UserPhoto size={64} />

                         <ModalUpperTextWrap>
                              <UserName>{`${userInfoData.kor_nm}/${userInfoData.team_nm}`}</UserName>
                              <UserAccount>{accounts[0].username}</UserAccount>
                         </ModalUpperTextWrap>
                    </ModalUpperContents>

                    <ModalBottomContents>
                         <SignOutBtn
                              onClick={() => {
                                   msalInstance.logoutRedirect();
                              }}
                         >
                              로그아웃
                         </SignOutBtn>
                    </ModalBottomContents>
               </UserInfoModalContents>
          </Modal>
     );
};

export default MobileUserInfoModal;

const UserInfoModalContents = styled.div`
     width: 240px;
     height: 240px;
     padding: 20px;
     box-sizing: border-box;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     align-items: space-between;
`;

const ModalUpperContents = styled.div`
     display: flex;
     flex-direction: column;
     align-items: center;
     gap: 14px;
`;
const ModalBottomContents = styled.div``;

const SignOutBtn = styled.div`
     width: 200px;
     height: 44px;
     box-sizing: border-box;
     display: flex;
     justify-content: center;
     align-items: center;
     background: #3d4043;
     border: 1px solid #dadce0;
     color: #ffffff;
     border-radius: 8px;
`;

const ModalUpperTextWrap = styled.div`
     display: flex;
     flex-direction: column;
     gap: 2px;
`;
const UserName = styled.div`
     color: #3d4043;
     font-size: 12px;
     font-weight: 500;
     text-align: center;
`;
const UserAccount = styled.div`
     color: #71757a;
     font-size: 12px;
     font-weight: 500;
     text-align: center;
`;
