import UserPhoto from "../userPhoto/UserPhoto";
import { MobileLogo, MobileSearchText, MobileCenter, MobileNavContents, MobileNavWrap, MobileNavLeft, MobileNavRight } from "./MobileNav.styled";

const MobileNav = ({ setSearchMode, setModalUserInfoOpen }) => {
     return (
          <MobileNavWrap>
               <MobileNavContents>
                    <MobileNavLeft>
                         <MobileLogo src={"/img/logo.png"} alt="gs-logo" />
                    </MobileNavLeft>
                    <MobileCenter onClick={() => setSearchMode(true)}>
                         <MobileSearchText>점포명, 주소 검색</MobileSearchText>
                         <MobileLogo src={"/img/icons/search.png"} alt="search-icon" />
                    </MobileCenter>
                    <MobileNavRight>
                         <div onClick={() => setModalUserInfoOpen(prev => !prev)}>
                              <UserPhoto size={24} />
                         </div>
                    </MobileNavRight>
               </MobileNavContents>
          </MobileNavWrap>
     );
};

export default MobileNav;
