import { useCallback, useRef } from "react";
import { isCategoryMatch } from "../../../utils/validator";
import { SideBarStoreListWrap } from "./SidebarSearchList.styled";

const { naver } = window;

const SidebarStoreList = ({ activeStore, storeList, onClickStore, searchCodeFilter, map }) => {
     const listEl = useRef(null);

     const onClickStoreName = item => {
          map.current.setCenter(new naver.maps.LatLng(item.y, item.x));
          onClickStore(item);
     };

     const onMouseLeaveList = useCallback(
          name => () => {
               const marker = document.getElementById(`${name}`);
               marker && marker.parentElement.classList.remove("hover");
          },
          [],
     );

     const onMouseEnterList = useCallback(
          name => () => {
               const marker = document.getElementById(`${name}`);
               marker && marker.parentElement.classList.add("hover");
          },
          [],
     );

     return (
          <SideBarStoreListWrap ref={listEl}>
               {storeList?.length > 0 &&
                    storeList
                         .filter(item => isCategoryMatch(item["category"], item["sub_category_code"], searchCodeFilter))
                         .map((item, index) => {
                              const isActive = activeStore?.data?.id === item?.id;
                              return (
                                   <li
                                        key={"search-item" + item.id + index}
                                        className={`search-item ${isActive ? "active" : ""}`}
                                        onClick={() => {
                                             onClickStoreName(item);
                                        }}
                                        onMouseLeave={onMouseLeaveList(item.name)}
                                        onMouseEnter={onMouseEnterList(item.name)}
                                   >
                                        <div className="search-item-title">
                                             <h2>{item?.name}</h2>
                                             {item?.dst && item.dst !== 0 ? (
                                                  <span className="dst">{item.dst.toLocaleString()} m</span>
                                             ) : item?.dst && item.dst === 0 ? (
                                                  <span className="dst">{/* <RiPushpin2Fill /> */}</span>
                                             ) : null}
                                        </div>

                                        <div className="search-item-sub">
                                             <p className="category">{item?.category}</p>
                                             <em className="phone">{item?.phone}</em>
                                        </div>
                                        <p className="address">{item?.full_address}</p>
                                   </li>
                              );
                         })}
          </SideBarStoreListWrap>
     );
};

export default SidebarStoreList;
