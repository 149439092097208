export const MAIN_CATEGORY = {
	C1000: '편의점',
	C2000: '수퍼,마트',
	C3000: '반찬가게',
	C4000: '주유소',
	C5000: '주유소',
};

export type StoreCodeType =
	| 'C1001'
	| 'C1002'
	| 'C1003'
	| 'C1004'
	| 'C1999';
export type MartCodeType =
	| 'C2001'
	| 'C2003'
	| 'C2005'
	| 'C2006'
	| 'C2008'
	| 'C2011'
	| 'C2012'
	| 'C2999';
export type OliCodeType = 'C4001' | 'C4002' | 'C4003' | 'C4004' | 'C4005';
export type FoodCodeType = 'C3001';
export type MeetCodeType = 'C5001';

export type CodeFilterType = {
	C1000: null | StoreCodeType[];
	C2000: null | MartCodeType[];
	C3000: null | FoodCodeType[];
	C4000: null | OliCodeType[];
	C5000: null | MeetCodeType[];
};

export const SUB_CATEGORY = {
	C1001: 'GS25',
	C1002: 'CU',
	C1003: '세븐일레븐',
	C1004: '이마트24',
	C1999: '기타편의점',
	C2001: 'GS더프레시',
	C2008: '롯데슈퍼',
	C2003: '홈플러스익스프레스',
	C2005: '이마트에브리데이',
	C2006: '노브랜드',
	C2011: '하나로마트',
	C2012: '대형마트',
	C2999: '기타마트',
	C3001: '반찬가게',
	C4001: 'GS칼텍스직영',
	C4002: 'GS칼텍스',
	C4003: 'SK',
	C4004: '현대오일뱅크',
	C4005: 'S-OIL',
	C4999: '기타주유소',
	C5001: '정육점',
};

// 이미지 매칭
export const IMAGES_CODE = {
	'GS25': '/img/icons/gs25-circle.png',
	'CU': '/img/icons/cu-circle.png',
	'이마트24': '/img/icons/emart24-circle.png',
	'세븐일레븐': '/img/icons/seven.png',
	'GS더프레시': '/img/icons/gs-fresh-circle.png',
	'롯데슈퍼': '/img/icons/lotte-circle.png',
	'하나로마트': '/img/icons/one-mart-circle.png',
	'홈플러스익스프레스': '/img/icons/homeplus-circle.png',
	'이마트에브리데이': '/img/icons/emart-circle.png',
	'노브랜드': '/img/icons/nobrand-circle.png',
	'GS칼텍스직영': '/img/icons/gs-caltex-circle.png',
	'GS칼텍스': '/img/icons/gs-caltex-circle.png',
	'SK': '/img/icons/sk-energy-circle.png',
	'현대오일뱅크': '/img/icons/hyundai-oilbank-circle.png',
	'S-OIL': '/img/icons/s-oil-circle.png',
};
