import { ReactComponent as Close } from "../../assets/close.svg";
import styled from "@emotion/styled";

const MobileActiveStore = ({ onClickDetailModeOn, activeStore, handleCloseActiveStore, setDirectionStart, setDirectionEnd, directionDrawerOpen }) => {
     return (
          <MobileActiveStoreInfoWrap directionDrawerOpen={directionDrawerOpen}>
               <div onClick={onClickDetailModeOn}>
                    <div className="active-store-name">
                         <h2>{activeStore?.name}</h2>
                    </div>

                    <div className="active-store-details">
                         <p>{activeStore?.category}</p>
                         <p>{activeStore?.phone}</p>
                    </div>
                    <p className="active-store-address">{activeStore?.full_address}</p>
                    <DirectionBtnWraps>
                         <ItemDirection
                              onClick={e => {
                                   e.stopPropagation();
                                   setDirectionStart({
                                        coordinate: { x: activeStore?.x, y: activeStore?.y },
                                        name: activeStore?.name,
                                        isPlace: false,
                                   });
                              }}
                         >
                              출발
                         </ItemDirection>
                         <ItemDirection
                              onClick={e => {
                                   e.stopPropagation();
                                   setDirectionEnd({
                                        coordinate: { x: activeStore?.x, y: activeStore?.y },
                                        name: activeStore?.name,
                                        isPlace: false,
                                   });
                              }}
                         >
                              도착
                         </ItemDirection>
                    </DirectionBtnWraps>
               </div>

               <button
                    className="active-store-info-close"
                    onClick={e => {
                         e.preventDefault();
                         handleCloseActiveStore();
                    }}
               >
                    <Close />
               </button>
          </MobileActiveStoreInfoWrap>
     );
};

export default MobileActiveStore;

const DirectionBtnWraps = styled.div`
     display: flex;
     width: 100%;
     justify-content: flex-end;
     gap: 6px;
`;
const ItemDirection = styled.div`
     cursor: pointer;
     width: 62px;
     height: 38px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 999px;
     border: 1px solid #dadce0;
     background: #216cf0;
     box-shadow: 0px 0px 40px 0px rgba(179, 198, 207, 0.5);
     color: #f1f4f9;
     text-align: center;
     -webkit-text-stroke-width: 1;
     -webkit-text-stroke-color: #f1f4f9;

     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: 12px; /* 100% */
`;

const MobileActiveStoreInfoWrap = styled.div`
     box-sizing: border-box;
     position: absolute;
     bottom: ${({ directionDrawerOpen }) => (directionDrawerOpen ? "220px" : "80px")};
     left: 0;
     width: 100%;
     padding: 0 20px 25px;
     z-index: 500;
     > div {
          box-sizing: border-box;
          width: 100%;
          padding: 16px 14px 18px;
          box-shadow: rgb(0 0 0 / 15%) 3px 3px 15px;
          border-radius: 10px;
          background-color: #fff;

          .active-store-name {
               display: flex;
               align-items: center;
               justify-content: space-between;
               margin-bottom: 8px;
          }

          .active-store-name h2 {
               color: #1e2530;
               box-sizing: border-box;
               padding-top: 3px;

               font-size: 14px;
               font-style: normal;
               font-weight: 700;
               line-height: 18px; /* 150% */
               letter-spacing: -0.24px;
          }

          .active-store-details {
               display: flex;
               align-items: center;
               color: #71757a;

               font-size: 12px;
               font-style: normal;
               font-weight: 500;
               line-height: 16px; /* 160% */
          }

          .active-store-details p {
               &:nth-of-type(2)::before {
                    display: inline-block;
                    width: 1px;
                    height: 8px;
                    background-color: rgb(226, 226, 226);
                    margin: -1px 6px 1px;
                    vertical-align: -1px;
                    content: "";
               }
          }
     }
     .active-store-address {
          color: #1e2530;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 160% */
     }

     .active-store-info-close {
          position: absolute;
          top: 11.5px;
          right: 35px;
          padding: 6px;

          > svg {
               width: 14px;
               height: 14px;
          }
     }
`;
