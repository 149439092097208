import { memo } from 'react';
import styled from 'styled-components';

interface IDistanceControllerV2 {
  controlList: { text: string; value: string }[];
  distance: string;
  onChangeDistance: any;
}

const DistanceControllerV2 = memo(({ controlList, distance, onChangeDistance }: IDistanceControllerV2) => {
  return (
    <DistanceControllerWrap>
      <ul>
        {controlList.map(item => (
          <li
            key={`${item.text}-${item.value}`}
            className={distance === item.value ? 'on' : ''}
            onClick={onChangeDistance(item.value)}
          >
            {item.text}
          </li>
        ))}
      </ul>
    </DistanceControllerWrap>
  );
});

export default DistanceControllerV2;

export const DistanceControllerWrap = styled.div`
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  box-sizing: border-box;

  border-radius: 10px;
  overflow: hidden;
  z-index: 500;

  > ul {
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: #ffffff;
    box-sizing: border-box;
    border-radius: 2px;
  }
  > ul li {
    display: block;
    width: 100%;
    text-align: center;
    padding: 18px 0 17px;
    border-right: 1px solid #d3d3d3;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #e2e5e9;
    font-weight: bold;
    &.on {
      color: #000000;
    }
  }
  > ul li {
    &:last-child {
      border-right-width: 0;
    }
  }
`;
