const IconTobaccoOpen = ({ active = false, size = "16" }) => {
     const color = active ? "#216CF0" : "rgba(61, 64, 67, 0.50)";
     return (
          <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4.29636 2.45703C5.39269 1.72449 6.68162 1.3335 8.00017 1.3335C8.87565 1.3335 9.74255 1.50593 10.5514 1.84097C11.3602 2.176 12.0952 2.66706 12.7142 3.28612C13.3333 3.90517 13.8243 4.6401 14.1594 5.44894C14.4944 6.25778 14.6668 7.12468 14.6668 8.00016C14.6668 9.31871 14.2758 10.6076 13.5433 11.704C12.8108 12.8003 11.7696 13.6548 10.5514 14.1594C9.33322 14.6639 7.99277 14.796 6.69956 14.5387C5.40636 14.2815 4.21847 13.6466 3.28612 12.7142C2.35377 11.7819 1.71883 10.594 1.4616 9.30077C1.20436 8.00756 1.33638 6.66711 1.84097 5.44894C2.34555 4.23077 3.20004 3.18957 4.29636 2.45703ZM9.81349 5.86016L6.95349 8.72683L5.85349 7.62683C5.79372 7.55704 5.72018 7.50036 5.63747 7.46034C5.55476 7.42033 5.46467 7.39784 5.37286 7.3943C5.28105 7.39075 5.18949 7.40622 5.10394 7.43974C5.01839 7.47325 4.94069 7.52409 4.87572 7.58906C4.81075 7.65403 4.75991 7.73173 4.7264 7.81728C4.69288 7.90283 4.67741 7.99438 4.68096 8.0862C4.6845 8.17801 4.70699 8.2681 4.74701 8.35081C4.78702 8.43352 4.8437 8.50706 4.91349 8.56683L6.48016 10.1402C6.54245 10.2019 6.61633 10.2508 6.69755 10.284C6.77878 10.3172 6.86575 10.334 6.95349 10.3335C7.12838 10.3328 7.29598 10.2633 7.42016 10.1402L10.7535 6.80683C10.816 6.74485 10.8656 6.67112 10.8994 6.58988C10.9333 6.50864 10.9507 6.4215 10.9507 6.3335C10.9507 6.24549 10.9333 6.15835 10.8994 6.07711C10.8656 5.99587 10.816 5.92214 10.7535 5.86016C10.6286 5.73599 10.4596 5.6663 10.2835 5.6663C10.1074 5.6663 9.9384 5.73599 9.81349 5.86016Z"
                    fill={color}
               />
          </svg>
     );
};

export default IconTobaccoOpen;
