const IconStarFill = ({ fillColor = "black", size = 24 }) => {
     return (
          <svg width={size} height={size} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                    d="M19.663 6.77161C19.8156 6.89054 19.9305 7.0512 19.9936 7.23411C20.0423 7.41698 20.038 7.6099 19.9814 7.79046C19.9247 7.97101 19.818 8.13179 19.6736 8.25411L15.5436 12.2541L16.5436 17.9341C16.5793 18.1216 16.5606 18.3154 16.4898 18.4926C16.4189 18.6698 16.2988 18.823 16.1436 18.9341C15.972 19.0583 15.7655 19.1249 15.5536 19.1241C15.3925 19.1246 15.234 19.0833 15.0936 19.0041L9.99363 16.3241L4.87363 18.9941C4.7094 19.0812 4.52415 19.1208 4.33868 19.1084C4.1532 19.096 3.97485 19.0322 3.82363 18.9241C3.66848 18.813 3.54838 18.6598 3.4775 18.4826C3.40662 18.3054 3.38793 18.1116 3.42363 17.9241L4.42363 12.2441L0.29363 8.24411C0.165087 8.11584 0.0739014 7.95498 0.0298575 7.7788C-0.0141863 7.60263 -0.00942525 7.41778 0.0436298 7.24411C0.10159 7.06638 0.208206 6.90846 0.35138 6.78827C0.494554 6.66807 0.668552 6.59042 0.85363 6.56411L6.54363 5.72411L9.09363 0.56411C9.17551 0.395039 9.30337 0.252452 9.46254 0.152683C9.62171 0.052915 9.80577 0 9.99363 0C10.1815 0 10.3655 0.052915 10.5247 0.152683C10.6839 0.252452 10.8117 0.395039 10.8936 0.56411L13.4436 5.73411L19.1336 6.56411C19.3264 6.58061 19.5103 6.65269 19.663 6.77161Z"
                    fill={fillColor}
               />
          </svg>
     );
};

export default IconStarFill;
