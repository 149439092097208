export const COMPETITION_CATEGORY = ["C1002", "C1003", "C1004"];

export const REVIEW_CATEGORY = ["C1001", "C2001"];

export const MART_LIST = [
     { text: "GS더프래시", subCode: "C2001" },
     { text: "롯데슈퍼", subCode: "C2008" },
     { text: "홈플러스익스프레스", subCode: "C2003" },
     { text: "이마트에브리데이", subCode: "C2005" },
     { text: "노브랜드", subCode: "C2006" },
     { text: "하나로마트", subCode: "C2011" },
     { text: "대형 마트", subCode: "C2012" },
     { text: "기타", subCode: "C2999" },
];

export const STORE_LIST = [
     { text: "GS25", subCode: "C1001" },
     { text: "CU", subCode: "C1002" },
     { text: "세븐일레븐", subCode: "C1003" },
     { text: "이마트24", subCode: "C1004" },
     { text: "기타", subCode: "C1999" },
];

export const OIL_LIST = [
     { text: "GS칼텍스직영", subCode: "C4001" },
     { text: "GS칼텍스", subCode: "C4002" },
     { text: "SK", subCode: "C4003" },
     { text: "현대오일뱅크", subCode: "C4004" },
     { text: "S-OIL", subCode: "C4005" },
     { text: "기타", subCode: "C4999" },
];
