import styled from "@emotion/styled";

export const FilterControllerWrap = styled.div`
     padding-bottom: 15px;
`;

export const StoreList = styled.ul`
     padding-top: 10px;
     display: flex;
     gap: 10px;
     flex-wrap: wrap;
`;

interface IStoreItem {
     active: boolean;
     accent: string;
}

export const StoreItem = styled.li<IStoreItem>`
     cursor: pointer;
     border-radius: 8px;
     border: ${({ active }) => (active ? "1px solid transparent" : "1px solid #e6e6e6")};
     background-color: ${({ active, accent }) => (active ? accent : "rgba(218, 220, 224, 0.50);")};
     color: ${({ active }) => (active ? "#ffffff" : "rgba(47, 49, 60, 0.50)")};
     padding: 8px 10px 6px;
     word-break: keep-all;

     font-size: 12px;
     font-style: normal;
     font-weight: 500;
     line-height: normal;
`;

export const Line = styled.div`
     width: 100%;
     height: 1px;
     background-color: #e6e6e6;
     margin: 20px 0px;
`;

export const FilterContents = styled.div`
     display: flex;
     flex-direction: column;
     padding-top: 20px;
`;
export const FilterContentsTitle = styled.div`
     color: #3d4043;

     font-size: 14px;
     font-style: normal;
     font-weight: 600;
     line-height: normal;
     letter-spacing: -0.2px;
     display: flex;
     align-items: center;

     gap: 5px;
     svg {
          padding-bottom: 2px;
     }
`;
