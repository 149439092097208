import styled from "@emotion/styled";
import Modal from "..";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const MobileTobaccoBookmarkedModal = ({ open, onClose, handleRemoveBookmarkedStoreByModal, selectBookmarkItem }) => {
     const { tobaccoBookmarkedList } = useSelector(state => state.store);
     const [deleteInfo, setDeleteInfo] = useState({ store: null, showDeletePage: false });

     const handleClickDeleteBtn = bookmarkInfo => {
          handleRemoveBookmarkedStoreByModal(bookmarkInfo.bookmarkId);
          setDeleteInfo({ store: bookmarkInfo, showDeletePage: true });
     };

     const handleClickDeleteConfirm = () => {
          setDeleteInfo({ store: null, showDeletePage: false });
          onClose();
     };

     const handleClickBookmarkItem = bookmarkItem => {
          selectBookmarkItem(bookmarkItem);
          onClose();
     };

     if (tobaccoBookmarkedList === null) {
          return null;
     }

     return (
          <Modal open={open} onClose={onClose} noHeader headCloseButton={false} width={320}>
               {!deleteInfo.showDeletePage && (
                    <ModalWrap>
                         <div className="title">즐겨찾기 점포 목록</div>

                         {tobaccoBookmarkedList.length > 0 && (
                              <div className="bookmarked-list">
                                   {tobaccoBookmarkedList.map(el => {
                                        const address = el.fullRoadAddress ?? el.fullLocationAddress ?? "";

                                        return (
                                             <div className="bookmark-item" key={el.bookmarkId} onClick={() => handleClickBookmarkItem(el)}>
                                                  <div className="bookmark-left">
                                                       <div className="bookmark-name">{el.businessName ?? address}</div>
                                                       <div className="bookmark-address">{address}</div>
                                                  </div>

                                                  <div className="bookmark-right">
                                                       <div
                                                            onClick={e => {
                                                                 e.stopPropagation();
                                                                 const name = el?.businessName ?? el?.fullRoadAddress ?? el?.fullLocationAddress ?? "";

                                                                 if (window.confirm("즐겨찾기 : " + name + " 을(를) 삭제하시겠습니까?")) {
                                                                      handleClickDeleteBtn(el);
                                                                 }
                                                            }}
                                                       >
                                                            <img width={24} src="img/icons/trash_round.png" alt="remove-bookmark" />
                                                       </div>
                                                  </div>
                                             </div>
                                        );
                                   })}
                              </div>
                         )}

                         {tobaccoBookmarkedList.length === 0 && (
                              <div className="empty">
                                   <div className="empty-text">{"즐겨찾기를 등록한 점포가 없어요\n즐겨찾기를 추가해 주세요"}</div>
                                   <img src="img/images/empty.png" alt="empty" width={190} />
                              </div>
                         )}
                    </ModalWrap>
               )}
               {deleteInfo.showDeletePage && (
                    <DeleteInfoWrap>
                         <div className="text">{`${deleteInfo?.store?.businessName ?? deleteInfo?.store.fullRoadAddress ?? deleteInfo.store.fullLocationAddress}이\n즐겨찾기에서 삭제 되었어요`}</div>
                         <div className="close-btn" onClick={handleClickDeleteConfirm}>
                              확인
                         </div>
                    </DeleteInfoWrap>
               )}
          </Modal>
     );
};

export default MobileTobaccoBookmarkedModal;

const DeleteInfoWrap = styled.div`
     box-sizing: border-box;
     display: flex;
     flex-direction: column;

     justify-content: space-between;
     width: 240px;
     height: 197px;
     padding: 20px;

     .text {
          color: #3d4043;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px; /* 133.333% */
          letter-spacing: 0.12px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          white-space: pre-line;
     }
     .close-btn {
          box-sizing: border-box;
          color: #fff;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.12px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 200px;
          height: 44px;
          padding: 8px 11px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #dadce0;
          background: #3d4043;
     }
`;

const ModalWrap = styled.div`
     width: 320px;
     padding: 27px 17px;

     .title {
          color: #3d4043;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px; /* 100% */
          letter-spacing: 0.16px;
     }
     .empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 50px;
     }

     .empty-text {
          padding: 150px 0px 40px;
          color: #71757a;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px; /* 142.857% */
          letter-spacing: 0.28px;
          text-align: center;
          white-space: pre-line;
     }

     .bookmarked-list {
          overflow: auto;
          border-radius: 8px;
          margin-top: 35px;
          width: 100%;
          background-color: #f1f4f9;
          min-height: 400px;
          max-height: 400px;
     }

     .bookmark-item {
          box-sizing: border-box;
          width: 100%;
          padding: 10px;
          gap: 5px;
          border-bottom: 0.4px solid #bdbdbd;
          display: flex;
          justify-content: space-between;

          .bookmark-name {
               font-size: 16px;
               font-weight: bold;
          }
          .bookmark-address {
               overflow: hidden;
               color: #1e2530;
               font-size: 12px;
               font-style: normal;
               font-weight: 500;
               line-height: normal;
          }
     }
`;
