import styled from "@emotion/styled";
import { Drawer } from "@mui/material";

interface IMobileFilter {
     open: boolean;
     onClose: () => void;
     children: React.ReactNode;
}

const MobileFilterWrap = ({ open, onClose, children }: IMobileFilter) => {
     return (
          <CustomDrawer anchor="bottom" open={open}>
               <DrawerWrap>
                    <DrawerTop> 필터 </DrawerTop>
                    <DrawerContentsWrap>{children}</DrawerContentsWrap>
                    <DrawerCloseWrap onClick={onClose}>
                         <DrawerCloseBtn>닫기</DrawerCloseBtn>
                    </DrawerCloseWrap>
               </DrawerWrap>
          </CustomDrawer>
     );
};

export default MobileFilterWrap;

const CustomDrawer = styled(Drawer)`
	height 100vh !important;
	overflow-y: auto;

`;

const DrawerWrap = styled.div`
     height: 100vh;
     background: #ffffff;
     width: 100%;
`;

const DrawerTop = styled.div`
     position: fixed;
     top: 0px;
     z-index: 3;
     width: 100%;
     height: 60px;
     background-color: #ffffff;
     box-shadow:
          0 0 5px 0 rgb(0 0 0 / 20%),
          5px 0 15px 0 rgb(0 0 0 / 10%);
     display: flex;
     justify-content: center;
     align-items: center;
     font-size: 20px;
     font-weight: bold;
`;

const DrawerCloseWrap = styled.div`
     position: fixed;
     bottom: 0px;
     width: 100%;
     height: 50px;
     display: flex;
     justify-content: center;
     align-items: center;
     padding-bottom: 5px;
`;

const DrawerCloseBtn = styled.div`
     width: 100%;
     height: 100%;
     background-color: #1373e5;
     border-radius: 8px;
     display: flex;
     justify-content: center;
     align-items: center;
     color: #ffffff;
     font-size: 20px;
     margin: 0px 10px;
`;

const DrawerContentsWrap = styled.div`
     padding-top: 60px;
     width: 100%;
     height: calc(100vh - 120px);
     overflow: auto;
     > div {
          position: unset;
     }
`;
