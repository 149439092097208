import { useCallback, useState } from "react";
import { TAB } from "../../constants/menu";
import { TobaccoSideBarContentsWrap, SidebarTop, SearchWrap, SideBarTabWrap, SideBarTabItem } from "./TobaccoSidebarContents.styled";
import SidebarTobaccoStoreList from "./sidebarTobaccoStoreList/SidebarTobaccoStoreList";
import SidebarTobaccoSearchList from "./sidebarTobaccoSearchList/SidebarTobaccoSearchList";
import IconLine from "../MobileFilterV2/svgComponents/IconLine";
import IconList from "../mobileBottomMenu/IconComponents/IconList";
import IconStoreSearch from "../mobileBottomMenu/IconComponents/IconStoreSearch";
import { useDispatch } from "react-redux";
import { postTobaccoStoreSearch, tobaccoSearchReset } from "../../reducers/store";

import IconBookmark from "../mobileBottomMenu/IconComponents/IconBookmark";
import SidebarTobaccoBookmarkList from "./sidebarTobaccoBookmarkList/SidebarTobaccoBookmarkList";

const TobaccoSidebarContents = ({
     tab,
     handleChangeTab,
     map,
     tobaccoSearchStoreList,
     tobaccoStoreList,
     onClickStore,
     account,
     tobaccoFilterOption,
     activeStore,
     handleRemoveBookmarkedStore,
     selectBookmarkItem,
}) => {
     const [searchValue, setSearchValue] = useState("");

     const dispatch = useDispatch();

     const onChangeSearchValue = e => {
          if (e.target.value.trim() === "") {
               dispatch(tobaccoSearchReset());
          }

          setSearchValue(e.target.value);
     };

     const onSearch = useCallback(
          type => () => {
               handleChangeTab(TAB.SEARCH);
               dispatch(
                    postTobaccoStoreSearch({
                         storeName: searchValue,
                         account: account,
                         includeClosedWithin: tobaccoFilterOption?.includeClosedWithin,
                         includeSuspendedStore: "Y",
                    }),
               );
          },
          [account, dispatch, searchValue],
     );

     return (
          <TobaccoSideBarContentsWrap>
               <SidebarTop>
                    <SearchWrap>
                         <input
                              placeholder="점포명을 검색해 주세요"
                              value={searchValue}
                              onChange={onChangeSearchValue}
                              onKeyDown={e => {
                                   if (e.key === "Enter") {
                                        onSearch()();
                                   }
                              }}
                         />
                         <div
                              onClick={() => {
                                   onSearch()();
                              }}
                         >
                              <img src={"img/icons/search.png"} alt="search" width={22} />
                         </div>
                    </SearchWrap>

                    <SideBarTabWrap>
                         <SideBarTabItem selected={tab === TAB.BOOKMARK} onClick={() => handleChangeTab(TAB.BOOKMARK)}>
                              <IconBookmark active={tab === TAB.BOOKMARK} /> 즐겨찾기
                         </SideBarTabItem>
                         <IconLine />
                         <SideBarTabItem selected={tab === TAB.LIST} onClick={() => handleChangeTab(TAB.LIST)}>
                              <IconList active={tab === TAB.LIST} /> 점포목록
                         </SideBarTabItem>
                         <IconLine />
                         <SideBarTabItem selected={tab === TAB.SEARCH} onClick={() => handleChangeTab(TAB.SEARCH)}>
                              <IconStoreSearch active={tab === TAB.SEARCH} /> 점포 검색
                         </SideBarTabItem>
                    </SideBarTabWrap>
               </SidebarTop>
               {tab === TAB.LIST && <SidebarTobaccoStoreList activeStore={activeStore} storeList={tobaccoStoreList} onClickStore={onClickStore} map={map} />}

               {tab === TAB.SEARCH && <SidebarTobaccoSearchList map={map} searchStoreList={tobaccoSearchStoreList} onClickStore={onClickStore} />}
               {tab === TAB.BOOKMARK && (
                    <SidebarTobaccoBookmarkList handleRemoveBookmarkedStore={handleRemoveBookmarkedStore} selectBookmarkItem={selectBookmarkItem} />
               )}
               {/* 
     
      {tab === TAB.DIRECTION && (
        <SidebarTobaccoDirection
          account={account}
          directionStart={directionStart}
          setDirectionStart={setDirectionStart}
          directionEnd={directionEnd}
          setDirectionEnd={setDirectionEnd}
          resetDirection={resetDirection}
          tobaccoFilterOption={tobaccoFilterOption}
        />
      )} */}
          </TobaccoSideBarContentsWrap>
     );
};

export default TobaccoSidebarContents;
