import axios from "axios";

// const url =
// 	window.location.hostname === 'gsretail-mvp-rfc.azurewebsites.net'
// 		? 'https://gsretail-mvp-rfc-back.azurewebsites.net/api'
// 		: 'http://127.0.0.1:3060/api';

const url =
     window.location.hostname === "gsretail-mvp-store.azurewebsites.net"
          ? "https://gsretail-mvp-store-back.azurewebsites.net/api"
          : "http://127.0.0.1:3080/api";

const Api = axios.create({
     baseURL: url,
     withCredentials: true,
});

Api.defaults.withCredentials = true;

Api.interceptors.request.use(
     function (config) {
          const token = sessionStorage.getItem("idToken");

          if (token) {
               config.headers.Authorization = `Bearer ${token}`;
          }

          return config;
     },
     function (error) {
          return Promise.reject(error);
     },
);

Api.interceptors.response.use(
     function (response) {
          return response;
     },
     function (error) {
          return Promise.reject(error);
     },
);

Api.defaults.headers.post["Content-Type"] = "application/json";

export default Api;
