import { memo, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { RiPushpin2Fill } from "react-icons/ri";
import { SearchBarWrap } from "./SidebarSearchList.styled";
import Loader from "../../loader";
import { isCategoryMatch } from "../../../utils/validator";

const { naver } = window;

let beforeScrollY = 0;

const SidebarSearchList = memo(({ searchStoreList, listEl, map, onClickStore, onSearch, searchCodeFilter }) => {
     const { searchLoading } = useSelector(state => state.store);

     const eventScroll = useCallback(
          e => {
               const SearchList = document.getElementById("search-list");

               if (
                    beforeScrollY < SearchList.scrollTop &&
                    searchStoreList &&
                    !searchLoading &&
                    SearchList.scrollTop + SearchList.offsetHeight >= SearchList.scrollHeight
               ) {
                    onSearch("scroll")();
               }
               beforeScrollY = SearchList.scrollTop;
          },
          [onSearch, searchLoading, searchStoreList],
     );

     useEffect(() => {
          const SearchList = document.getElementById("search-list");
          SearchList.addEventListener("scroll", eventScroll);

          return () => {
               SearchList.removeEventListener("scroll", eventScroll);
          };
     }, [eventScroll]);

     useEffect(() => {
          if (!searchStoreList) {
               listEl.current.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
               });
          }
     }, [searchStoreList]);

     const onClickStoreName = item => {
          map.current.setCenter(new naver.maps.LatLng(item.y, item.x));
          onClickStore(item);
     };

     const onMouseLeaveList = useCallback(
          name => () => {
               const marker = document.getElementById(`${name}`);
               marker && marker.parentElement.classList.remove("hover");
          },
          [],
     );

     const onMouseEnterList = useCallback(
          name => () => {
               const marker = document.getElementById(`${name}`);
               marker && marker.parentElement.classList.add("hover");
          },
          [],
     );

     return (
          <SearchBarWrap>
               {searchLoading && <Loader />}

               <ul id="search-list" className="section" ref={listEl}>
                    {searchStoreList &&
                         searchStoreList
                              ?.filter(item => isCategoryMatch(item["category"], item["sub_category_code"], searchCodeFilter))
                              .map((item, index) => (
                                   <li
                                        key={"search-item" + item.id + index}
                                        className="search-item"
                                        onClick={() => {
                                             onClickStoreName(item);
                                        }}
                                        onMouseLeave={onMouseLeaveList(item.name)}
                                        onMouseEnter={onMouseEnterList(item.name)}
                                   >
                                        <div className="search-item-title">
                                             <h2>{item?.name}</h2>
                                             {item?.dst && item.dst !== 0 ? (
                                                  <span className="dst">{item.dst.toLocaleString()} m</span>
                                             ) : item?.dst && item.dst === 0 ? (
                                                  <span className="dst">
                                                       <RiPushpin2Fill />
                                                  </span>
                                             ) : null}
                                        </div>

                                        <div className="search-item-sub">
                                             <p className="category">{item?.category}</p>
                                             <em className="phone">{item?.phone}</em>
                                        </div>
                                        <p className="address">{item?.full_address}</p>
                                   </li>
                              ))}
               </ul>
          </SearchBarWrap>
     );
});

export default SidebarSearchList;
