import { DISTANCE_100, DISTANCE_1000, DISTANCE_150, DISTANCE_300, DISTANCE_50, DISTANCE_500, DISTANCE_75 } from "../../../constants/map";
import DistanceControllerV2 from "../../distanceController_v2";
import TobaccoFilterController from "../../tobaccoFilterController/TobaccoFilterController";
import { Line, TitleText, TobaccoFilterWrap } from "./TobaccoFilter.styled";

interface ITobaccoFilter {
     onChangeDistance: any;
     onChangeCircleDistance: any;
     handleCheckIncludeShutDownStore: any;
     filterOption: any;
}

const distList = [
     { text: "150M", value: DISTANCE_150 },
     { text: "300M", value: DISTANCE_300 },
     { text: "500M", value: DISTANCE_500 },
     { text: "1KM", value: DISTANCE_1000 },
];

const circleList = [
     { text: "50M", value: DISTANCE_50 },
     { text: "75M", value: DISTANCE_75 },
     { text: "100M", value: DISTANCE_100 },
     { text: "300M", value: DISTANCE_300 },
];

const TobaccoFilter = ({ onChangeDistance, onChangeCircleDistance, filterOption, handleCheckIncludeShutDownStore }: ITobaccoFilter) => {
     return (
          <TobaccoFilterWrap>
               <TitleText>조회 범위</TitleText>
               <DistanceControllerV2 controlList={distList} distance={filterOption?.distance} onChangeDistance={onChangeDistance} />
               <Line />
               <TitleText>주변 점포 반경</TitleText>
               <DistanceControllerV2 controlList={circleList} distance={filterOption?.circleDistance} onChangeDistance={onChangeCircleDistance} />
               <Line />

               <TobaccoFilterController fixed={false} filterOption={filterOption} handleCheckIncludeShutDownStore={handleCheckIncludeShutDownStore} />
          </TobaccoFilterWrap>
     );
};

export default TobaccoFilter;
