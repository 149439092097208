const IconDirection = ({ color = "#1E2530", size = 22 }) => {
     return (
          <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 22 22" fill="none">
               <path
                    d="M18.8052 6.29266C18.7635 6.18526 18.7011 6.08713 18.6214 6.00391L15.9964 3.37891C15.9148 3.29733 15.818 3.23261 15.7114 3.18846C15.6048 3.14431 15.4905 3.12158 15.3752 3.12158C15.1421 3.12158 14.9187 3.21415 14.7539 3.37891C14.5891 3.54368 14.4966 3.76715 14.4966 4.00016C14.4966 4.23318 14.5891 4.45665 14.7539 4.62141L15.8914 5.75016H8.37516C7.67897 5.75016 7.01129 6.02672 6.51901 6.51901C6.02672 7.01129 5.75016 7.67897 5.75016 8.37516V15.8914L4.62141 14.7539C4.45665 14.5891 4.23318 14.4966 4.00016 14.4966C3.76715 14.4966 3.54368 14.5891 3.37891 14.7539C3.21415 14.9187 3.12158 15.1421 3.12158 15.3752C3.12158 15.6082 3.21415 15.8316 3.37891 15.9964L6.00391 18.6214C6.08713 18.7011 6.18526 18.7635 6.29266 18.8052C6.3974 18.8515 6.51065 18.8754 6.62516 18.8754C6.73967 18.8754 6.85292 18.8515 6.95766 18.8052C7.06507 18.7635 7.1632 18.7011 7.24641 18.6214L9.87141 15.9964C10.0362 15.8316 10.1287 15.6082 10.1287 15.3752C10.1287 15.1421 10.0362 14.9187 9.87141 14.7539C9.70665 14.5891 9.48318 14.4966 9.25016 14.4966C9.01715 14.4966 8.79368 14.5891 8.62891 14.7539L7.50016 15.8914V8.37516C7.50016 8.1431 7.59235 7.92054 7.75644 7.75644C7.92054 7.59235 8.1431 7.50016 8.37516 7.50016H15.8914L14.7539 8.62891C14.6719 8.71026 14.6068 8.80703 14.5624 8.91366C14.518 9.02028 14.4951 9.13465 14.4951 9.25016C14.4951 9.36567 14.518 9.48004 14.5624 9.58667C14.6068 9.69329 14.6719 9.79007 14.7539 9.87141C14.8353 9.95343 14.932 10.0185 15.0387 10.0629C15.1453 10.1074 15.2597 10.1302 15.3752 10.1302C15.4907 10.1302 15.605 10.1074 15.7117 10.0629C15.8183 10.0185 15.9151 9.95343 15.9964 9.87141L18.6214 7.24641C18.7011 7.1632 18.7635 7.06507 18.8052 6.95766C18.8927 6.74463 18.8927 6.50569 18.8052 6.29266Z"
                    fill={color}
               />
          </svg>
     );
};

export default IconDirection;
