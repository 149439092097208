import { Configuration } from "@azure/msal-browser";

const AzureActiveDirectoryAppClientId = process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_CLIENT_ID;
const AzureActiveDirectoryAppTenantId = process.env.REACT_APP_AZURE_ACTIVE_DIRECTORY_APP_TENANT_ID;

const productRedirectUrl = process.env.NODE_ENV === "development" ? "http://localhost:3000/redirct" : `${window.location.origin}/redirct`;

export const msalConfig: Configuration = {
     auth: {
          clientId: AzureActiveDirectoryAppClientId || "",
          authority: `https://login.microsoftonline.com/${AzureActiveDirectoryAppTenantId}`,
          redirectUri: productRedirectUrl,
     },
     cache: {
          cacheLocation: "sessionStorage", // session 테스트 / local 운영
          storeAuthStateInCookie: true,
     },
};

export const loginRequest = {
     scopes: ["User.Read"],
};

export const graphConfig = {
     graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
     // betaUser: 'https://graph.microsoft.com/beta/users/taeyonglee@gsretail.com',
     betaUser: "https://graph.microsoft.com/v1.0/users",
     // betaUser:
     // 	'https://graph.microsoft.com/v1.0/users/fbf1cf2f-4c82-4e91-adcc-bc2b25e52fd0/getMemberGroups ',
     // betaUser: 'https://graph.microsoft.com/v1.0/groups',
};
