import { useState } from 'react';
import { Provider } from 'react-redux';
import { LicenseInfo } from '@mui/x-license-pro';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { store } from './store';
import Home from './pages/Home';

import Report from './pages/report';
import SignIn from './pages/signIn';
import { useIsAuthenticated } from '@azure/msal-react';
import Snackbar from './components/snackbar';
// hook
import { OnIsTabletOrMobile } from './hook/widthSize';
import MobileHome from './pages/Home/MobileHome/index.jsx';
import  TobaccoMobileHome  from './pages/tobacco/MobileHome/index';
import TobaccoPage from './pages/tobacco';

LicenseInfo.setLicenseKey('09a113ecdba820f0fec7ea28633adbf3Tz04NjkzNCxFPTE3NDI2NDczNDQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');


function App() {
	const isAuthenticated = useIsAuthenticated();
	const isTabletOrMobile = OnIsTabletOrMobile();

	const [snackbarMessage, setSnackbarMessage] = useState<string>('');
	const [sidebarOpen, setSidebarOpen] = useState(true);

	const handleChangeSidebarOpen =()=>{
		setSidebarOpen(prev=>!prev);
	}

	return (
		<Provider store={store}>
			{!isAuthenticated ? (
				<SignIn />
			) : (
			<BrowserRouter>
					<Routes>
						{isTabletOrMobile ? (
							<>
							<Route
								path='/'
								element={<MobileHome setSnackbarMessage={setSnackbarMessage} />}
							/>
							<Route
								path='/tobacco'
								element={<TobaccoMobileHome setSnackbarMessage={setSnackbarMessage} />}
							/>
							</>

						) : (
							<>
							<Route
								path='/'
								element={<Home 
									sidebarOpen={sidebarOpen}
									handleChangeSidebarOpen={handleChangeSidebarOpen}
								/>}
							/>
							<Route
								path='tobacco'
								element={<TobaccoPage 
									sidebarOpen={sidebarOpen}
									handleChangeSidebarOpen={handleChangeSidebarOpen}
								/>}
							/>
							</>
							
						)}

						<Route path='/report' element={<Report />} />
					</Routes>
				</BrowserRouter>
			)}

			<Snackbar message={snackbarMessage} />
		</Provider>
	);
}

export default App;
