import { memo, useCallback, useRef } from "react";

import Loader from "../../loader";

import { TobaccoSearchBarWrap } from "./SidebarTobaccoSearchList.styled";
import { useSelector } from "react-redux";

const { naver } = window;

const SidebarTobaccoSearchList = memo(({ searchStoreList, map, onClickStore }) => {
     const listEl = useRef(null);

     const { tobaccoSearchLoading } = useSelector(state => state.store);

     const onClickStoreName = item => {
          map.current.setCenter(new naver.maps.LatLng(item.y, item.x));
          onClickStore(item);
     };

     const onMouseLeaveList = useCallback(
          managementNumber => () => {
               const marker = document.getElementById(`${managementNumber}`);
               marker && marker.parentElement.classList.remove("hover");
          },
          [],
     );

     const onMouseEnterList = useCallback(
          managementNumber => () => {
               const marker = document.getElementById(`${managementNumber}`);
               marker && marker.parentElement.classList.add("hover");
          },
          [],
     );

     return (
          <TobaccoSearchBarWrap>
               {tobaccoSearchLoading && <Loader />}

               <ul id="search-list" className="section" ref={listEl}>
                    {searchStoreList &&
                         searchStoreList?.map((item, index) => (
                              <li
                                   key={"search-item" + item.businessName + index}
                                   className="search-item"
                                   onClick={() => {
                                        onClickStoreName(item);
                                   }}
                                   onMouseLeave={onMouseLeaveList(item.managementNumber)}
                                   onMouseEnter={onMouseEnterList(item.managementNumber)}
                              >
                                   <div className="search-item-title">
                                        <h2>{item?.businessName}</h2>
                                        {item?.distance && item.distance !== 0 ? (
                                             <span className="dst">{item.distance.toLocaleString()} m</span>
                                        ) : item?.distance && item.distance === 0 ? (
                                             <span className="distance">{/* <RiPushpin2Fill /> */}</span>
                                        ) : null}
                                   </div>

                                   <div className="search-item-sub">
                                        <p className="category">{item?.detailedBusinessStatusName}</p>
                                   </div>
                                   <p className="address">{item?.fullRoadAddress}</p>
                              </li>
                         ))}
               </ul>
          </TobaccoSearchBarWrap>
     );
});

export default SidebarTobaccoSearchList;
