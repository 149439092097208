import Api from "./index";
import { sub, format } from "date-fns";
// import axios from 'axios';

// post store name
export const postStoreSearchApi = ({ storeName, account, offset, category }) => {
     return Api.post(`/place/list`, {
          search_str: storeName,
          account,
          offset,
          category,
     });
};

// post store name
export const postTobaccoStoreSearchApi = ({ storeName, account, includeClosedWithin, includeSuspendedStore }) => {
     return Api.post(`/licensed/store/search`, {
          searchString: storeName,
          account,
          includeClosedWithin,
          includeSuspendedStore,
     });
};

// post store distance
export const postStoreDistanceApi = ({ lat, lng, distance, account }) => {
     const bodyParameters = {
          lat,
          lng,
          distance: parseInt(distance),
          account,
     };
     return Api.post(`/place/list`, bodyParameters);
};

// post tobacco store distance
export const postTobaccoStoreDistanceApi = ({ lat, lng, distance, account, includeClosedWithin, includeSuspendedStore }) => {
     const bodyParameters = {
          lat,
          lng,
          distance: parseInt(distance),
          account,
          includeClosedWithin,
          includeSuspendedStore,
     };
     return Api.post(`/licensed/store/nearby`, bodyParameters);
};

// post caltex list
export const postCaltexListApi = ({ lat, lng, account }) => {
     const bodyParameters = {
          lat,
          lng,
          account,
          sub_category_code: "C4001",
     };
     return Api.post(`/place/list`, bodyParameters);
};

// post store info
export const postStoreInfoApi = ({ id, account, date }) => {
     const futureDate = sub(new Date(), {
          months: date[0],
     });

     const bodyParameters = {
          id,
          start_date: format(futureDate, "yyyy-MM-dd"),
          end_date: format(new Date(), "yyyy-MM-dd"),
          account,
     };

     return Api.post(`/place/info`, bodyParameters);
};

// post store info
export const postTobaccoStoreInfoApi = ({ managementNumber, account }) => {
     const bodyParameters = {
          managementNumber,
          account,
     };

     return Api.post(`/licensed/store/info`, bodyParameters);
};

/* 리뷰 카드 조회 */
export const getReviewInfoApi = ({ account }) => {
     const bodyParameters = {
          account,
     };

     return Api.post(`/review/info`, bodyParameters);
};

/* 로그인 사용자 정보 조회 */
export const getUserInfoApi = ({ account, kor_nm }) => {
     const bodyParameters = {
          account,
          kor_nm,
     };

     return Api.post(`/user/info`, bodyParameters);
};

/* 담당점포/담당OFC 리뷰 등록 현황 요약 조회 */
export const getReviewStatusApi = ({ account }) => {
     const bodyParameters = {
          account,
     };

     return Api.post(`/review/status`, bodyParameters);
};

export const postCheckAddressByCoord = ({ account, lng, lat }) => {
     const bodyParameters = {
          lng,
          lat,
          account,
     };

     return Api.post(`/coordinate/check`, bodyParameters);
};

export const postChangeStoreAddress = ({ account, lng, lat, username, managementNumber, fullRoadAddress, fullLocationAddress }) => {
     const bodyParameters = {
          account,
          username,
          managementNumber,
          fullRoadAddress,
          fullLocationAddress,
          lng,
          lat,
     };

     return Api.post(`/licensed/address/update`, bodyParameters);
};

export const registerTobaccoStoreBookmark = ({ account, username, managementNumber }) => {
     const bodyParameters = {
          account,
          username,
          managementNumber,
     };

     return Api.post(`/bookmark/regist/store`, bodyParameters);
};

export const registerTobaccoAddressBookmark = ({ account, username, fullRoadAddress, fullLocationAddress, lng, lat }) => {
     const bodyParameters = {
          account,
          username,
          fullRoadAddress,
          fullLocationAddress,
          lng,
          lat,
     };

     return Api.post(`/bookmark/regist/coordinate`, bodyParameters);
};

export const removeTobaccoStoreBookmark = ({ account, bookmarkId }) => {
     const bodyParameters = {
          account,
          bookmarkId,
     };

     return Api.post(`/bookmark/remove`, bodyParameters);
};

export const getBookmarkListApi = ({ account }) => {
     const bodyParameters = {
          account,
     };
     return Api.post(`/bookmark/list`, bodyParameters);
};
