import { IMAGES_CODE, SUB_CATEGORY } from "../../data/code";

import { NearestStoreListWrap, ImageWrap } from "./NearestStore.styled";

const NearestStoreV2 = ({ nearStoreFromActive, onClickStore }) => {
     const getBrandImage = (imageSrc, brand) => {
          if (imageSrc) return <img src={imageSrc} alt={SUB_CATEGORY[brand]} />;

          const isStore = brand[1] === "1";
          const isMarket = brand[1] === "2";
          const isOil = brand[1] === "4";
          const isFood = brand[1] === "3";

          return (
               <div
                    style={{ width: 26, height: 26, background: "white", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "50%" }}
               >
                    <img
                         className="brand-other-icon"
                         src={`img/icons/svg/color_${isStore ? "store" : isMarket ? "mart-station" : isOil ? "gas-station" : isFood ? "side-dish-station" : "meat"}.svg`}
                         alt={brand}
                    />
               </div>
          );
     };

     return (
          <NearestStoreListWrap left={nearStoreFromActive?.position?.left ?? 0} bottom={nearStoreFromActive?.position?.bottom ?? 0}>
               <ul>
                    {nearStoreFromActive?.stores.map((store, i) => {
                         const brand = store.sub_category_code;
                         const isStore = brand[1] === "1";
                         const isMarket = brand[1] === "2";
                         const isOil = brand[1] === "4";
                         const isFood = brand[1] === "3";

                         const borderColor = isStore ? "#4B87F7" : isMarket ? "#24C69A" : isOil ? "#EA346F" : isFood ? "#7944E3" : "#F6C044";

                         return (
                              <li
                                   key={store.name + i}
                                   className="marker-button-child-item"
                                   onClick={() => {
                                        onClickStore(store);
                                   }}
                              >
                                   <ImageWrap borderColor={borderColor}>
                                        {getBrandImage(IMAGES_CODE[SUB_CATEGORY[store.sub_category_code]], store.sub_category_code)}
                                   </ImageWrap>

                                   <div>{store.name.length > 12 ? `${store.name.slice(0, 11)}...` : store.name}</div>
                              </li>
                         );
                    })}
               </ul>
          </NearestStoreListWrap>
     );
};

export default NearestStoreV2;
