import styled from "@emotion/styled";
import Modal from "..";
import { DISTANCE_100, DISTANCE_1000, DISTANCE_150, DISTANCE_300, DISTANCE_50, DISTANCE_500, DISTANCE_75 } from "../../../constants/map";
import MobileDistanceController from "../../mobileDistanceController";

const distList = [
     { text: "150M", value: DISTANCE_150 },
     { text: "300M", value: DISTANCE_300 },
     { text: "500M", value: DISTANCE_500 },
     { text: "1KM", value: DISTANCE_1000 },
];

const circleDistList = [
     { text: "50M", value: DISTANCE_50 },
     { text: "75M", value: DISTANCE_75 },
     { text: "100M", value: DISTANCE_100 },
     { text: "300M", value: DISTANCE_300 },
];

const MobileTobaccoStoreDistanceModal = ({ open, onClose, distance, circleDistance, onClickDistanceButton, onClickCircleDistanceButton }) => {
     return (
          <Modal isTransparent open={open} onClose={onClose} noHeader headCloseButton={false} width={240}>
               <Title>조회 범위</Title>
               <MobileDistanceController controlList={distList} distance={distance} onChangeDistance={onClickDistanceButton} />
               <div style={{ height: 40 }} />
               <Title>주변 점포 반경</Title>
               <MobileDistanceController controlList={circleDistList} distance={circleDistance} onChangeDistance={onClickCircleDistanceButton} />
          </Modal>
     );
};

export default MobileTobaccoStoreDistanceModal;

const Title = styled.div`
     color: #fff;
     text-align: center;
     font-size: 21px;
     font-style: normal;
     font-weight: 900;
     line-height: normal;
     letter-spacing: 0.16px;
     padding-bottom: 10px;
`;
