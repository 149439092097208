export const LICENSE_KEY = "09a113ecdba820f0fec7ea28633adbf3Tz04NjkzNCxFPTE3NDI2NDczNDQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y";

export const CONTROLLER_VERSION = "MVP-STORE-CONTROLLER-V2.2";
export const TOBACCO_MAP_CONTROLLER = "MVP-STORE-TOBACCO-CONTROLLER-V2.2";

export const DISTANCE_50 = "50";
export const DISTANCE_75 = "75";
export const DISTANCE_100 = "100";
export const DISTANCE_150 = "150";
export const DISTANCE_300 = "300";
export const DISTANCE_500 = "500";
export const DISTANCE_1000 = "1000";

export const SHUTDOWN_PERIOD_3 = "3";
export const SHUTDOWN_PERIOD_6 = "6";

export const INITIAL_STORE_MAP_CODE_FILTER = {
     C1000: ["C1001", "C1002", "C1003", "C1004"],
     C2000: ["C2001", "C2003", "C2005", "C2008"],
     C3000: null,
     C4000: null,
     C5000: null,
};

export const INITIAL_STORE_MAP_FILTER = JSON.stringify({
     mapFilter: INITIAL_STORE_MAP_CODE_FILTER,
     distance: DISTANCE_500,
});

export const INITIAL_TOBACCO_MAP_FILTER = {
     shutdownPeriod: SHUTDOWN_PERIOD_3,
     circleDistance: DISTANCE_75,
     distance: DISTANCE_500,
     includeClosedWithin: 0,
     showOpenStore: true,
     showSuspendedStore: true,
     showFavorite: false,
};

export const INITIAL_LAT = 37.501921162780974;
export const INITIAL_LNG = 127.03726398429752;
