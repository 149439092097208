const IconBookmark = ({ active = false }) => {
     const color = active ? "#206DEF" : "#50505080";

     return (
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
               <path
                    d="M16.8334 7.25273C16.786 7.11555 16.6998 6.99505 16.5854 6.90586C16.4709 6.81666 16.333 6.7626 16.1884 6.75023L11.9209 6.12773L10.0084 2.25023C9.94694 2.12343 9.85105 2.01649 9.73167 1.94166C9.61229 1.86683 9.47425 1.82715 9.33336 1.82715C9.19246 1.82715 9.05442 1.86683 8.93504 1.94166C8.81566 2.01649 8.71977 2.12343 8.65836 2.25023L6.74586 6.12023L2.47836 6.75023C2.33955 6.76996 2.20905 6.8282 2.10167 6.91835C1.99429 7.0085 1.91433 7.12694 1.87086 7.26023C1.83107 7.39049 1.8275 7.52912 1.86053 7.66125C1.89356 7.79338 1.96195 7.91402 2.05836 8.01023L5.15586 11.0102L4.40586 15.2702C4.37908 15.4108 4.3931 15.5562 4.44626 15.6891C4.49942 15.822 4.5895 15.9369 4.70586 16.0202C4.81927 16.1013 4.95304 16.1492 5.09214 16.1584C5.23125 16.1677 5.37019 16.138 5.49336 16.0727L9.33336 14.0702L13.1584 16.0802C13.2636 16.1396 13.3825 16.1706 13.5034 16.1702C13.6622 16.1708 13.8172 16.1209 13.9459 16.0277C14.0622 15.9444 14.1523 15.8295 14.2055 15.6966C14.2586 15.5637 14.2726 15.4183 14.2459 15.2777L13.4959 11.0177L16.5934 8.01773C16.7016 7.92599 16.7817 7.80541 16.8242 7.66999C16.8666 7.53458 16.8698 7.38989 16.8334 7.25273ZM12.2209 10.2527C12.1329 10.3378 12.0671 10.4431 12.0292 10.5595C11.9913 10.6758 11.9824 10.7997 12.0034 10.9202L12.5434 14.0627L9.72336 12.5627C9.61485 12.505 9.49379 12.4747 9.37086 12.4747C9.24792 12.4747 9.12687 12.505 9.01836 12.5627L6.19836 14.0627L6.73836 10.9202C6.75931 10.7997 6.75044 10.6758 6.71253 10.5595C6.67462 10.4431 6.60881 10.3378 6.52086 10.2527L4.27086 8.00273L7.42836 7.54523C7.54986 7.52833 7.66536 7.48189 7.76473 7.40997C7.86411 7.33805 7.94433 7.24286 7.99836 7.13273L9.33336 4.27523L10.7434 7.14023C10.7974 7.25036 10.8776 7.34555 10.977 7.41747C11.0764 7.48939 11.1919 7.53583 11.3134 7.55273L14.4709 8.01023L12.2209 10.2527Z"
                    fill={color}
                    fillOpacity="0.5"
               />
          </svg>
     );
};

export default IconBookmark;
