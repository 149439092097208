import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { postStoreInfo, storeDetailReset, postFixedDistance } from "../../reducers/store";

// hooks
import { OnIsTabletOrMobile } from "../../hook/widthSize";

import { userInfo } from "../../reducers/user";
import { getEndMarkerIcon, getStartMarkerIcon, handleEndMarkerDelete, handleStartMarkerDelete, markerImage } from "../../utils/markerUtils";
import { CONTROLLER_VERSION, DISTANCE_500, INITIAL_LAT, INITIAL_LNG, INITIAL_STORE_MAP_CODE_FILTER, INITIAL_STORE_MAP_FILTER } from "../../constants/map";
import { HomeWrap, PcHomeFilterOpenBtn } from "./HomeWrap.styled";

import { Circle } from "../../constants/naver";
import { TAB } from "../../constants/menu";
import SidebarContents from "../../components/sidebarV2/SidebarContents";
import { handleRightClick, handleStoreMapClick } from "../../utils/tobaccoEventHandler";
import { createStoreMarkerEventHandlers, storeMarkerOverlap } from "../../utils/mapMarker";

import { updateMapFilter } from "../../utils/validator";
import PcStoreFilterTop from "../../components/pcStoreFilterTop/PcStoreFilterTop";
import PcHomeFilter from "../../components/pcHomeFilter/PcHomeFilter";
import PcStoreRightButtons from "../../components/pcStoreRightButtons/PcStoreRightButtons";
import PcStoreDetail from "../../containers/PcStoreDetail/PcStoreDetail";
import NearestStoreV2 from "../../components/NearestStore/NearestStoreV2";
import PcSidebar from "../../components/sidebar/PcSideBar";
import { postCheckAddressByCoord } from "../../api/store";
import PcDirection from "../../components/pcDirection/PcDirection";
const { naver } = window;

const HomePage = ({ sidebarOpen, handleChangeSidebarOpen }) => {
     const map = useRef();
     const { accounts } = useMsal();
     const isTabletOrMobile = OnIsTabletOrMobile();

     const dispatch = useDispatch();
     const userInfoData = useSelector(state => state.user);

     const { storeList, searchStoreList } = useSelector(state => state.store);

     const [tab, setTab] = useState(TAB.LIST);
     const [activeStore, setActiveStore] = useState({
          open: false,
          data: null,
     });

     const [nearStoreFromActive, setNearStoreFromActive] = useState(null);

     const [showFilterPage, setShowFilterPage] = useState(false);

     const [codeFilter, setCodeFilter] = useState(null);

     const [distance, setDistance] = useState(DISTANCE_500);

     const markers = useRef([]);

     const rightClickInfoWindowRef = useRef(null);
     const startMarkerRef = useRef(null);

     const endMarkerRef = useRef(null);
     const markerInfoWindowRef = useRef(null);

     const [directionStart, setDirectionStart] = useState({ coordinate: null, name: null });
     const [directionEnd, setDirectionEnd] = useState({ coordinate: null, name: null });
     const [findDirectionAddress, setFindDirectionAddress] = useState({ type: null, coordinate: null });
     const [directionDrawerOpen, setDirectionDrawerOpen] = useState(false);

     const updateStoreList = useCallback(
          (lat, lng, distance, codeFilter) => {
               dispatch(
                    postFixedDistance({
                         lat,
                         lng,
                         distance,
                         account: accounts[0]["username"],
                         codeFilter,
                    }),
               );
          },
          [accounts],
     );

     useEffect(() => {
          handleStartMarkerDelete(startMarkerRef, markerInfoWindowRef);

          if (directionStart?.isPlace && directionStart.coordinate?.x && directionStart.coordinate?.y && !startMarkerRef.current) {
               const startMarker = new naver.maps.Marker({
                    position: directionStart.coordinate,
                    map: map.current,
                    icon: getStartMarkerIcon(),
               });
               startMarkerRef.current = startMarker;
          }
     }, [directionStart]);

     useEffect(() => {
          handleEndMarkerDelete(endMarkerRef, markerInfoWindowRef);

          if (directionEnd?.isPlace && directionEnd.coordinate?.x && directionEnd.coordinate?.y && !endMarkerRef.current) {
               handleEndMarkerDelete(endMarkerRef, markerInfoWindowRef);
               const endMarker = new naver.maps.Marker({
                    position: directionEnd.coordinate,
                    map: map.current,
                    icon: getEndMarkerIcon(),
               });
               endMarkerRef.current = endMarker;
          }
     }, [directionEnd]);

     const resetDirection = () => {
          setDirectionStart({ coordinate: null, name: null, isPlace: false });
          setDirectionEnd({ coordinate: null, name: null, isPlace: false });
     };

     const handleChangeTab = tab => setTab(tab);

     const onChangeDragend = useCallback(
          e => {
               if (map.current.getZoom() > 10 && !Circle.getMap()) {
                    const position = map.current.getCenter();

                    updateStoreList(position.y, position.x, distance, codeFilter);
               }
          },
          [accounts, dispatch, distance],
     );

     const onDragStart = e => {
          setNearStoreFromActive(null);
     };

     const onChangeSize = e => {
          map.current.autoResize();
          setNearStoreFromActive(null);
     };

     const deleteCircle = (y, x) => {
          Circle.setMap(null);
     };

     const onClickDistanceButton = distance => () => {
          const newCodeFilter = {
               mapFilter: codeFilter,
               distance,
          };
          if (!activeStore.open) {
               const position = map.current.getCenter();
               updateStoreList(position.y, position.x, distance, codeFilter);
          } else {
               const position = Circle.getCenter();
               Circle.setRadius(distance);
               updateStoreList(position.y, position.x, distance, distance);
          }
          localStorage.setItem(CONTROLLER_VERSION, JSON.stringify(newCodeFilter));
          setDistance(distance);
     };

     const onChangeSideBar = () => {
          handleChangeSidebarOpen(prev => !prev);
          const timeOut = setTimeout(() => {
               map.current.autoResize();
               clearTimeout(timeOut);
          }, 350);
     };

     const setCircle = useCallback(
          (y, x) => {
               Circle.setCenter(naver.maps.LatLng(y, x));
               Circle.setRadius(distance);
               Circle.setMap(map.current);
          },
          [distance, map],
     );

     const onClickStore = useCallback(
          store => {
               dispatch(
                    postStoreInfo({
                         account: accounts[0]["username"],
                         id: store.id,
                    }),
               );
               updateStoreList(store.y, store.x, distance, codeFilter);
               setActiveStore({ open: true, data: store });
               setCircle(store.y, store.x);
               map.current.setCenter(naver.maps.LatLng(store.y, store.x - 0.0008));
          },
          [dispatch, accounts, distance, codeFilter, setCircle],
     );

     const onClickStoreDetailClose = useCallback(() => {
          setActiveStore({ open: false, data: null });
          deleteCircle();
          dispatch(storeDetailReset());
          if (activeStore?.data) {
               const marker = document.getElementById(`${activeStore.data.name}`);
               marker && marker.parentElement.classList.remove("active");
          }
     }, [activeStore.data, dispatch]);

     const onChangeZoom = useCallback(
          e => {
               setNearStoreFromActive(null);
               if (e <= 10) {
                    if (markers.current.length > 0) {
                         markers.current.forEach(marker => {
                              marker["marker"].setMap(null);
                         });
                         markers.current = [];
                    }
                    onClickStoreDetailClose();
               } else if (!Circle.getMap()) {
                    const position = map.current.getCenter();
                    updateStoreList(position.y, position.x, distance, codeFilter);
               }
          },
          [accounts, dispatch, distance, onClickStoreDetailClose],
     );

     useEffect(() => {
          if (userInfoData?.kor_nm == null) {
               dispatch(
                    userInfo({
                         account: accounts[0]["username"],
                         kor_nm: accounts[0]["name"],
                    }),
               );
          }
     }, [accounts, dispatch, userInfoData]);

     useEffect(() => {
          const mvpStoreLocalStorage = localStorage.getItem(CONTROLLER_VERSION);

          if (!mvpStoreLocalStorage) {
               localStorage.setItem(CONTROLLER_VERSION, INITIAL_STORE_MAP_FILTER);

               setCodeFilter(INITIAL_STORE_MAP_CODE_FILTER);
               setDistance(DISTANCE_500);
          } else {
               const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);

               setCodeFilter(mvpStoreLocalStorageJson.mapFilter);
               setDistance(mvpStoreLocalStorageJson.distance);
          }
     }, []);

     useEffect(() => {
          const mvpStoreLocalStorage = localStorage.getItem(CONTROLLER_VERSION);

          const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);

          const { distance, mapFilter } = mvpStoreLocalStorageJson;

          map.current = new naver.maps.Map("map", {
               center: new naver.maps.LatLng(INITIAL_LAT, INITIAL_LNG),
               zoom: 18,
          });

          if (navigator?.geolocation) {
               navigator.geolocation.getCurrentPosition(
                    position => {
                         const golocationPosition = new naver.maps.LatLng(position.coords.latitude, position.coords.longitude);

                         map.current.setCenter(golocationPosition);

                         const { latitude, longitude } = position.coords;

                         updateStoreList(latitude, longitude, distance, mapFilter);
                    },
                    error => {
                         console.log("위치 정보 미동의");

                         updateStoreList(INITIAL_LAT, INITIAL_LNG, distance, mapFilter);
                    },
                    {
                         enableHighAccuracy: false,
                         maximumAge: 0,
                         timeout: Infinity,
                    },
               );
          } else {
               console.error("GPS를 지원하지 않습니다");
               updateStoreList(INITIAL_LAT, INITIAL_LNG, distance, mapFilter);
          }
     }, [accounts, dispatch]);

     useEffect(() => {
          if (storeList) {
               if (markers.current.length > 0) {
                    markers.current.forEach(marker => {
                         marker["marker"].setMap(null);
                    });
                    markers.current = [];
               }

               const overlap = storeMarkerOverlap(storeList, map, codeFilter);

               overlap.forEach(store => {
                    const content = markerImage(store, activeStore, userInfoData);

                    const marker = new naver.maps.Marker({
                         map: map.current,
                         position: store.coord,
                         icon: {
                              content: content,
                              size: new naver.maps.Size(30, 30),
                              anchor: new naver.maps.Point(20, 45),
                         },
                    });

                    markers.current = [...markers.current, { ...store, marker }];

                    if (marker) {
                         const { mouseOverHandler, mouseOutHandler, clickHandler } = createStoreMarkerEventHandlers(
                              marker,
                              store,
                              onClickStore,
                              deleteCircle,
                              map,
                              activeStore,
                         );
                    }
               });
          }
     }, [storeList, distance, codeFilter, accounts, activeStore, onClickStore, userInfoData]);

     useEffect(() => {
          const clickEvent = handleStoreMapClick(map, rightClickInfoWindowRef);

          const rightClickEvent = handleRightClick(map, setFindDirectionAddress, rightClickInfoWindowRef);

          const dragStartEvent = naver.maps.Event.addListener(map.current, "dragstart", onDragStart);
          const centerEvent = naver.maps.Event.addListener(map.current, "dragend", onChangeDragend);
          const zoomEvent = naver.maps.Event.addListener(map.current, "zoom_changed", onChangeZoom);
          const sizeEvent = naver.maps.Event.addListener(map.current, "size_changed", onChangeSize);

          return () => {
               naver.maps.Event.removeListener(clickEvent);
               naver.maps.Event.removeListener(rightClickEvent);
               naver.maps.Event.removeListener(centerEvent);
               naver.maps.Event.removeListener(zoomEvent);
               naver.maps.Event.removeListener(sizeEvent);
               naver.maps.Event.removeListener(dragStartEvent);
          };
     }, [accounts, distance, onChangeDragend, onChangeZoom]);

     useEffect(() => {
          map.current.autoResize();
     }, [activeStore.open]);

     /** 매장 활성화 ClassName 지정 */
     useEffect(() => {
          if (activeStore.data) {
               setTimeout(() => {
                    const marker = document.getElementById(`${activeStore.data.name}`);
                    marker && marker.parentElement.classList.add("active");

                    const rect = marker?.getBoundingClientRect();
                    const currentStoreInfoFromMarker = markers.current.find(el => el.id === activeStore.data.id);

                    if (currentStoreInfoFromMarker && currentStoreInfoFromMarker.child.length > 0) {
                         setNearStoreFromActive({
                              stores: currentStoreInfoFromMarker.child,
                              position: {
                                   bottom: rect.bottom,
                                   left: rect.left,
                              },
                         });
                    } else {
                         setNearStoreFromActive(null);
                    }
               }, 800);
          }
     }, [activeStore]);
     const handleClickFilterReset = e => {
          e.stopPropagation();

          const mvpStoreLocalStorage = localStorage.getItem(CONTROLLER_VERSION);
          const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);

          const newMvpStoreController = {
               ...mvpStoreLocalStorageJson,
               mapFilter: INITIAL_STORE_MAP_CODE_FILTER,
          };
          localStorage.setItem(CONTROLLER_VERSION, JSON.stringify(newMvpStoreController));

          setCodeFilter(INITIAL_STORE_MAP_CODE_FILTER);
     };

     const onClickFilterButton =
          ({ mainCode, subCode }) =>
          e => {
               e.stopPropagation();

               const mvpStoreLocalStorage = localStorage.getItem(CONTROLLER_VERSION);
               const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);

               let newMapFilter = {};

               if (mvpStoreLocalStorageJson.mapFilter?.[mainCode]) {
                    if (subCode) {
                         newMapFilter = {
                              ...mvpStoreLocalStorageJson.mapFilter,
                              [mainCode]: mvpStoreLocalStorageJson.mapFilter[mainCode].includes(subCode)
                                   ? mvpStoreLocalStorageJson.mapFilter[mainCode]?.length > 1
                                        ? mvpStoreLocalStorageJson.mapFilter[mainCode].filter(s => s !== subCode)
                                        : null
                                   : [...mvpStoreLocalStorageJson.mapFilter[mainCode], subCode],
                         };
                    } else {
                         newMapFilter = { ...mvpStoreLocalStorageJson.mapFilter };
                         delete newMapFilter[mainCode];
                    }
               } else {
                    if (!subCode) {
                         newMapFilter = updateMapFilter(mvpStoreLocalStorageJson, mainCode);
                    } else {
                         newMapFilter = {
                              ...mvpStoreLocalStorageJson.mapFilter,
                              [mainCode]: [subCode],
                         };
                    }
               }

               const newMvpStoreController = {
                    ...mvpStoreLocalStorageJson,
                    mapFilter: newMapFilter,
               };

               localStorage.setItem(CONTROLLER_VERSION, JSON.stringify(newMvpStoreController));

               setCodeFilter(newMapFilter);
          };

     const handleChangeFilterPage = () => {
          setShowFilterPage(prev => !prev);
     };

     const getAddressByCoords = async (lng, lat) => {
          try {
               const newAddrData = await postCheckAddressByCoord({
                    lng,
                    lat,
                    account: accounts[0]["username"],
               });

               return newAddrData.data;
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
               return null;
          }
     };

     const handleClickSetDirectionWithPoint = async (type, coord) => {
          try {
               const data = await getAddressByCoords(coord.x, coord.y);
               const addressData = data.data;
               const address = addressData.fullRoadAddress ?? addressData.fullLocationAddress ?? "";

               if (type === "START") {
                    setDirectionStart({ coordinate: { x: coord.x, y: coord.y }, name: address, isPlace: true });
                    setDirectionDrawerOpen(true);
               }

               if (type === "END") {
                    setDirectionEnd({ coordinate: { x: coord.x, y: coord.y }, name: address, isPlace: true });
                    setDirectionDrawerOpen(true);
               }
               // handleClickCloseDirectionWithPoint();
          } catch {
               // handleClickCloseDirectionWithPoint();
          }
     };

     useEffect(() => {
          if (findDirectionAddress.type) {
               handleClickSetDirectionWithPoint(findDirectionAddress.type, findDirectionAddress.coordinate);
          }
     }, [findDirectionAddress]);

     return (
          <>
               {!activeStore.open && (
                    <PcHomeFilterOpenBtn sidebarOpen={sidebarOpen} onClick={handleChangeFilterPage}>
                         <img src={"img/icons/filter-icon.png"} alt="mobile-filter" width={16} height={16} />
                    </PcHomeFilterOpenBtn>
               )}
               {!activeStore.open && <PcHomeFilter sidebarOpen={sidebarOpen} codeFilter={codeFilter} onClickFilterButton={onClickFilterButton} />}

               {!activeStore.open && showFilterPage && (
                    <PcStoreFilterTop
                         sidebarOpen={sidebarOpen}
                         codeFilter={codeFilter}
                         handleClickFilterReset={handleClickFilterReset}
                         onClickFilterButton={onClickFilterButton}
                         onClose={handleChangeFilterPage}
                    />
               )}

               <PcStoreRightButtons
                    handleChangeDirectionStatus={() => setDirectionDrawerOpen(prev => !prev)}
                    onClickDistanceButton={onClickDistanceButton}
                    map={map}
                    updateStoreList={updateStoreList}
                    distance={distance}
                    mapFilter={codeFilter}
               />

               <HomeWrap isTabletOrMobile={isTabletOrMobile}>
                    <div className="left-container">
                         <PcSidebar open={sidebarOpen} onChangeSideBar={onChangeSideBar}>
                              {sidebarOpen && (
                                   <SidebarContents
                                        activeStore={activeStore}
                                        map={map}
                                        tab={tab}
                                        account={accounts[0]["username"]}
                                        searchStoreList={searchStoreList}
                                        defaultList={storeList}
                                        searchCodeFilter={codeFilter}
                                        setDirectionStart={setDirectionStart}
                                        setDirectionEnd={setDirectionEnd}
                                        onClickStore={onClickStore}
                                        handleChangeTab={handleChangeTab}
                                        resetDirection={resetDirection}
                                   />
                              )}
                         </PcSidebar>
                         {activeStore.open && !isTabletOrMobile && (
                              <PcStoreDetail
                                   activeStore={activeStore.data}
                                   sidebarOpen={sidebarOpen}
                                   user={accounts[0]["name"]}
                                   handleChangeTab={handleChangeTab}
                                   close={onClickStoreDetailClose}
                                   setDirectionStart={setDirectionStart}
                                   setDirectionEnd={setDirectionEnd}
                              />
                         )}
                    </div>

                    <div id="map" style={{ width: "100%", height: "100%" }} />
                    {directionDrawerOpen && (
                         <PcDirection
                              directionStart={directionStart}
                              directionEnd={directionEnd}
                              setDirectionStart={setDirectionStart}
                              setDirectionEnd={setDirectionEnd}
                         />
                    )}

                    {nearStoreFromActive && <NearestStoreV2 nearStoreFromActive={nearStoreFromActive} onClickStore={onClickStore} />}
               </HomeWrap>
          </>
     );
};

export default HomePage;
