const IconCigarette = ({ active = false }) => {
     const color = active ? "#3871E0" : "black";

     return (
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                    d="M18.083 15.5834H5.24967C5.00656 15.5834 4.7734 15.68 4.60149 15.8519C4.42959 16.0238 4.33301 16.2569 4.33301 16.5C4.33301 16.7432 4.42959 16.9763 4.60149 17.1482C4.7734 17.3201 5.00656 17.4167 5.24967 17.4167H18.083C18.3261 17.4167 18.5593 17.3201 18.7312 17.1482C18.9031 16.9763 18.9997 16.7432 18.9997 16.5C18.9997 16.2569 18.9031 16.0238 18.7312 15.8519C18.5593 15.68 18.3261 15.5834 18.083 15.5834Z"
                    fill={color} // ensure `color` is valid
               />
               <ellipse cx="5.70801" cy="6.875" rx="1.375" ry="1.375" fill={color} />
               <ellipse cx="5.70801" cy="11.4584" rx="1.375" ry="1.375" fill={color} />
          </svg>
     );
};
export default IconCigarette;
