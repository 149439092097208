import styled from "@emotion/styled";

interface IDetailWrap {
     height: number | null;
}

interface IItemData {
     border: false;
}

export const ItemData = styled.div<IItemData>`
     display: flex;
     align-items: center;
     padding: 12px 12px 12px 0px;
     border-top: ${({ border }) => (border ? "0.4px solid #BDBDBD" : "none")};
     .title {
          margin-left: 12px;
          color: #1e2530;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          box-sizing: border-box;
          min-width: 68px;
     }

     .expectations {
          display: flex;
          align-items: center;
          gap: 10px;
     }

     .expectation-change-amount {
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
     }

     .contents {
          color: #868782;

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
     }
     .expectation-amount {
          color: #868782;

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
     }
`;

export const DetailModeTop = styled.div`
     padding-bottom: 14px;
     padding-left: 18px;
`;

export const ManagementInfoTop = styled.div`
     color: #3d4043;
     text-align: center;

     font-size: 14px;
     font-style: normal;
     font-weight: 600;
     line-height: normal;
     width: 100%;
     text-align: left;
     padding-bottom: 10px;
`;

export const ManagementInfoWrap = styled.div`
     padding: 10px 0px 26px;
`;

export const ManagementContentsWrap = styled.div`
     box-sizing: border-box;
     background-color: #ffffff;
     width: 100%;
     border-radius: 10px;
     padding-left: 16px;
`;

export const StoreDetailWrap = styled.div<IDetailWrap>`
     box-sizing: border-box;
     z-index: 900;
     background-color: #f1f4f9;
     height: 100%;
     overflow: hidden;
     padding: 18px 0px;

     .active-store-info {
          padding: 0px 18px;
     }

     h2 {
          color: #1e2530;
          word-break: keep-all;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px; /* 100% */
          padding-bottom: 12px;
     }

     .active-store-details {
          color: #71757a;

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
     }

     .active-store-address {
          color: #1e2530;

          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
     }

     .info-default-manager {
          display: flex;
          align-items: top;
          justify-content: space-between;
          margin: 20px -4.5px 20px;

          > li {
               flex: 1;
               border: 1px solid #d1d5db;
               box-sizing: border-box;
               border-radius: 8px;
               margin: 0 4.5px;
               text-align: center;
               padding: 4px 4px 6px;
               overflow: hidden;
          }
          > li span {
               font-weight: 400;
               font-size: 14px;
               line-height: 18px;
          }
          > li p {
               font-weight: 700;
               font-size: 14px;
               line-height: 18px;
          }
     }

     .info-detail {
          overflow-y: auto;
          height: calc(100% - 200px);
          box-sizing: border-box;
          padding: 9px 18px 150px;
     }

     .info-detail-header {
          display: flex;
          align-items: center;
          border-bottom: 0.5px solid #868782;

          box-sizing: border-box;

          &.on {
               position: fixed;
               top: 53px;
               width: 360px;
               margin-left: -20px;
               z-index: 50;
               border-top-width: 0;
          }

          > li {
               flex: 1;
               text-align: center;
          }
          > li button {
               color: #1e2530;
               text-align: center;

               font-size: 14px;
               font-style: normal;
               font-weight: 500;
               line-height: 20px; /* 142.857% */
               letter-spacing: -0.23px;
               color: #868782;

               background-color: transparent;
               box-sizing: border-box;
               font-size: 16px;
               padding: 14px 0;
               width: 100%;
               &.active {
                    color: #1e2530;
                    font-weight: 700;
                    border-bottom: 2px solid #1e2530;
                    padding: 14px 0 11px;
                    font-weight: 700;
               }
          }
     }

     .info-detail-keyword {
          padding-bottom: 22px;
          border-bottom: 1px solid #e5e7eb;
     }

     .info-detail-keyword-header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
               display: flex;
               gap: 5px;
               color: #3d4043;

               font-size: 14px;
               font-style: normal;
               font-weight: 600;
               line-height: normal;
          }
          .accent {
               color: #216cf0;
          }

          .review-date-wrap {
               display: flex;
               gap: 2px;
               padding-bottom: 9px;

               div {
                    width: 46px;
                    height: 21px;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    font-size: 10px;
                    font-style: normal;
                    font-weight: 500;
                    color: rgba(186, 190, 196, 0.5);
                    line-height: normal;
                    background: #fff;
               }

               .active {
                    background: #babec4;
                    color: #f1f4f9;
               }
          }
     }
     .info-detail-review > ul {
          background-color: #fff;
          border-radius: 10px;
     }

     .info-detail-review > ul li {
          padding: 6px 0;
          border-bottom: 0.4px solid #bdbdbd;
     }

     /* 마지막 li 항목에서 border-bottom 제거 */
     .info-detail-review > ul li:last-child {
          border-bottom: none;
     }
     .info-detail-review .reviewer {
          overflow: hidden;
          color: #1e2530;
          text-overflow: ellipsis;

          font-style: normal;
          font-weight: 700;
          line-height: normal;
     }
     .info-detail-review .body {
          overflow: hidden;
          color: #1e2530;
          text-overflow: ellipsis;

          font-style: normal;
          font-weight: 500;
          line-height: normal;
     }

     .info-detail-review .rest {
          overflow: hidden;
          color: #868782;
          text-overflow: ellipsis;

          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
     }

     .info-detail-review-none {
          padding: 50px 0 !important;
          .info-detail-review-none-icon {
               display: inline-block;
               border-radius: 100%;
               border: 2px solid #666;
               margin-bottom: 20px;
               > svg {
                    font-size: 50px;
                    color: #666;
               }
          }
          text-align: center;
     }

     .review-contents {
          padding: 6px 16px;
     }
`;

export const StoreDetailInfoWrap = styled.div`
     background-color: #ffffff;
     border-radius: 10px;
     padding: 12px 0px 0px;
     margin: 10px 0px;

     .title {
          padding-left: 16px;
          overflow: hidden;
          color: #1e2530;
          text-overflow: ellipsis;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding-bottom: 12px;
     }

     .line-wrap {
          box-sizing: border-box;
          padding-left: 16px;
          padding-bottom: 14px;
          width: 100%;
          .line {
               width: 100%;
               height: 1px;
               background: #bdbdbd;
          }
     }

     .info-item {
          padding: 0px 16px 16px;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;

          .info-code-name {
               overflow: hidden;
               color: #1e2530;
               text-overflow: ellipsis;

               font-size: 12px;
               font-style: normal;
               font-weight: 400;
               line-height: normal;
          }

          .info-code-value {
               overflow: hidden;
               color: #1e2530;
               text-align: right;
               text-overflow: ellipsis;

               font-size: 12px;
               font-style: normal;
               font-weight: 400;
               line-height: normal;
          }
     }

     .info-code-value.yes-color {
          color: #216cf0;
     }

     .info-code-value.no-color {
          color: #f00;
     }

     .info-code-right {
          display: flex;
          align-items: center;
          width: 150px;
          justify-content: space-between;
     }

     .info-dode-pre-value {
          width: 80px;
          text-align: right;
          overflow: hidden;

          text-overflow: ellipsis;

          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
     }

     .info-dode-pre-value.up-color {
          color: #216cf0;
     }

     .info-dode-pre-value.down-color {
          color: #f00;
     }

     .info-dode-pre-value.diff-color {
          color: #1e2530;
     }
`;
