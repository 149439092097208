const IconDirectionArrow = ({}) => {
     return (
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
               <path
                    d="M15.8628 2.14519C15.5763 1.85311 15.2129 1.6481 14.8148 1.55387C14.4166 1.45964 13.9999 1.48003 13.6128 1.61269L3.00034 5.16019C2.57233 5.30029 2.19836 5.5698 1.93006 5.93152C1.66176 6.29323 1.51235 6.72932 1.50248 7.17957C1.4926 7.62982 1.62273 8.07203 1.87491 8.44517C2.12709 8.8183 2.48888 9.10395 2.91034 9.26269L6.84034 10.7627C6.92983 10.7969 7.01131 10.8492 7.0797 10.9163C7.14809 10.9834 7.20192 11.0639 7.23784 11.1527L8.73784 15.0902C8.89053 15.5057 9.16761 15.864 9.53132 16.1163C9.89503 16.3686 10.3277 16.5027 10.7703 16.5002H10.8228C11.2736 16.492 11.7105 16.3429 12.0723 16.0739C12.4341 15.805 12.7027 15.4295 12.8403 15.0002L16.3878 4.37269C16.5169 3.98939 16.5361 3.57765 16.4433 3.184C16.3505 2.79036 16.1494 2.43053 15.8628 2.14519ZM15.0003 3.90019L11.4153 14.5352C11.3737 14.6698 11.2901 14.7875 11.1767 14.8712C11.0634 14.9549 10.9262 15.0001 10.7853 15.0002C10.6453 15.0025 10.5078 14.9621 10.3913 14.8844C10.2747 14.8067 10.1846 14.6954 10.1328 14.5652L8.63284 10.6277C8.52409 10.3416 8.35638 10.0816 8.1406 9.86448C7.92482 9.6474 7.66579 9.47814 7.38034 9.36769L3.44284 7.86769C3.31001 7.81902 3.19583 7.72984 3.11643 7.61276C3.03703 7.49567 2.99642 7.3566 3.00034 7.21519C3.00046 7.0743 3.04565 6.93714 3.12932 6.82378C3.21299 6.71043 3.33074 6.62682 3.46534 6.58519L14.1003 3.03769C14.2224 2.98793 14.3563 2.97459 14.4859 2.99931C14.6154 3.02402 14.735 3.08573 14.8302 3.17696C14.9254 3.26819 14.9921 3.38504 15.0223 3.51339C15.0525 3.64173 15.0449 3.77608 15.0003 3.90019Z"
                    fill="#206DEF"
               />
          </svg>
     );
};

export default IconDirectionArrow;
