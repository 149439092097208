import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styled/reset.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./auth/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

if (window.location.href.includes("gsr-mvp-app-p001.azurewebsites.net")) {
     window.location.href = "https://storemap.gsrdt.com";
}

ReactDOM.render(
     <React.StrictMode>
          <MsalProvider instance={msalInstance}>
               <App />
          </MsalProvider>
     </React.StrictMode>,
     document.getElementById("root"),
);
