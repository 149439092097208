import styled from "@emotion/styled";
import { INITIAL_LAT, INITIAL_LNG } from "../../constants/map";
import IconDirection from "../MobileFilterV2/svgComponents/IconDirection";
import IconCurrent from "../MobileFilterV2/svgComponents/IconCurrent";

const { naver } = window;

const MobileStoreRightButtons = ({ map, updateStoreList, distance, mapFilter, handleOpenDistance, handleChangeDirectionStatus }) => {
     const handleClickCurrent = () => {
          if (navigator?.geolocation) {
               navigator.geolocation.getCurrentPosition(
                    position => {
                         const golocationPosition = new naver.maps.LatLng(position.coords.latitude, position.coords.longitude);

                         map.current.setCenter(golocationPosition);
                         const { latitude, longitude } = position.coords;

                         updateStoreList(latitude, longitude, distance, mapFilter);
                    },
                    error => {
                         window.alert("위치 정보 미동의: 현재 위치를 확인할 수 없습니다.");
                         return;
                         // console.log("위치 정보 미동의");
                         // updateStoreList(INITIAL_LAT, INITIAL_LNG, distance, mapFilter);
                    },
                    {
                         enableHighAccuracy: false,
                         maximumAge: 0,
                         timeout: Infinity,
                    },
               );
          }
     };

     return (
          <MobileStoreRightButtonsWrap>
               <MobileBtnIconsWrap onClick={handleChangeDirectionStatus}>
                    <IconDirection size={21} />
               </MobileBtnIconsWrap>
               <MobileBtnIconsWrap onClick={handleOpenDistance}>
                    <img src="img/icons/svg/meter.svg" alt="goToCurrentPosition" />
               </MobileBtnIconsWrap>
               <MobileBtnIconsWrap onClick={handleClickCurrent}>
                    <IconCurrent color={"black"} size={21} />
               </MobileBtnIconsWrap>
          </MobileStoreRightButtonsWrap>
     );
};

export default MobileStoreRightButtons;

const MobileStoreRightButtonsWrap = styled.div`
     position: fixed;
     top: 120px;
     right: 10px;
     z-index: 3;
     gap: 10px;
     display: flex;
     flex-direction: column;
`;

const MobileBtnIconsWrap = styled.div`
     width: 36px;
     height: 36px;
     background-color: #ffffff;
     border-radius: 50%;
     display: flex;
     justify-content: center;
     align-items: center;
     box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.18);
`;
