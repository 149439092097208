import styled from "@emotion/styled";

interface IDetailWrap {
     height: number | null;
}

interface IItemData {
     border: false;
}

export const TobaccoItemData = styled.div<IItemData>`
     display: flex;
     align-items: center;
     padding: 12px 16px 12px 0px;
     border-top: ${({ border }) => (border ? "0.4px solid #BDBDBD" : "none")};

     .business-status {
          overflow: hidden;
          color: #1e2530;
          text-overflow: ellipsis;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 72px;
     }
     .business-current {
          color: #868782;

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
     }

     .history-change-wrap {
          width: 100%;
          display: flex;
          flex-direction: column;

          .update-date {
               padding-top: 10px;
               width: 100%;
               text-align: left;
               color: #216cf0;

               font-size: 11px;
               font-style: normal;
               font-weight: 400;
               line-height: 0%; /* 0px */
               padding-bottom: 5px;
          }

          .user {
               color: #868782;
          }

          .address {
               overflow: hidden;
               color: #1e2530;
               text-overflow: ellipsis;

               font-size: 13px;
               font-style: normal;
               font-weight: 500;
               line-height: normal;
          }

          .before {
               padding-top: 5px;
               color: #868782;
          }
     }
`;

export const TobaccoDetailWrap = styled.div<IDetailWrap>`
     box-sizing: border-box;
     z-index: 900;
     background-color: #f1f4f9;
     height: 100%;
     overflow: auto;
     padding: 18px 0px;

     .active-tobacco-store-info {
          padding: 0px 18px;
     }
     h2 {
          color: #1e2530;

          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px; /* 100% */
          padding-bottom: 12px;
     }

     .active-tobacco-store-details {
          color: #71757a;

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 133.333% */
     }
`;

export const TobaccoManagementInfoTop = styled.div`
     color: #3d4043;
     text-align: center;

     font-size: 16px;
     font-style: normal;
     font-weight: 600;
     line-height: normal;
     width: 100%;
     text-align: left;
     padding-bottom: 10px;
`;

export const TobaccoManagementInfoWrap = styled.div`
     padding: 10px 18px 26px;
`;

export const TobaccoManagementContentsWrap = styled.div`
     box-sizing: border-box;
     background-color: #ffffff;
     width: 100%;
     border-radius: 10px;
     padding-left: 16px;
`;
