import { memo } from "react";

import { FilterControllerWrap, StoreList, StoreItem, FilterContents, FilterContentsTitle } from "./PcFilterControllerV2.styled";
import { MART_LIST, OIL_LIST, STORE_LIST } from "../../constants/store";
import IconMart from "../MobileFilterV2/svgComponents/IconMart";
import IconStore from "../MobileFilterV2/svgComponents/IconStore";
import IconOil from "../MobileFilterV2/svgComponents/IconOil";
import IconFood from "../MobileFilterV2/svgComponents/IconFood";
import IconMeat from "../MobileFilterV2/svgComponents/IconMeat";

const PcFilterController = memo(({ codeFilter, handleSetCodeFilter }) => {
     return (
          <FilterControllerWrap>
               <FilterContents>
                    <FilterContentsTitle>
                         <button className={`controller ${codeFilter?.["C2000"] && "active"}`} onClick={handleSetCodeFilter({ mainCode: "C2000" })}>
                              <IconMart size={16} active />
                         </button>
                         {`마트(${codeFilter?.["C2000"]?.length ?? 0})`}
                    </FilterContentsTitle>

                    <StoreList>
                         {MART_LIST.map(item => (
                              <StoreItem
                                   active={codeFilter?.["C2000"]?.includes(item.subCode)}
                                   accent={"#24C69A"}
                                   key={item.text}
                                   onClick={handleSetCodeFilter({
                                        mainCode: "C2000",
                                        subCode: item.subCode,
                                   })}
                              >
                                   {item.text}
                              </StoreItem>
                         ))}
                    </StoreList>
               </FilterContents>
               <FilterContents>
                    <FilterContentsTitle>
                         <button className={`controller ${codeFilter?.["C1000"] && "active"}`} onClick={handleSetCodeFilter({ mainCode: "C1000" })}>
                              <IconStore size={16} active />
                         </button>
                         {`편의점(${codeFilter?.["C1000"]?.length ?? 0})`}
                    </FilterContentsTitle>

                    <StoreList>
                         {STORE_LIST.map(item => (
                              <StoreItem
                                   active={codeFilter?.["C1000"]?.includes(item.subCode)}
                                   accent={"#4B87F7"}
                                   key={item.text}
                                   onClick={handleSetCodeFilter({
                                        mainCode: "C1000",
                                        subCode: item.subCode,
                                   })}
                              >
                                   {item.text}
                              </StoreItem>
                         ))}
                    </StoreList>
               </FilterContents>
               <FilterContents>
                    <FilterContentsTitle>
                         <button className={`controller ${codeFilter?.["4000"] && "active"}`} onClick={handleSetCodeFilter({ mainCode: "C4000" })}>
                              <IconOil size={16} active />
                         </button>
                         {`주유소(${codeFilter?.["C4000"]?.length ?? 0})`}
                    </FilterContentsTitle>

                    <StoreList>
                         {OIL_LIST.map(item => (
                              <StoreItem
                                   active={codeFilter?.["C4000"]?.includes(item.subCode)}
                                   accent={"#EA346F"}
                                   key={item.text}
                                   onClick={handleSetCodeFilter({
                                        mainCode: "C4000",
                                        subCode: item.subCode,
                                   })}
                              >
                                   {item.text}
                              </StoreItem>
                         ))}
                    </StoreList>
               </FilterContents>
               <div style={{ display: "flex", gap: 160 }}>
                    <FilterContents>
                         <FilterContentsTitle>
                              <button className={`controller ${codeFilter?.["3000"] && "active"}`} onClick={handleSetCodeFilter({ mainCode: "C3000" })}>
                                   <IconFood size={16} active />
                              </button>
                              반찬가게
                         </FilterContentsTitle>

                         <StoreList>
                              <StoreItem
                                   active={codeFilter?.["C3000"]?.length > 0}
                                   accent={"#7944E3"}
                                   onClick={handleSetCodeFilter({
                                        mainCode: "C3000",
                                   })}
                              >
                                   전체
                              </StoreItem>
                         </StoreList>
                    </FilterContents>
                    <FilterContents>
                         <FilterContentsTitle>
                              <button className={`controller ${codeFilter?.["3000"] && "active"}`} onClick={handleSetCodeFilter({ mainCode: "C5000" })}>
                                   <IconMeat size={16} active />
                              </button>
                              정육점
                         </FilterContentsTitle>

                         <StoreList>
                              <StoreItem
                                   active={codeFilter?.["C5000"]?.length > 0}
                                   accent={"#F6C044"}
                                   onClick={handleSetCodeFilter({
                                        mainCode: "C5000",
                                   })}
                              >
                                   전체
                              </StoreItem>
                         </StoreList>
                    </FilterContents>
               </div>
          </FilterControllerWrap>
     );
});

export default PcFilterController;
