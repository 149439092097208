import { SearchWrap } from "./MobileSearh.styled";
import NavigateBeforeIcon from "@mui/icons-material/ArrowBack";
import { ReactComponent as Search } from "../../assets/search.svg";
import { RiPushpin2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";

const MobileSearch = ({ onClickClose, listEl, onClickStoreName, codeFilter, onSearch, searchValue, onChangeSearchValue }) => {
     const { storeList, searchStoreList } = useSelector(state => state.store);
     return (
          <SearchWrap>
               <div className="search-header">
                    <div className="search-header-contents">
                         <button className="search-header-back" onClick={onClickClose}>
                              <NavigateBeforeIcon fontSize="large" sx={{ paddingRight: 2, width: 24, height: 24 }} />
                         </button>
                         <div className="search-input-wrap">
                              <input
                                   placeholder="점포명, 주소 검색"
                                   value={searchValue}
                                   onChange={onChangeSearchValue}
                                   onKeyDown={e => {
                                        if (e.key === "Enter") {
                                             onSearch()();
                                        }
                                   }}
                              />
                         </div>
                         <button onClick={onSearch()}>
                              <Search />
                         </button>
                    </div>
               </div>

               <ul id="search-list" className="section" ref={listEl}>
                    {(searchStoreList ? searchStoreList : storeList)?.map((item, index) => {
                         if (
                              (item["category"] === "편의점" && codeFilter?.["C1000"] && codeFilter["C1000"].includes(item["sub_category_code"])) ||
                              (item["category"] === "슈퍼,마트" && codeFilter?.["C2000"] && codeFilter["C2000"].includes(item["sub_category_code"])) ||
                              (item["category"] === "반찬가게" && codeFilter?.["C3000"]) ||
                              (item["category"] === "정육점" && codeFilter?.["C5000"]) ||
                              (item["category"] === "주유소" && codeFilter?.["C4000"] && codeFilter["C4000"].includes(item["sub_category_code"]))
                         ) {
                              return (
                                   <li
                                        key={"search-item" + item.id + index}
                                        className="search-item"
                                        onClick={() => {
                                             onClickStoreName(item);
                                        }}
                                   >
                                        <div className="search-item-title">
                                             <h2>{item?.name}</h2>
                                             {item?.dst && item.dst !== 0 ? (
                                                  <span className="dst">{item.dst.toLocaleString()} m</span>
                                             ) : item?.dst && item.dst === 0 ? (
                                                  <span className="dst">
                                                       <RiPushpin2Fill />
                                                  </span>
                                             ) : null}
                                        </div>

                                        <div className="search-item-sub">
                                             <p className="category">{`${item?.category} `}</p>
                                             {item?.phone && item?.phone !== "null" && <em className="phone">{`${item?.phone}`}</em>}
                                        </div>
                                        <p className="address">{item?.full_address}</p>
                                   </li>
                              );
                         }

                         return null;
                    })}
               </ul>
          </SearchWrap>
     );
};

export default MobileSearch;
