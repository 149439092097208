import { Checkbox } from "@mui/material";
import { TobaccoFilterControllerWrap, TobaccoFilterControlTop, TobaccoFilterControlBottom } from "./TobaccoFilterController.styled";

const TobaccoFilterController = ({ filterOption, handleCheckIncludeShutDownStore, fixed = true }) => {
     return (
          <TobaccoFilterControllerWrap fixed={fixed}>
               <TobaccoFilterControlTop>
                    <Checkbox
                         id="shutDown"
                         checked={filterOption?.includeClosedWithin > 0} // 기본값을 보장
                         onChange={handleCheckIncludeShutDownStore}
                    />{" "}
                    폐업점포 포함
               </TobaccoFilterControlTop>
               <TobaccoFilterControlBottom>
                    <div>
                         <Checkbox checked disabled />
                         3개월내
                    </div>
                    <div>
                         <Checkbox
                              id="sixMonths"
                              checked={filterOption?.includeClosedWithin > 5}
                              disabled={filterOption?.includeClosedWithin === 0}
                              onChange={handleCheckIncludeShutDownStore}
                         />
                         6개월내
                    </div>
               </TobaccoFilterControlBottom>
          </TobaccoFilterControllerWrap>
     );
};

export default TobaccoFilterController;
