const IconStore = ({ active = false, size = "24" }) => {
     const color = active ? "#4B87F7" : "rgba(61, 64, 67, 0.50)";
     return (
          <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                    d="M22 2H2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3V7C1.00174 7.61894 1.19488 8.2222 1.55294 8.72707C1.911 9.23193 2.41645 9.61369 3 9.82V21C3 21.2652 3.10536 21.5196 3.29289 21.7071C3.48043 21.8946 3.73478 22 4 22H20C20.2652 22 20.5196 21.8946 20.7071 21.7071C20.8946 21.5196 21 21.2652 21 21V9.82C21.5835 9.61369 22.089 9.23193 22.4471 8.72707C22.8051 8.2222 22.9983 7.61894 23 7V3C23 2.73478 22.8946 2.48043 22.7071 2.29289C22.5196 2.10536 22.2652 2 22 2ZM15 4H17V7C17 7.26522 16.8946 7.51957 16.7071 7.70711C16.5196 7.89464 16.2652 8 16 8C15.7348 8 15.4804 7.89464 15.2929 7.70711C15.1054 7.51957 15 7.26522 15 7V4ZM11 4H13V7C13 7.26522 12.8946 7.51957 12.7071 7.70711C12.5196 7.89464 12.2652 8 12 8C11.7348 8 11.4804 7.89464 11.2929 7.70711C11.1054 7.51957 11 7.26522 11 7V4ZM7 4H9V7C9 7.26522 8.89464 7.51957 8.70711 7.70711C8.51957 7.89464 8.26522 8 8 8C7.73478 8 7.48043 7.89464 7.29289 7.70711C7.10536 7.51957 7 7.26522 7 7V4ZM4 8C3.73478 8 3.48043 7.89464 3.29289 7.70711C3.10536 7.51957 3 7.26522 3 7V4H5V7C5 7.26522 4.89464 7.51957 4.70711 7.70711C4.51957 7.89464 4.26522 8 4 8ZM14 20H10V16C10 15.4696 10.2107 14.9609 10.5858 14.5858C10.9609 14.2107 11.4696 14 12 14C12.5304 14 13.0391 14.2107 13.4142 14.5858C13.7893 14.9609 14 15.4696 14 16V20ZM19 20H16V16C16 14.9391 15.5786 13.9217 14.8284 13.1716C14.0783 12.4214 13.0609 12 12 12C10.9391 12 9.92172 12.4214 9.17157 13.1716C8.42143 13.9217 8 14.9391 8 16V20H5V9.82C5.36757 9.68356 5.70664 9.48012 6 9.22C6.55002 9.71196 7.26207 9.98393 8 9.98393C8.73793 9.98393 9.44998 9.71196 10 9.22C10.55 9.71196 11.2621 9.98393 12 9.98393C12.7379 9.98393 13.45 9.71196 14 9.22C14.55 9.71196 15.2621 9.98393 16 9.98393C16.7379 9.98393 17.45 9.71196 18 9.22C18.2934 9.48012 18.6324 9.68356 19 9.82V20ZM21 7C21 7.26522 20.8946 7.51957 20.7071 7.70711C20.5196 7.89464 20.2652 8 20 8C19.7348 8 19.4804 7.89464 19.2929 7.70711C19.1054 7.51957 19 7.26522 19 7V4H21V7Z"
                    fill={color}
               />
          </svg>
     );
};

export default IconStore;
