import { memo } from "react";
import { ItemMobileFilter, MobileHomeFilterWrap } from "./MobileHomeFilter.styled";
import IconFood from "./svgComponents/IconFood";
import IconMart from "./svgComponents/IconMart";
import IconMeat from "./svgComponents/IconMeat";
import IconOil from "./svgComponents/IconOil";
import IconStore from "./svgComponents/IconStore";

const FILTERS = [
     {
          name: "마트",
          mainCode: "C2000",
          icon: active => <IconMart active={active} />,
          color: "#24C69A",
     },
     {
          name: "편의점",
          mainCode: "C1000",
          icon: active => <IconStore active={active} />,
          color: "#4B87F7",
     },
     {
          name: "주유소",
          mainCode: "C4000",
          icon: active => <IconOil active={active} />,
          color: "#EA346F",
     },
     {
          name: "반찬가게",
          mainCode: "C3000",
          icon: active => <IconFood active={active} />,
          color: "#7944E3",
     },
     {
          name: "정육점",
          mainCode: "C5000",
          icon: active => <IconMeat active={active} />,
          color: "#F6C044",
     },
];

const MobileHomeFilter = memo(({ codeFilter, onClickFilterButton }) => {
     return (
          <MobileHomeFilterWrap>
               {FILTERS.map((el, i) => {
                    const active = codeFilter?.[el.mainCode]?.length > 0;
                    const textColor = codeFilter?.[el.mainCode]?.length > 0 ? "#3d4043" : "rgba(61, 64, 67, 0.50)";

                    return (
                         <ItemMobileFilter
                              onClick={onClickFilterButton({ mainCode: el.mainCode })}
                              color={active ? el.color : "rgba(61, 64, 67, 0.50)"}
                              textColor={textColor}
                              key={`filter-${i}-${el.color}`}
                         >
                              {el.icon(active)}{" "}
                              {`${el.name}${el.mainCode !== "C3000" && el.mainCode !== "C5000" ? `(${codeFilter?.[el.mainCode]?.length ?? 0})` : ""}`}
                         </ItemMobileFilter>
                    );
               })}
          </MobileHomeFilterWrap>
     );
});

export default MobileHomeFilter;
