import { COMPETITION_CATEGORY, REVIEW_CATEGORY } from '../constants/store';

/* 개발팀 정보 조회 권한
		- 자사 편의점: 개발팀만
		- 타사 편의점: 영업팀 & 개발팀
	*/
export const checkAuthRFCTeamInfo = (
  sub_category_code,
  team_mng_yn,
  part_mng_yn,
  rfc_yn,
) => {
  if (sub_category_code === 'C1001' && rfc_yn === 'Y') {
    return true;
  } else if (
    COMPETITION_CATEGORY.includes(sub_category_code) &&
    (rfc_yn === 'Y' || team_mng_yn === 'Y' || part_mng_yn === 'Y')
  ) {
    return true;
  } else {
    return false;
  }
};

/*
		네이버 리뷰 조회 권한
	*/
export const checkAuthNaverReview = sub_category_code => {
  if (REVIEW_CATEGORY.includes(sub_category_code)) {
    return true;
  } else {
    return false;
  }
};

/* 영업팀 정보 조회 권한
		- 자사 편의점: 개발팀 제외하고 전체	 (sub_category_code === "C1001" && rfc_yn !== "Y")
		- 자사 수퍼: 전체					(sub_category_code === "C2001")
	*/
export const checkAuthOperTeamInfo = (sub_category_code, rfc_yn) => {
  if (sub_category_code === 'C1001' && rfc_yn !== 'Y') {
    return true;
  } else if (sub_category_code === 'C2001') {
    return true;
  } else {
    return false;
  }
};

/* OFC/점장 정보 조회 권한
		- 자사 편의점/수퍼 전체
	*/
export const checkManagerInfo = sub_category_code => {
  if (REVIEW_CATEGORY.includes(sub_category_code)) {
    return true;
  } else {
    return false;
  }
};

/* 예상매출액 정보 조회 권한
		- 편의점 영업팀장: 소속지역팀 타사 편의점
		- 개발팀: 소속지역팀 편의점 전체
		- 개발기획: 편의점 전체
	*/
export const checkExpAmountInfo = (
  sub_category_code,
  team_mng_yn,
  oper_region_cd,
  rfc_yn,
  rfc_region_cd,
  rfc_adm_yn,
  region_cd,
) => {
  if (
    COMPETITION_CATEGORY.includes(sub_category_code) &&
    team_mng_yn === 'Y' &&
    oper_region_cd === region_cd
  ) {
    return true;
  } else if (
    COMPETITION_CATEGORY.includes(sub_category_code) &&
    rfc_yn === 'Y' &&
    rfc_region_cd === region_cd
  ) {
    return true;
  } else if (
    COMPETITION_CATEGORY.includes(sub_category_code) &&
    rfc_adm_yn === 'Y'
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkStoreStatusInfo = (
  sub_category_code,
  team_mng_yn,
  oper_region_cd,
  rfc_yn,
  rfc_region_cd,
  rfc_adm_yn,
  region_cd,
) => {
  if (
    (sub_category_code === 'C1001' ||
      COMPETITION_CATEGORY.includes(sub_category_code)) &&
    team_mng_yn === 'Y' &&
    oper_region_cd === region_cd
  ) {
    return true;
  } else if (
    (sub_category_code === 'C1001' ||
      COMPETITION_CATEGORY.includes(sub_category_code)) &&
    rfc_yn === 'Y' &&
    rfc_region_cd === region_cd
  ) {
    return true;
  } else if (
    (sub_category_code === 'C1001' ||
      COMPETITION_CATEGORY.includes(sub_category_code)) &&
    rfc_adm_yn === 'Y'
  ) {
    return true;
  } else {
    return false;
  }
};

export const isStoreOverlap = (store, codeFilter) => {
  return (
    (store['category'] === '편의점' &&
      codeFilter?.['C1000'] &&
      codeFilter['C1000'].includes(store['sub_category_code'])) ||
    (store['category'] === '슈퍼,마트' &&
      codeFilter?.['C2000'] &&
      codeFilter['C2000'].includes(store['sub_category_code'])) ||
    (store['category'] === '반찬가게' && codeFilter?.['C3000']) ||
    (store['category'] === '주유소' &&
      codeFilter?.['C4000'] &&
      codeFilter['C4000'].includes(store['sub_category_code'])) ||
    (store['category'] === '정육점' && codeFilter?.['C5000'])
  );
};

const getMapFilter = mainCode => {
  const mapFilter = {
    C1000: ['C1001', 'C1002', 'C1003', 'C1004', 'C1999'],
    C2000: [
      'C2001',
      'C2003',
      'C2005',
      'C2006',
      'C2008',
      'C2011',
      'C2012',
      'C2999',
    ],
    C3000: ['C3001'],
    C4000: ['C4001', 'C4002', 'C4003', 'C4004', 'C4005', 'C4999'],
    C5000: ['C5001'],
  };

  return mapFilter[mainCode] || null;
};

export const updateMapFilter = (mvpStoreLocalStorageJson, mainCode) => {
  const newMapFilter = {
    ...mvpStoreLocalStorageJson.mapFilter,
    [mainCode]: getMapFilter(mainCode),
  };

  return newMapFilter;
};

export const isCategoryMatch = (category, subCategory, searchFilter) => {
  return (
    (category === '편의점' &&
      searchFilter?.['C1000'] &&
      searchFilter['C1000'].includes(subCategory)) ||
    (category === '슈퍼,마트' &&
      searchFilter?.['C2000'] &&
      searchFilter['C2000'].includes(subCategory)) ||
    (category === '반찬가게' && searchFilter?.['C3000']) ||
    (category === '정육점' && searchFilter?.['C5000']) ||
    (category === '주유소' &&
      searchFilter?.['C4000'] &&
      searchFilter['C4000'].includes(subCategory))
  );
};
