import styled from "@emotion/styled";

const TopMessageAlert = ({ message, onClose }) => {
     return (
          <>
               <ArrowBack onClick={onClose}>
                    <img src="img/icons/svg/arrow-back.svg" width={24} alt="arrow-back" />
               </ArrowBack>
               <AlertWrap>{message}</AlertWrap>
          </>
     );
};

export default TopMessageAlert;

const ArrowBack = styled.div`
     position: fixed;
     top: 20px;
     left: 20px;
     z-index: 3;
`;

const AlertWrap = styled.div`
     box-sizing: border-box;
     position: fixed;
     top: 55px;
     z-index: 3;
     height: 70px;
     color: #fff;
     text-align: center;

     font-size: 12px;
     font-style: normal;
     font-weight: 700;
     line-height: 16px; /* 133.333% */
     letter-spacing: 0.12px;
     width: 100%;

     background-color: rgba(0, 0, 0, 0.5);
     text-align: center;
     white-space: pre-line;
     display: flex;
     justify-content: center;
     align-items: center;
`;
