import {
     ItemData,
     StoreDetailWrap,
     DetailModeTop,
     ManagementInfoWrap,
     ManagementInfoTop,
     ManagementContentsWrap,
     StoreDetailInfoWrap,
} from "./MobileStoreDetail.styled";
import {
     checkAuthNaverReview,
     checkAuthOperTeamInfo,
     checkAuthRFCTeamInfo,
     checkExpAmountInfo,
     checkManagerInfo,
     checkStoreStatusInfo,
} from "../../utils/validator";
import { useSelector } from "react-redux";
import { CardContent, Typography } from "@mui/material";
import { formatToIntegerWithCommas } from "../../utils/textFormat";
import BrandDistanceList from "../brandDistanceList";
import moment from "moment";

import { AiOutlineExclamation } from "react-icons/ai";
import { InfoKeywordBarWrap, VotedkeywordsWrap } from "../../containers/PcStoreDetail/PcStoreDetail.styled";
import styled from "@emotion/styled";
import { useState } from "react";

const MobileDetail = ({ handleCloseDetailMode, activeStore, onChangeReview, setDirectionStart, setDirectionEnd }) => {
     const [tab, setTab] = useState("competitor");

     const userInfoData = useSelector(state => state.user);

     const { storeInfo, storeReviewList, fixedStoreFilterList, storekeywordStat, areaInfo, areaInfoDetail, reviewDate } = useSelector(state => state.store);

     const canShowAuthOperTeamInfo = checkAuthOperTeamInfo(activeStore?.["data"]?.sub_category_code, userInfoData?.rfc_yn);
     const canShowAuthRFCTeamInfo = checkAuthRFCTeamInfo(
          activeStore?.["data"]?.sub_category_code,
          userInfoData?.team_mng_yn,
          userInfoData?.part_mng_yn,
          userInfoData?.rfc_yn,
     );

     const canShowManagerInfo = checkManagerInfo(activeStore?.["data"]?.sub_category_code);
     const canShowAmountInfo = checkExpAmountInfo(
          activeStore?.["data"]?.sub_category_code,
          userInfoData?.team_mng_yn,
          activeStore?.["data"]?.oper_region_cd,
          userInfoData?.rfc_yn,
          activeStore?.["data"]?.rfc_region_cd,
          userInfoData?.rfc_adm_yn,
          userInfoData?.region_cd,
     );

     const MANAGEMENT_INFO = [
          { show: canShowAuthOperTeamInfo, title: "영업팀", contents: storeInfo?.["div_name"] },
          { show: canShowAuthRFCTeamInfo, title: "개발팀", contents: areaInfo == null ? "-" : areaInfo?.["rfc_region_nm"] },
          { show: canShowManagerInfo, title: "OFC/점장", contents: storeInfo?.["kornm"] },
          {
               show: canShowAmountInfo,
               title: "예상일매출",
               contents:
                    areaInfo == null
                         ? "-"
                         : areaInfo["signal"] === "-"
                           ? formatToIntegerWithCommas(areaInfo?.["exp_sales_amount"])
                           : areaInfo["signal"] === "UP"
                             ? formatToIntegerWithCommas(areaInfo?.["exp_sales_amount"]) +
                               " (▲" +
                               formatToIntegerWithCommas(areaInfo?.["exp_sales_amount_pre"]) +
                               ")"
                             : areaInfo["signal"] === "DOWN"
                               ? formatToIntegerWithCommas(areaInfo?.["exp_sales_amount"]) +
                                 " (▼" +
                                 formatToIntegerWithCommas(areaInfo?.["exp_sales_amount_pre"]) +
                                 ")"
                               : formatToIntegerWithCommas(areaInfo?.["exp_sales_amount"]),
          },
     ];

     return (
          <StoreDetailWrap>
               <DetailModeTop>
                    <img onClick={handleCloseDetailMode} src="img/icons/svg/arrow-back.svg" alt="close-detail-mode" />
               </DetailModeTop>

               <div className="active-store-info">
                    <div>
                         <div className="active-store-name">
                              <h2>{activeStore["data"]?.name}</h2>
                         </div>
                         <div className="active-store-details">
                              <p>
                                   {activeStore["data"]?.category}
                                   {activeStore["data"]?.phone && ` |${activeStore["data"]?.phone}`}
                              </p>
                         </div>
                         <p className="active-store-address">{activeStore["data"]?.full_address}</p>
                    </div>
                    <DirectionBtnWraps>
                         <ItemDirection
                              onClick={e => {
                                   e.stopPropagation();
                                   setDirectionStart({
                                        coordinate: { x: activeStore?.x, y: activeStore?.y },
                                        name: activeStore?.name,
                                        isPlace: false,
                                   });
                                   handleCloseDetailMode();
                              }}
                         >
                              출발
                         </ItemDirection>
                         <ItemDirection
                              onClick={e => {
                                   e.stopPropagation();
                                   setDirectionEnd({
                                        coordinate: { x: activeStore?.x, y: activeStore?.y },
                                        name: activeStore?.name,
                                   });
                                   handleCloseDetailMode();
                              }}
                         >
                              도착
                         </ItemDirection>
                    </DirectionBtnWraps>

                    {(canShowAuthOperTeamInfo || canShowAuthRFCTeamInfo || canShowManagerInfo || canShowAmountInfo) && (
                         <ManagementInfoWrap>
                              <ManagementInfoTop>상세 내용</ManagementInfoTop>
                              <ManagementContentsWrap>
                                   {MANAGEMENT_INFO.filter(el => el.show).map((el, idx, total) => {
                                        const arrowColor = areaInfo?.["signal"] === "DOWN" ? "#F00" : areaInfo?.["signal"] === "UP" ? "#216CF0" : "transparent";
                                        const changeIcon = areaInfo?.["signal"] === "DOWN" ? "▼" : areaInfo?.["signal"] === "UP" ? "▲" : "";
                                        const expectationAmount = formatToIntegerWithCommas(areaInfo?.["exp_sales_amount"] ?? 0);
                                        const expectationChangeAmount = formatToIntegerWithCommas(areaInfo?.["exp_sales_amount_pre"] ?? 0);

                                        return (
                                             <ItemData key={el.title} border={total.length > 1 && idx > 0}>
                                                  <img src={"img/icons/search-round.png"} alt="search-round-team" width={26} height={26} />
                                                  <div className="title">{el.title}</div>
                                                  {el.title === "예상일매출" ? (
                                                       <div className="expectations">
                                                            <div className="expectation-change-amount" style={{ color: arrowColor }}>
                                                                 {`${changeIcon ? `(${changeIcon}` : ""}${expectationChangeAmount}${changeIcon ? ")" : ""}`}
                                                            </div>
                                                            <div className="expectation-amount">{expectationAmount}</div>
                                                       </div>
                                                  ) : (
                                                       <div className="contents">{el.contents}</div>
                                                  )}
                                             </ItemData>
                                        );
                                   })}
                              </ManagementContentsWrap>
                         </ManagementInfoWrap>
                    )}
               </div>
               <ul className="info-detail-header">
                    <li>
                         <button className={tab === "competitor" ? "active" : ""} onClick={() => setTab("competitor")}>
                              주변 점포
                         </button>
                    </li>
                    {checkAuthNaverReview(activeStore?.["data"]?.sub_category_code) && (
                         <li>
                              <button className={tab === "review" ? "active" : ""} onClick={() => setTab("review")}>
                                   네이버 리뷰
                              </button>
                         </li>
                    )}
                    {checkStoreStatusInfo(
                         activeStore?.["data"]?.sub_category_code,
                         userInfoData?.team_mng_yn,
                         activeStore?.["data"]?.oper_region_cd,
                         userInfoData?.rfc_yn,
                         activeStore?.["data"]?.rfc_region_cd,
                         userInfoData?.rfc_adm_yn,
                         userInfoData?.region_cd,
                    ) && (
                         <li>
                              <button className={tab === "status" ? "active" : ""} onClick={() => setTab("status")}>
                                   점포 현황
                              </button>
                         </li>
                    )}
               </ul>
               <div className="info-detail">
                    <div className="info-detail-body">
                         {tab === "status" ? (
                              <>
                                   <Typography
                                        sx={{
                                             color: "#868782",
                                             fontSize: "10px",
                                             fontWeight: 400,
                                             lineHeight: "normal",
                                        }}
                                   >
                                        ※주거/인구/교통/학군/상권 정보는 부동산114 제공 정보입니다.
                                   </Typography>
                                   {areaInfoDetail?.length > 0 ? (
                                        areaInfoDetail.map((cat, index) => (
                                             <StoreDetailInfoWrap key={cat["category-name"]}>
                                                  <div className="title">{cat["category_name"]} </div>
                                                  <div className="line-wrap">
                                                       <div className="line" />
                                                  </div>

                                                  {cat["info_detail"].map((info, index) => (
                                                       <div className="info-item" key={info["code_name"]}>
                                                            <div className="info-code-name">{info["code_name"]}</div>

                                                            <div className="info-code-right">
                                                                 {areaInfo["signal"] !== "-" && (
                                                                      <div
                                                                           className={`info-dode-pre-value ${
                                                                                info["signal"] === "UP"
                                                                                     ? "up-color"
                                                                                     : info["signal"] === "DOWN"
                                                                                       ? "down-color"
                                                                                       : info["signal"] === "DIFF"
                                                                                         ? "diff-color"
                                                                                         : ""
                                                                           }`}
                                                                      >
                                                                           {info["signal"] === "UP"
                                                                                ? "(▲" + formatToIntegerWithCommas(info["code_value_pre"]) + ")"
                                                                                : info["signal"] === "DOWN"
                                                                                  ? "(▼" + formatToIntegerWithCommas(info["code_value_pre"]) + ")"
                                                                                  : info["signal"] === "DIFF"
                                                                                    ? info["code_value_pre"] + " → "
                                                                                    : ""}
                                                                      </div>
                                                                 )}
                                                                 <div
                                                                      className={`info-code-value ${
                                                                           info["code_value"] === "Y"
                                                                                ? "yes-color"
                                                                                : info["code_value"] === "N"
                                                                                  ? "no-color"
                                                                                  : ""
                                                                      }`}
                                                                 >
                                                                      {info["code_value"] == null
                                                                           ? "-"
                                                                           : info["code_type"] === "INT"
                                                                             ? formatToIntegerWithCommas(info["code_value"])
                                                                             : info["code_value"]}
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  ))}
                                             </StoreDetailInfoWrap>
                                        ))
                                   ) : (
                                        <CardContent
                                             sx={{
                                                  bgcolor: "#F0F0F0",
                                                  borderRadius: "10px",
                                                  marginBottom: "20px",
                                                  textAlign: "center",
                                             }}
                                        >
                                             <Typography
                                                  sx={{
                                                       fontSize: 15,
                                                       fontWeight: "bold",
                                                       marginTop: "10px",
                                                  }}
                                             >
                                                  점포현황 등록 내역이 없습니다
                                             </Typography>
                                        </CardContent>
                                   )}
                              </>
                         ) : tab === "competitor" ? (
                              <div>
                                   {fixedStoreFilterList &&
                                        Object.entries(fixedStoreFilterList).map(([key, value]) => (
                                             <BrandDistanceList
                                                  key={key}
                                                  brand={key}
                                                  value={value}
                                                  // handle={true}
                                             />
                                        ))}
                              </div>
                         ) : tab === "review" ? (
                              <>
                                   <div className="info-detail-keyword">
                                        <div className="info-detail-keyword-header">
                                             <div className="title">
                                                  <div> 키워드 리뷰</div>
                                                  <span className="accent">{storekeywordStat?.[0]?.["totCnt"] ? storekeywordStat[0]["totCnt"] : "0"}</span>
                                             </div>

                                             <div className="review-date-wrap">
                                                  <div className={reviewDate === "1 개월" ? "active" : ""} onClick={onChangeReview("1 개월")}>
                                                       1 개월
                                                  </div>
                                                  <div className={reviewDate === "3 개월" ? "active" : ""} onClick={onChangeReview("3 개월")}>
                                                       3 개월
                                                  </div>
                                                  <div className={reviewDate === "6 개월" ? "active" : ""} onClick={onChangeReview("6 개월")}>
                                                       6 개월
                                                  </div>
                                             </div>
                                        </div>
                                        {storekeywordStat?.length > 0 && (
                                             <ul className="info-detail-keyword-list">
                                                  {storekeywordStat.map((keyword, i) => (
                                                       <InfoKeywordBarWrap key={"keyword-list" + i} per={keyword.per}>
                                                            <p>{keyword["display_name"]}</p>
                                                            <span>{keyword["cnt"]}</span>
                                                       </InfoKeywordBarWrap>
                                                  ))}
                                             </ul>
                                        )}
                                   </div>
                                   <div className="info-detail-review">
                                        <ul>
                                             {storeReviewList?.length > 0 ? (
                                                  storeReviewList.map((review, index) => (
                                                       <li key={review["review_id"] + index}>
                                                            <div className="review-contents">
                                                                 <p className="reviewer">{review["author_nickname"]}</p>
                                                                 <p className="body">{review["body"]}</p>
                                                                 <VotedkeywordsWrap>
                                                                      {review["voted_keywords"]?.map((vote, i) => (
                                                                           <li key={review["review_id"] + index + i}>{vote}</li>
                                                                      ))}
                                                                 </VotedkeywordsWrap>
                                                                 <p className="rest">
                                                                      방문일 : {moment(review["author_visited"]).format("MMM Do")} | 작성일 :{" "}
                                                                      {moment(review["author_created"]).format("MMM Do")}
                                                                 </p>
                                                            </div>
                                                       </li>
                                                  ))
                                             ) : (
                                                  <li className="info-detail-review-none">
                                                       <div>
                                                            <div className="info-detail-review-none-icon">
                                                                 <AiOutlineExclamation />
                                                            </div>
                                                            <p>최근 {reviewDate} 수집된 리뷰 데이터가 없습니다.</p>
                                                       </div>
                                                  </li>
                                             )}
                                        </ul>
                                   </div>
                              </>
                         ) : null}
                    </div>
               </div>
          </StoreDetailWrap>
     );
};

export default MobileDetail;

const DirectionBtnWraps = styled.div`
     display: flex;
     width: 100%;
     justify-content: flex-end;
     gap: 6px;
`;
const ItemDirection = styled.div`
     cursor: pointer;
     width: 62px;
     height: 38px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 999px;
     border: 1px solid #dadce0;
     background: #216cf0;
     box-shadow: 0px 0px 40px 0px rgba(179, 198, 207, 0.5);
     color: #f1f4f9;
     text-align: center;
     -webkit-text-stroke-width: 1;
     -webkit-text-stroke-color: #f1f4f9;
     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: 12px; /* 100% */
`;
