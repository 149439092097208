import { memo, useRef, useMemo } from "react";

import { StoreDetailWrap, DetailModeTop } from "./InfoBar.styled";

import { useSelector } from "react-redux";
import styled from "@emotion/styled";
import IconStarFill from "../../assets/svgComponents/IconStarFill";
import IconStar from "../../assets/svgComponents/IconStar";

const TobaccoStoreDetail = memo(
     ({ handleClickFavoriteBtn, activeStore, close, sidebarOpen, setDirectionStart, handleChangeMoveStoreInfo, setDirectionEnd }) => {
          const { tobaccoStoreInfo, tobaccoBookmarkedList } = useSelector(state => state.store);

          const { businessName, contactNumber, businessStatusCode, detailedBusinessStatusName, fullLocationAddress, fullRoadAddress } = activeStore;

          const address = fullRoadAddress ?? fullLocationAddress;

          const infoEl = useRef(null);

          const detailStoreInfo = tobaccoStoreInfo?.data;

          const approvalDate = (detailStoreInfo?.approvalDate ?? "").replaceAll("-", ".");
          const closureDate = (detailStoreInfo?.closureDate ?? "").replaceAll("-", ".");
          const suspensionStartDate = (detailStoreInfo?.suspensionStartDate ?? "").replaceAll("-", ".");
          const suspensionEndDate = (detailStoreInfo?.suspensionEndDate ?? "").replaceAll("-", ".");
          const complaintType = detailStoreInfo?.complaintType;

          const isStoreSelected = useMemo(() => {
               if (!activeStore || !tobaccoBookmarkedList) return false;

               const storeInfo = tobaccoBookmarkedList.find(el => el.managementNumber === activeStore.managementNumber);

               if (storeInfo) {
                    return true;
               } else {
                    return false;
               }
          }, [tobaccoBookmarkedList, activeStore]);

          const addressChangeHistories = useMemo(() => {
               if (!detailStoreInfo?.storeAddressHistory || detailStoreInfo?.storeAddressHistory.length === 0) {
                    return { showHistory: false };
               }

               const originAddress = detailStoreInfo?.storeAddressHistory.find(el => el.sequence === 0);

               const changeHistories = detailStoreInfo?.storeAddressHistory.filter(el => el.sequence > 0).sort((a, b) => a - b);

               return {
                    showHistory: true,
                    changeHistories,
                    originAddress,
               };
          }, [detailStoreInfo]);

          return (
               <StoreDetailWrap ref={infoEl} sidebarOpen={sidebarOpen}>
                    <DetailModeTop>
                         <img onClick={close} src="img/icons/svg/arrow-back.svg" alt="close-detail-mode" />
                    </DetailModeTop>

                    <div className="active-tobacco-store-info">
                         <div>
                              <div className="active-store-name">
                                   <h2>{businessName ?? fullRoadAddress ?? fullLocationAddress ?? ""}</h2>
                              </div>
                              <div className="active-tobacco-store-details">{contactNumber && ""}</div>
                              <p className="active-tobacco-tore-address">{address}</p>
                         </div>
                         <BtnsWrap>
                              <LeftBtnsWrap>
                                   <FavoriteBtn
                                        onClick={e => {
                                             e.stopPropagation();

                                             handleClickFavoriteBtn(activeStore);
                                        }}
                                        active={isStoreSelected}
                                   >
                                        {isStoreSelected ? <IconStarFill fillColor="#2971EE" /> : <IconStar lineColor="#F1F4F9" />}
                                   </FavoriteBtn>
                                   {activeStore.managementNumber && (
                                        <FavoriteBtn
                                             onClick={e => {
                                                  e.stopPropagation();

                                                  handleChangeMoveStoreInfo();
                                             }}
                                             active={true}
                                        >
                                             <img src="img/icons/svg/u_edit.svg" alt="fix-position" width={20} />
                                        </FavoriteBtn>
                                   )}
                              </LeftBtnsWrap>

                              <DirectionBtnsWrap>
                                   <ItemDirection
                                        onClick={e => {
                                             e.stopPropagation();
                                             setDirectionStart({
                                                  coordinate: { x: activeStore?.xlongitude, y: activeStore?.ylatitude },
                                                  name: businessName ?? fullRoadAddress ?? fullLocationAddress ?? "",
                                                  isPlace: false,
                                             });
                                             close();
                                        }}
                                   >
                                        출발
                                   </ItemDirection>
                                   <ItemDirection
                                        onClick={e => {
                                             e.stopPropagation();
                                             setDirectionEnd({
                                                  coordinate: { x: activeStore?.xlongitude, y: activeStore?.ylatitude },
                                                  name: businessName ?? fullRoadAddress ?? fullLocationAddress ?? "",
                                                  isPlace: false,
                                             });
                                             close();
                                        }}
                                   >
                                        도착
                                   </ItemDirection>
                              </DirectionBtnsWrap>
                         </BtnsWrap>
                    </div>

                    <TobaccoManagementInfoWrap>
                         <TobaccoManagementInfoTop>상세 내용</TobaccoManagementInfoTop>
                         <TobaccoManagementContentsWrap>
                              <TobaccoItemData border={false}>
                                   <div style={{ paddingRight: 12 }}>
                                        <img src={"img/icons/store_round.png"} alt="store-round-icon" width={26} height={26} />
                                   </div>
                                   <div className="business-status">영업상태</div>
                                   <div className="business-current">{detailedBusinessStatusName}</div>
                              </TobaccoItemData>
                              {businessStatusCode === "01" && (
                                   <>
                                        <TobaccoItemData border>
                                             <div style={{ paddingRight: 12 }}>
                                                  <img src={"img/icons/check_round.png"} alt="store-round-icon" width={26} height={26} />
                                             </div>
                                             <div className="business-status">영업 허가일</div>
                                             <div className="business-current">{approvalDate}</div>
                                        </TobaccoItemData>
                                   </>
                              )}
                              {businessStatusCode === "02" && (
                                   <>
                                        <TobaccoItemData border>
                                             <div style={{ paddingRight: 12 }}>
                                                  <img src={"img/icons/suspended_round.png"} alt="store-round-icon" width={26} height={26} />
                                             </div>
                                             <div className="business-status">휴업 시작일</div>
                                             <div className="business-current">{suspensionStartDate}</div>
                                        </TobaccoItemData>

                                        <TobaccoItemData border>
                                             <div style={{ paddingRight: 12 }}>
                                                  <img src={"img/icons/suspended_round.png"} alt="store-round-icon" width={26} height={26} />
                                             </div>
                                             <div className="business-status">휴업 종료일</div>
                                             <div className="business-current">{suspensionEndDate}</div>
                                        </TobaccoItemData>
                                   </>
                              )}

                              {businessStatusCode === "03" && (
                                   <TobaccoItemData border>
                                        <div style={{ paddingRight: 12 }}>
                                             <img src={"img/icons/closed_round.png"} alt="store-round-icon" width={26} height={26} />
                                        </div>
                                        <div className="business-status">폐업일</div>
                                        <div className="business-current">{closureDate}</div>
                                   </TobaccoItemData>
                              )}
                              <TobaccoItemData border>
                                   <div style={{ paddingRight: 12 }}>
                                        <img src={"img/icons/check_round.png"} alt="store-round-icon" width={26} height={26} />
                                   </div>
                                   <div className="business-status">담배권 유형</div>
                                   <div className="business-current">{complaintType}</div>
                              </TobaccoItemData>
                         </TobaccoManagementContentsWrap>
                    </TobaccoManagementInfoWrap>
                    {addressChangeHistories.showHistory && (
                         <TobaccoManagementInfoWrap>
                              <TobaccoManagementInfoTop>주소 변경 내역</TobaccoManagementInfoTop>
                              <TobaccoManagementHistoryWrap>
                                   <TobaccoManagementContentsWrap>
                                        {[...addressChangeHistories.changeHistories].map((el, idx, total) => {
                                             const changedAddress = el.fullRoadAddress ?? el.fullLocationAddress;
                                             const changeAddressType = el.fullRoadAddress ? "[도로명]" : "[지번]";

                                             const beforeInfo = idx === total.length - 1 ? addressChangeHistories.originAddress : total[idx + 1];

                                             const beforeChangedAddress = beforeInfo.fullRoadAddress ?? beforeInfo.fullLocationAddress;
                                             const beforeChangeAddressType = beforeInfo.fullRoadAddress ? "[도로명]" : "[지번]";

                                             return (
                                                  <TobaccoItemData key={`${el.sequence}-${el.fullRoadAddress}`} border={total.length > 1 && idx > 0}>
                                                       <div className="history-change-wrap">
                                                            <div className="address">
                                                                 (변경) {changeAddressType} {changedAddress}
                                                            </div>
                                                            <div className="address before">
                                                                 (이전) {beforeChangeAddressType} {beforeChangedAddress}
                                                            </div>
                                                            <div className="update-date">업데이트 : {el.updateDate.replaceAll("-", ".")}</div>
                                                            <div className="update-date user">수행자 : {el.updateUserAccount}</div>
                                                       </div>
                                                  </TobaccoItemData>
                                             );
                                        })}
                                   </TobaccoManagementContentsWrap>
                              </TobaccoManagementHistoryWrap>
                         </TobaccoManagementInfoWrap>
                    )}
               </StoreDetailWrap>
          );
     },
);

export default TobaccoStoreDetail;

export const TobaccoItemData = styled.div`
     display: flex;
     align-items: center;
     padding: 12px 16px 12px 0px;
     border-top: ${({ border }) => (border ? "0.4px solid #BDBDBD" : "none")};

     .business-status {
          overflow: hidden;
          color: #1e2530;
          text-overflow: ellipsis;

          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          width: 72px;
     }
     .business-current {
          color: #868782;

          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
     }

     .history-change-wrap {
          width: 100%;
          display: flex;
          flex-direction: column;

          .update-date {
               padding-top: 10px;
               width: 100%;
               text-align: left;
               color: #216cf0;

               font-size: 11px;
               font-style: normal;
               font-weight: 400;
               line-height: 0%; /* 0px */
               padding-bottom: 5px;
          }

          .user {
               color: #868782;
          }

          .address {
               overflow: hidden;
               color: #1e2530;
               text-overflow: ellipsis;

               font-size: 13px;
               font-style: normal;
               font-weight: 500;
               line-height: normal;
          }

          .before {
               padding-top: 5px;
               color: #868782;
          }
     }
`;

const TobaccoManagementInfoTop = styled.div`
     color: #3d4043;
     text-align: center;

     font-size: 16px;
     font-style: normal;
     font-weight: 600;
     line-height: normal;
     width: 100%;
     text-align: left;
     padding-bottom: 10px;
`;
const TobaccoManagementInfoWrap = styled.div`
     padding: 44px 18px 26px;
`;

const TobaccoManagementContentsWrap = styled.div`
     box-sizing: border-box;
     background-color: #ffffff;
     width: 100%;
     border-radius: 10px;
     padding-left: 16px;
`;

const TobaccoManagementHistoryWrap = styled.div`
     height: 100%;
     max-height: 380px;

     overflow: auto;

     &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          background: #ffffff;
     }
     &::-webkit-scrollbar-thumb {
          border-radius: 3.5px;
          background-color: #ced4da;

          &:hover {
               background-color: #adb5bd;
          }
     }
     &::-webkit-scrollbar-track {
          background: #ffffff;
     }
`;

const FavoriteBtn = styled.div`
     width: 36px;
     height: 36px;
     border-radius: 50%;
     background-color: ${({ active }) => (active ? "#D6E2FB" : "#B7B7B5")};
     display: flex;
     justify-content: center;
     align-items: center;
`;

const LeftBtnsWrap = styled.div`
     display: flex;
     gap: 10px;

     div {
          cursor: pointer;
     }
`;

const BtnsWrap = styled.div`
     display: flex;
     width: 100%;
     justify-content: space-between;
     padding-top: 16px;
`;

const DirectionBtnsWrap = styled.div`
     display: flex;
     gap: 6px;
`;
const ItemDirection = styled.div`
     cursor: pointer;
     width: 62px;
     height: 38px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 999px;
     border: 1px solid #dadce0;
     background: #216cf0;
     box-shadow: 0px 0px 40px 0px rgba(179, 198, 207, 0.5);
     color: #f1f4f9;
     text-align: center;
     -webkit-text-stroke-width: 1;
     -webkit-text-stroke-color: #f1f4f9;

     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: 12px; /* 100% */
`;
