import styled from '@emotion/styled';

export const TobaccoFilterWrap = styled.div`
  padding: 20px 30px;
`;

export const TitleText = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 15px;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e6e6e6;
  margin: 30px 0px;
`;
