import { TOBACCO_MAP_CONTROLLER } from '../constants/map';

export const updateTobacoLocalStorageData = newProperties => {
  const storedData = localStorage.getItem(TOBACCO_MAP_CONTROLLER);

  if (storedData) {
    const parsedData = JSON.parse(storedData);
    const updatedData = {
      ...parsedData,
      ...newProperties,
    };

    localStorage.setItem(TOBACCO_MAP_CONTROLLER, JSON.stringify(updatedData));
  }
};
