import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { postStoreInfo, storeDetailReset, postFixedDistance, postStoreSearch, searchReset, areaInfoReset } from "../../../reducers/store";
import { use100vh } from "react-div-100vh";
import { userInfo } from "../../../reducers/user";
import { getEndMarkerIcon, getStartMarkerIcon, handleEndMarkerDelete, handleStartMarkerDelete, markerImage } from "../../../utils/markerUtils";
import { CONTROLLER_VERSION, DISTANCE_500, INITIAL_LAT, INITIAL_LNG, INITIAL_STORE_MAP_CODE_FILTER, INITIAL_STORE_MAP_FILTER } from "../../../constants/map";
import { HomeWrap } from "./MobileHomeWrap.styled";

import MobileDetail from "../../../components/MobileDetail/MobileDetail";
import MobileActiveStore from "../../../components/mobileActiveStore/MobileActiveStore";
import MobileSearch from "../../../components/mobileSearch/MobileSearch";
import { updateMapFilter } from "../../../utils/validator";
import { Circle } from "../../../constants/naver";
import { MobileFilterOpenBtn } from "../../tobacco/MobileHome/TobaccoMobileHomeWrap.styled";

import MobileBottomMenu from "../../../components/mobileBottomMenu/MobileBottomMenu";
import { createStoreMarkerEventHandlers, storeMarkerOverlap } from "../../../utils/mapMarker";
import { createLongPressHandler } from "../../../utils/tobaccoEventHandler";
import MobileDirection from "../../../components/mobileDirection/MobileDirection";
import MobileNav from "../../../components/MobileHeader/MobileNav";
import MobileUserInfoModal from "../../../components/modal/MobileUserInfo/MobileUserInfoModal";
import MobileHomeFilter from "../../../components/MobileFilterV2/MobileHomeFilter";
import MobileFilterTop from "../../../components/mobileFilterTop/MobileFilterTop";
import MobileStoreRightButtons from "../../../components/mobileStoreRightButtons/MobileStoreRightButtons";
import MobileStoreDistanceModal from "../../../components/modal/MobileStoreDistanceModal/MobileStoreDistanceModal";
import TopMessageAlert from "../../../components/TopMessage/TopMessageAlert";
import { postCheckAddressByCoord } from "../../../api/store";
import NearestStoreV2 from "../../../components/NearestStore/NearestStoreV2";
import MobileActiveStoreSelectedPosition from "../../../components/mobileActiveStore/MobileActiveStoreSelectedPosition";

const { naver } = window;

const MobileHome = ({ setSnackbarMessage }) => {
     const { accounts } = useMsal();
     const height = use100vh();

     const map = useRef();
     const listEl = useRef(null);

     const markers = useRef([]);
     const directionWithPoint = useRef(null);
     const selectedPositionRef = useRef(null);

     const dispatch = useDispatch();

     const userInfoData = useSelector(state => state.user);
     const { storeList } = useSelector(state => state.store);

     // 스크롤 옵셋
     const [searchOffset, setSearchOffset] = useState(0);
     // mode
     const [searchMode, setSearchMode] = useState(false);
     const [detailMode, setDetailMode] = useState(false);

     const openDetailMode = () => setDetailMode(true);
     const closeDetailMode = () => setDetailMode(false);
     const [showFilterPage, setShowFilterPage] = useState(false);
     const [selectedPosition, setSelectedPosition] = useState(null);

     const [selectedPositionInfo, setSelectedPositionInfo] = useState({
          open: false,
          position: null,
          data: null,
          updated: false,
     });

     const [directionDrawerOpen, setDirectionDrawerOpen] = useState(false);
     const [directionDrawerSearchMode, setDirectionDrawerSearchMode] = useState({ show: false, type: null });

     const [showDirectionPointMessage, setShowDirectionPointMessage] = useState(false);

     const handleClickSetDirectionWithPoint = async (type, coord) => {
          try {
               const data = await getAddressByCoords(coord.x, coord.y);
               const addressData = data.data;
               const address = addressData.fullRoadAddress ?? addressData.fullLocationAddress ?? "";

               if (type === "START") {
                    setDirectionStart({ coordinate: { x: coord.x, y: coord.y }, name: address, isPlace: true });
               }

               if (type === "END") {
                    setDirectionEnd({ coordinate: { x: coord.x, y: coord.y }, name: address, isPlace: true });
               }
               handleClickCloseDirectionWithPoint();
          } catch {
               handleClickCloseDirectionWithPoint();
          }
     };

     const getAddressByCoords = async (lng, lat) => {
          try {
               const newAddrData = await postCheckAddressByCoord({
                    lng,
                    lat,
                    account: accounts[0]["username"],
               });

               return newAddrData.data;
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
               return null;
          }
     };

     const startMarkerRef = useRef(null);

     const endMarkerRef = useRef(null);
     const markerInfoWindowRef = useRef(null);

     const [directionStart, setDirectionStart] = useState({ coordinate: null, name: null, isPlace: false });
     const [directionEnd, setDirectionEnd] = useState({ coordinate: null, name: null, isPlace: false });

     // info
     const [infoDetailActive, setInfoDetailActive] = useState("competitor");

     const [activeStore, setActiveStore] = useState({
          open: false,
          data: null,
     });
     const [codeFilter, setCodeFilter] = useState(null);
     const [distance, setDistance] = useState(DISTANCE_500);

     // 검색 값
     const [searchValue, setSearchValue] = useState("");

     const handleClickCloseDirectionWithPoint = () => {
          setShowDirectionPointMessage(false);
          directionWithPoint.current = null;
          setDirectionDrawerSearchMode({ show: false, type: null });
     };

     const onClickCloseDirectionWithPoint = () => {
          setShowDirectionPointMessage(false);
          directionWithPoint.current = null;
     };

     const handleClickDirectionWithPoint = type => {
          setShowDirectionPointMessage(true);
          directionWithPoint.current = type;
     };

     useEffect(() => {
          handleStartMarkerDelete(startMarkerRef, markerInfoWindowRef);

          if (directionStart.coordinate?.x && directionStart.coordinate?.y && !startMarkerRef.current) {
               const startMarker = new naver.maps.Marker({
                    position: directionStart.coordinate,
                    map: map.current,
                    icon: getStartMarkerIcon(),
               });
               startMarkerRef.current = startMarker;
          }
     }, [directionStart]);

     useEffect(() => {
          handleEndMarkerDelete(endMarkerRef, markerInfoWindowRef);

          if (directionEnd.coordinate?.x && directionEnd.coordinate?.y && !endMarkerRef.current) {
               handleEndMarkerDelete(endMarkerRef, markerInfoWindowRef);
               const endMarker = new naver.maps.Marker({
                    position: directionEnd.coordinate,
                    map: map.current,
                    icon: getEndMarkerIcon(),
               });
               endMarkerRef.current = endMarker;
          }
     }, [directionEnd]);

     const updateStoreList = useCallback(
          (lat, lng, distance, codeFilter) => {
               dispatch(
                    postFixedDistance({
                         lat,
                         lng,
                         distance,
                         account: accounts[0]["username"],
                         codeFilter,
                    }),
               );
          },
          [accounts],
     );

     /** 지도 반경 원 설정 */
     const setCircle = useCallback(
          (y, x) => {
               Circle.setCenter(naver.maps.LatLng(y, x));
               Circle.setRadius(distance);
               Circle.setMap(map.current);
          },
          [distance],
     );

     const handleCloseSelectedPositionInfo = () => {
          setSelectedPositionInfo({ open: false, data: null, position: null, updated: false });
          if (selectedPositionRef.current) {
               selectedPositionRef.current?.close();
               selectedPositionRef.current = null;
          }
     };

     /** 매장 활성화  */
     const onClickStore = useCallback(
          store => {
               dispatch(
                    postStoreInfo({
                         account: accounts[0]["username"],
                         id: store.id,
                    }),
               );
               selectedPositionRef?.current?.close();
               setSelectedPosition(null);

               updateStoreList(store.y, store.x, distance, codeFilter);
               setActiveStore({ open: true, data: store });
               setCircle(store.y, store.x);
               map.current.setCenter(naver.maps.LatLng(store.y, store.x));
          },
          [accounts, codeFilter, dispatch, distance, setCircle],
     );

     useEffect(() => {
          const mvpStoreLocalStorage = localStorage.getItem(CONTROLLER_VERSION);

          if (!mvpStoreLocalStorage) {
               localStorage.setItem(CONTROLLER_VERSION, INITIAL_STORE_MAP_FILTER);

               setCodeFilter(INITIAL_STORE_MAP_CODE_FILTER);
               setDistance(DISTANCE_500);
          } else {
               const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);

               setCodeFilter(mvpStoreLocalStorageJson.mapFilter);
               setDistance(mvpStoreLocalStorageJson.distance);
          }
     }, []);

     /** 사용자 위치 정보 설정 */
     useEffect(() => {
          const mvpStoreLocalStorage = localStorage.getItem(CONTROLLER_VERSION);

          const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);
          const { distance, mapFilter } = mvpStoreLocalStorageJson;

          map.current = new naver.maps.Map("map", {
               center: new naver.maps.LatLng(INITIAL_LAT, INITIAL_LNG),
               zoom: 18,
          });

          if (navigator?.geolocation) {
               navigator.geolocation.getCurrentPosition(
                    position => {
                         const golocationPosition = new naver.maps.LatLng(position.coords.latitude, position.coords.longitude);

                         map.current.setCenter(golocationPosition);
                         const { latitude, longitude } = position.coords;

                         updateStoreList(latitude, longitude, distance, mapFilter);
                    },
                    error => {
                         console.log("위치 정보 미동의");
                         updateStoreList(INITIAL_LAT, INITIAL_LNG, distance, mapFilter);
                    },
                    {
                         enableHighAccuracy: false,
                         maximumAge: 0,
                         timeout: Infinity,
                    },
               );
          } else {
               console.error("GPS를 지원하지 않습니다");
               updateStoreList(INITIAL_LAT, INITIAL_LNG, distance, mapFilter);
          }
     }, [accounts, dispatch]);

     useEffect(() => {
          if (storeList) {
               if (markers.current.length > 0) {
                    markers.current.forEach(marker => {
                         marker["marker"].setMap(null);
                    });
                    markers.current = [];
               }

               const overlap = storeMarkerOverlap(storeList, map, codeFilter);

               overlap.forEach(store => {
                    const content = markerImage(store, activeStore, userInfoData);

                    const marker = new naver.maps.Marker({
                         map: map.current,
                         position: store.coord,
                         icon: {
                              content: content,
                              size: new naver.maps.Size(30, 30),
                              anchor: new naver.maps.Point(20, 45),
                         },
                    });

                    markers.current = [...markers.current, { ...store, marker }];

                    if (marker) {
                         const { mouseOverHandler, mouseOutHandler, clickHandler } = createStoreMarkerEventHandlers(
                              marker,
                              store,
                              onClickStore,
                              deleteCircle,
                              map,
                              activeStore,
                         );
                    }
               });
          }
     }, [storeList, distance, codeFilter, accounts, activeStore, onClickStore, userInfoData]);

     /** 화면 크기 변화 감지 */
     useEffect(() => {
          const sizeEvent = naver.maps.Event.addListener(map.current, "size_changed", e => {
               map.current.autoResize();
          });
          return () => {
               naver.maps.Event.removeListener(sizeEvent);
          };
     }, []);

     /** 매장 상세 닫기 */
     const onClickStoreDetailClose = useCallback(() => {
          setActiveStore({ open: false, data: null });
          deleteCircle();
          dispatch(storeDetailReset());
          dispatch(areaInfoReset());

          // if (activeStore?.data) {
          //      const marker = document.getElementById(`${activeStore.data.name}`);
          //      marker && marker.parentElement.classList.remove("active");
          // }
     }, [activeStore.data, dispatch]);

     /** 지도 줌 이벤트 */
     const onChangeZoom = useCallback(
          e => {
               setNearStoreFromActive(null);
               if (e <= 10) {
                    if (markers.current.length > 0) {
                         markers.current.forEach(marker => {
                              marker["marker"].setMap(null);
                         });
                         markers.current = [];
                    }
                    onClickStoreDetailClose();
               } else if (!Circle.getMap()) {
                    const position = map.current.getCenter();
                    updateStoreList(position.y, position.x, distance, codeFilter);
               }
          },
          [accounts, distance, dispatch, onClickStoreDetailClose],
     );

     /** 지도 드래그 이벤트 */
     const onChangeDragend = useCallback(
          e => {
               if (map.current.getZoom() > 10 && !Circle.getMap()) {
                    const position = map.current.getCenter();
                    updateStoreList(position.y, position.x, distance, codeFilter);
               }
          },
          [accounts, distance, dispatch],
     );

     /** 지도 줌 & 드래그 변화 감지 */
     useEffect(() => {
          const dragStartEvent = naver.maps.Event.addListener(map.current, "dragstart", onDragStart);
          const centerEvent = naver.maps.Event.addListener(map.current, "dragend", onChangeDragend);
          const zoomEvent = naver.maps.Event.addListener(map.current, "zoom_changed", onChangeZoom);
          const sizeEvent = naver.maps.Event.addListener(map.current, "size_changed", onChangeSize);

          const { onTouchStart, onTouchEnd } = createLongPressHandler({
               map,
               selectedPositionRef,
               longPressDuration: 2000, // 필요 시 조정
               setSelectedPosition,
               directionWithPoint,
               handleClickSetDirectionWithPoint,
          });

          const touchStartEvent = naver.maps.Event.addListener(map.current, "touchstart", onTouchStart);
          const touchEndEvent = naver.maps.Event.addListener(map.current, "touchend", onTouchEnd);

          return () => {
               naver.maps.Event.removeListener(centerEvent);
               naver.maps.Event.removeListener(zoomEvent);
               naver.maps.Event.removeListener(sizeEvent);
               naver.maps.Event.removeListener(dragStartEvent);

               naver.maps.Event.removeListener(touchStartEvent);
               naver.maps.Event.removeListener(touchEndEvent);
          };
     }, [accounts, distance, onChangeDragend, onChangeZoom]);

     const onChangeSize = e => {
          setNearStoreFromActive(null);
          map.current.autoResize();
     };

     const onDragStart = e => {
          setNearStoreFromActive(null);
     };

     /** 지도 반경 원 제거 */
     const deleteCircle = (y, x) => {
          Circle.setMap(null);
     };

     const [nearStoreFromActive, setNearStoreFromActive] = useState(null);

     /** 매장 활성화 ClassName 지정 */
     useEffect(() => {
          if (activeStore.data) {
               setTimeout(() => {
                    const marker = document.getElementById(`${activeStore.data.name}`);
                    // marker && marker.parentElement.classList.add("active");

                    const rect = marker?.getBoundingClientRect();
                    const currentStoreInfoFromMarker = markers.current.find(el => el.id === activeStore.data.id);

                    if (currentStoreInfoFromMarker && currentStoreInfoFromMarker.child.length > 0) {
                         setNearStoreFromActive({
                              stores: currentStoreInfoFromMarker.child,
                              position: {
                                   bottom: rect.bottom,
                                   left: rect.left,
                              },
                         });
                    } else {
                         setNearStoreFromActive(null);
                    }
               }, 800);
          }
     }, [activeStore]);

     useEffect(() => {
          map.current.autoResize();
     }, [searchMode, detailMode]);

     const handleClickFilterReset = e => {
          e.stopPropagation();

          const mvpStoreLocalStorage = localStorage.getItem(CONTROLLER_VERSION);
          const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);

          const newMvpStoreController = {
               ...mvpStoreLocalStorageJson,
               mapFilter: INITIAL_STORE_MAP_CODE_FILTER,
          };
          localStorage.setItem(CONTROLLER_VERSION, JSON.stringify(newMvpStoreController));

          setCodeFilter(INITIAL_STORE_MAP_CODE_FILTER);
     };

     const onClickFilterButton =
          ({ mainCode, subCode }) =>
          e => {
               e.stopPropagation();

               const mvpStoreLocalStorage = localStorage.getItem(CONTROLLER_VERSION);
               const mvpStoreLocalStorageJson = JSON.parse(mvpStoreLocalStorage);

               let newMapFilter = {};

               if (mvpStoreLocalStorageJson.mapFilter?.[mainCode]) {
                    if (subCode) {
                         newMapFilter = {
                              ...mvpStoreLocalStorageJson.mapFilter,
                              [mainCode]: mvpStoreLocalStorageJson.mapFilter[mainCode].includes(subCode)
                                   ? mvpStoreLocalStorageJson.mapFilter[mainCode]?.length > 1
                                        ? mvpStoreLocalStorageJson.mapFilter[mainCode].filter(s => s !== subCode)
                                        : null
                                   : [...mvpStoreLocalStorageJson.mapFilter[mainCode], subCode],
                         };
                    } else {
                         newMapFilter = { ...mvpStoreLocalStorageJson.mapFilter };
                         delete newMapFilter[mainCode];
                    }
               } else {
                    if (!subCode) {
                         newMapFilter = updateMapFilter(mvpStoreLocalStorageJson, mainCode);
                    } else {
                         newMapFilter = {
                              ...mvpStoreLocalStorageJson.mapFilter,
                              [mainCode]: [subCode],
                         };
                    }
               }

               const newMvpStoreController = {
                    ...mvpStoreLocalStorageJson,
                    mapFilter: newMapFilter,
               };

               localStorage.setItem(CONTROLLER_VERSION, JSON.stringify(newMvpStoreController));

               setCodeFilter(newMapFilter);
          };

     /** 지도 반경 변경 기능 */
     const onClickDistanceButton = distance => () => {
          const newCodeFilter = {
               mapFilter: codeFilter,
               distance,
          };

          if (!activeStore?.open) {
               const position = map.current.getCenter();
               updateStoreList(position.y, position.x, distance, codeFilter);
          } else {
               const position = Circle.getCenter();
               Circle.setRadius(distance);
               updateStoreList(position.y, position.x, distance, codeFilter);
          }

          localStorage.setItem(CONTROLLER_VERSION, JSON.stringify(newCodeFilter));
          setDistance(distance);
     };

     const onSearch = type => () => {
          const category = [];

          Object.entries(codeFilter).map(([key, value]) => {
               if (value?.length > 0) {
                    category.push({
                         code: key,
                         sub_code: value,
                    });
               }
               return null;
          });

          if (type === "scroll") {
               setSearchOffset(prev => prev + 30);
          } else {
               setSearchOffset(0);
               listEl.current.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
               });
          }

          dispatch(
               postStoreSearch({
                    storeName: searchValue,
                    account: accounts[0]["username"],
                    openSnackbar: setSnackbarMessage,
                    category,
                    offset: type === "scroll" ? searchOffset + 30 : 0,
               }),
          );
     };

     /** 검색어 이벤트 */
     const onChangeSearchValue = e => {
          if (e.target.value.trim() === "") {
               dispatch(searchReset());
          }
          setSearchOffset(0);
          setSearchValue(e.target.value);
     };

     /** 리스트 클릭 이벤트 */
     const onClickStoreName = item => {
          map.current.setCenter(new naver.maps.LatLng(item.y, item.x));
          onClickStore(item);
          resetMode();
     };

     const resetMode = () => {
          setSearchMode(false);
          setDetailMode(false);
     };

     const onChangeReview = reviewDate => () => {
          dispatch(
               postStoreInfo({
                    account: accounts[0]["username"],
                    id: activeStore?.data?.id,
                    date: reviewDate,
               }),
          );
     };

     useEffect(() => {
          if (userInfoData?.kor_nm == null || userInfoData === undefined) {
               dispatch(
                    userInfo({
                         account: accounts[0]["username"],
                         kor_nm: accounts[0]["name"],
                    }),
               );
          }
     }, [accounts, dispatch, userInfoData]);

     const handleCloseActiveStore = () => {
          // if (activeStore?.data) {
          //      const marker = document.getElementById(`${activeStore.data.name}`);
          //      marker && marker.parentElement.classList.remove("active");
          // }
          setActiveStore({
               open: false,
               data: null,
          });
          setNearStoreFromActive(null);
          deleteCircle();
          dispatch(storeDetailReset());
     };

     const handleChangeFilterPage = () => setShowFilterPage(prev => !prev);

     const [modalUserInfoOpen, setModalUserInfoOpen] = useState(false);
     const [modalDistanceOpen, setModalDistanceOpen] = useState(false);

     useEffect(() => {
          if (selectedPosition) {
               getAddressByCoords(selectedPosition.x, selectedPosition.y).then(data => {
                    map.current.setCenter(naver.maps.LatLng(selectedPosition.y, selectedPosition.x));
                    if (activeStore.open) {
                         handleCloseActiveStore();
                         return;
                    } else {
                         updateStoreList(selectedPosition.y, selectedPosition.x, distance, codeFilter);
                    }

                    setSelectedPositionInfo({
                         open: true,
                         position: selectedPosition,
                         data,
                         updated: false,
                    });
               });
          }
     }, [selectedPosition, activeStore, codeFilter]);

     return (
          <>
               {!showDirectionPointMessage && !showFilterPage && !detailMode && !searchMode && (
                    <MobileFilterOpenBtn onClick={handleChangeFilterPage}>
                         <img src={"img/icons/filter-icon.png"} alt="mobile-filter" width={16} height={16} />
                    </MobileFilterOpenBtn>
               )}
               {!showDirectionPointMessage && !showFilterPage && !detailMode && !searchMode && (
                    <MobileHomeFilter codeFilter={codeFilter} onClickFilterButton={onClickFilterButton} />
               )}

               {showDirectionPointMessage && <TopMessageAlert message={"지도에서 위치를 선택해 주세요"} onClose={onClickCloseDirectionWithPoint} />}
               {!showDirectionPointMessage && (
                    <MobileFilterTop
                         codeFilter={codeFilter}
                         handleClickFilterReset={handleClickFilterReset}
                         onClickFilterButton={onClickFilterButton}
                         open={showFilterPage}
                         onClose={handleChangeFilterPage}
                    />
               )}

               {!showDirectionPointMessage && !showFilterPage && !detailMode && !searchMode && (
                    <MobileStoreRightButtons
                         handleOpenDistance={() => setModalDistanceOpen(true)}
                         handleChangeDirectionStatus={() => setDirectionDrawerOpen(prev => !prev)}
                         map={map}
                         updateStoreList={updateStoreList}
                         distance={distance}
                         mapFilter={codeFilter}
                    />
               )}

               {directionDrawerOpen && !detailMode && !searchMode && (
                    <MobileDirection
                         map={map}
                         onClickCloseDirectionWithPoint={onClickCloseDirectionWithPoint}
                         directionDrawerClose={() => setDirectionDrawerOpen(false)}
                         account={accounts[0]["username"]}
                         searchCodeFilter={codeFilter}
                         directionStart={directionStart}
                         directionEnd={directionEnd}
                         setDirectionStart={setDirectionStart}
                         setDirectionEnd={setDirectionEnd}
                         handleClickDirectionWithPoint={handleClickDirectionWithPoint}
                         directionDrawerSearchMode={directionDrawerSearchMode}
                         setDirectionDrawerSearchMode={setDirectionDrawerSearchMode}
                         showDirectionPointMessage={showDirectionPointMessage}
                    />
               )}

               <MobileUserInfoModal open={modalUserInfoOpen} onClose={() => setModalUserInfoOpen(false)} />
               <MobileStoreDistanceModal
                    distance={distance}
                    onChangeDistance={onClickDistanceButton}
                    open={modalDistanceOpen}
                    onClose={() => setModalDistanceOpen(false)}
               />

               <HomeWrap isTabletOrMobile height={height}>
                    {!showDirectionPointMessage && <MobileNav setSearchMode={setSearchMode} setModalUserInfoOpen={setModalUserInfoOpen} />}
                    <div id="map" style={{ width: "100%", height: `calc(${height} - 60px)}` }} />
                    {activeStore?.open && (
                         <MobileActiveStore
                              directionDrawerOpen={directionDrawerOpen}
                              onClickDetailModeOn={() => setDetailMode(true)}
                              handleCloseActiveStore={handleCloseActiveStore}
                              activeStore={activeStore.data}
                              setDirectionStart={setDirectionStart}
                              setDirectionEnd={setDirectionEnd}
                         />
                    )}

                    {selectedPositionInfo.open && selectedPosition && (
                         <MobileActiveStoreSelectedPosition
                              directionDrawerOpen={directionDrawerOpen}
                              selectedPosition={selectedPosition}
                              selectedPositionInfo={selectedPositionInfo}
                              handleCloseSelectedPositionInfo={handleCloseSelectedPositionInfo}
                              setDirectionStart={setDirectionStart}
                              setDirectionEnd={setDirectionEnd}
                         />
                    )}

                    {searchMode && (
                         <MobileSearch
                              listEl={listEl}
                              onClickClose={() => setSearchMode(prev => !prev)}
                              onClickStoreName={onClickStoreName}
                              codeFilter={codeFilter}
                              onSearch={onSearch}
                              onChangeSearchValue={onChangeSearchValue}
                              searchValue={searchValue}
                              showDirectionPointMessage
                         />
                    )}

                    {detailMode && (
                         <MobileDetail
                              handleCloseDetailMode={closeDetailMode}
                              activeStore={activeStore}
                              infoDetailActive={infoDetailActive}
                              handleSetDetailModeOn={openDetailMode}
                              onChangeReview={onChangeReview}
                              setDirectionStart={setDirectionStart}
                              setDirectionEnd={setDirectionEnd}
                         />
                    )}
                    {nearStoreFromActive && <NearestStoreV2 nearStoreFromActive={nearStoreFromActive} onClickStore={onClickStore} />}
               </HomeWrap>
               {!directionDrawerOpen && !detailMode && !searchMode && <MobileBottomMenu />}
          </>
     );
};

export default MobileHome;
