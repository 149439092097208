import Api from './my';

// get posts
export const getPostsApi = (shopId: string) => {
	return Api.get(`/posts/${shopId}`);
};

// post Post
export const postPostApi = (postData: any) => {
	return Api.post(`/post`, postData);
};

// post Post
export const postImagesApi = (FormData: any) => {
	return Api.post(`/images`, FormData);
};
