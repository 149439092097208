import styled from "@emotion/styled";

interface IItemMobileBottomMenu {
     active: boolean;
}

export const MobileBottomMenuWrap = styled.div`
     position: fixed;
     z-index: 2;
     bottom: 0px;
     width: 100%;
     height: 77px;
     background: #f1f4f9;
     box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
     display: flex;
     justify-contents: space-between;
     align-items: center;

     a {
          width: 100%;
          height: 100%;
     }
`;

export const ItemMobileBottomMenu = styled.div<IItemMobileBottomMenu>`
     cursor: pointer;
     width: 100%;
     height: 100%;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     gap: 5px;
     color: ${({ active }) => (active ? "#3871E0" : "#71757A")};
`;
