import styled from 'styled-components';

const Loader = () => {
  return (
    <LoaderWrap>
      <div className="dot-wave">
        <div className="dot-wave__dot"></div>
        <div className="dot-wave__dot"></div>
        <div className="dot-wave__dot"></div>
        <div className="dot-wave__dot"></div>
      </div>
    </LoaderWrap>
  );
};

export default Loader;

const LoaderWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  > img {
    width: 45px;
  }

  .dot-wave {
    --uib-size: 47px;
    --uib-speed: 1s;
    --uib-color: #0c4284;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: var(--uib-size);
    height: calc(var(--uib-size) * 0.17);
    padding-top: calc(var(--uib-size) * 0.34);
  }

  .dot-wave__dot {
    flex-shrink: 0;
    width: calc(var(--uib-size) * 0.17);
    height: calc(var(--uib-size) * 0.17);
    border-radius: 50%;
    background-color: var(--uib-color);
    will-change: transform;
  }

  .dot-wave__dot:nth-of-type(1) {
    animation: jump var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.45) infinite;
  }

  .dot-wave__dot:nth-of-type(2) {
    animation: jump var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.3) infinite;
  }

  .dot-wave__dot:nth-of-type(3) {
    animation: jump var(--uib-speed) ease-in-out calc(var(--uib-speed) * -0.15) infinite;
  }

  .dot-wave__dot:nth-of-type(4) {
    animation: jump var(--uib-speed) ease-in-out infinite;
  }

  @keyframes jump {
    0%,
    100% {
      transform: translateY(0px);
    }

    50% {
      transform: translateY(-200%);
    }
  }
`;
