import styled from "@emotion/styled";

export const HomeWrap = styled.div`
     display: flex;
     flex-direction: ${({ isTabletOrMobile }) => (!isTabletOrMobile ? "row;" : "column;")};

     height: 100%;

     .close-wrap {
          position: absolute;
          z-index: 15;
          top: 30px;
          left: 100%;
          box-shadow:
               0 0 5px 0 rgb(0 0 0 / 20%),
               5px 0 15px 0 rgb(0 0 0 / 10%);
          border-radius: 0 5px 5px 0;
          overflow: hidden;
          button {
               background-color: #fff;
               display: flex;
               justify-content: center;
               align-items: center;
               border: 1px solid #e0e0e0;
               border-left-width: 0;
          }
          svg {
               width: 24px;
               height: 24px;
          }
     }

     .left-container {
          box-sizing: border-box;
          position: relative;
          display: flex;
          z-index: 10;
     }

     .store-detail-default {
          /* margin-bottom: 30px; */
          padding: 30px 20px;
          border-bottom: 6px solid #f0f2f5;
     }
     .store-detail-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
     }

     .store-detail-title h1 {
          font-size: 24px;
     }
     .store-detail-title button svg {
          width: 22px;
          height: 22px;

          path {
               color: #d9d9d9;
          }
     }

     .store-detail-title button.on svg path {
          color: #ffeb5a;
     }

     .store-admin {
          /* padding: 30px 0; */
          border-bottom: 6px solid #f0f2f5;
          > div {
               display: flex;
               align-items: center;
               justify-content: space-between;
               padding: 16px;
               border-bottom: 1px solid #e0e0e0;
          }
     }

     .custom-infow-window {
          background-color: #fff;
          color: #333;
          padding: 5px;
          border: #e0e0e0;
          border-radius: 5px;
          box-shadow:
               0 0 5px 0 rgb(0 0 0 / 20%),
               5px 0 15px 0 rgb(0 0 0 / 10%);
     }

     .label-textarea {
          display: block;
          font-size: 14px;
          color: #757575;
          margin-bottom: 8px;
     }

     #map {
          flex: 1;
     }

     .marker-dot {
          box-sizing: border-box;
          position: absolute;
          bottom: -10px;
          left: 16.5px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: white;
     }

     .marker-dot.gas-station {
          border: 1px solid #ff8099;
     }
     .marker-dot.store {
          border: 1px solid #3e85e8;
     }
     .marker-dot.mart-station {
          border: 1px solid #62d655;
     }
     .marker-dot.side-dish-station {
          border: 1px solid #9868ff;
     }

     .marker-dot.meat {
          border: 1px solid #f6c044;
     }

     .marker-dot.rfc {
          border: 1.5px solid rgba(242, 101, 34, 1);
          bottom: -13px !important;
          left: 17.5px;
     }

     #map .marker-wrap {
          padding-top: 8px;
          &.active .marker-button,
          &.hover .marker-button {
               padding: 4px 8px 4px 4px;
               z-index: 20;
          }
          &.active .marker-button > .marker-none-img,
          &.hover .marker-button > .marker-none-img {
               margin-right: 6px;
          }
     }

     #map .marker-button {
          position: relative;
          display: flex;
          padding: 4px;
          border-radius: 23px;
          background: #fff;
          align-items: center;
          white-space: nowrap;

          & > .marker-none-img {
               width: 30px;
               height: 30px;
               border-radius: 100%;
               background-color: #ef7300;
               display: flex;
               align-items: center;
               justify-content: center;
          }
          & > img {
               width: 30px;
          }
     }

     #map .marker-button.store {
          border: 1px solid #3e85e8;
          & .marker-none-img {
               background-color: transparent;
          }
          .marker-button-count {
               border: 1px solid #3e85e8;
          }
     }

     #map .marker-button.store.rfc {
          border: 2px solid rgba(242, 101, 34, 0.7);
          & .marker-none-img {
               background-color: rgba(242, 101, 34, 0.7);
          }
          .marker-button-count {
               border: 1px solid rgba(242, 101, 34, 1);
          }
     }

     #map .marker-button.gas-station {
          border: 1px solid #ff8099;
          z-index: 10;
          &:after {
               box-shadow: -1px 1px 1px 0 #ff8099;
          }
          & .marker-none-img {
               background-color: transparent;
          }
          .marker-button-count {
               border: 1px solid #ff8099;
          }
     }

     #map .marker-button.mart-station {
          border: 1px solid #62d655;
          &:after {
               box-shadow: -1px 1px 1px 0 #62d655;
          }
          & .marker-none-img {
               background-color: transparent;
          }
          .marker-button-count {
               border: 1px solid #62d655;
          }
     }

     #map .marker-button.side-dish-station {
          border: 1px solid #9868ff;
          &:after {
               box-shadow: -1px 1px 1px 0 #9868ff;
          }
          & .marker-none-img {
               background-color: transparent;
          }
          .marker-button-count {
               border: 1px solid #9868ff;
          }
     }

     #map .marker-button.meat {
          border: 1px solid #f6c044;
          &:after {
               box-shadow: -1px 1px 1px 0 #f6c044;
          }
          & .marker-none-img {
               background-color: transparent;
          }
          .marker-button-count {
               border: 1px solid #f6c044;
          }
     }

     #map .marker-button:after {
          background: linear-gradient(45deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
          box-shadow: -1px 1px 1px 0 #0475f4;
          content: "";
          height: 5px;
          left: 19px;
          position: absolute;
          top: 37px;
          transform: translate(-50%, -50%) rotate(-45deg);
          width: 5px;
     }

     #map .marker-button.store.rfc:after {
          background: linear-gradient(45deg, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 51%, rgba(255, 255, 255, 0) 100%);
          box-shadow: -2px 2px 1px rgba(242, 101, 34, 0.7);
          content: "";
          height: 9px;
          left: 19px;
          position: absolute;
          top: 37px;
          transform: translate(-50%, -50%) rotate(-45deg);
          width: 9px;
     }

     #map .marker-wrap.active img,
     #map .marker-wrap.hover img {
          margin-right: 6px;
     }
     #map .marker-button .marker-button-name {
          /* width: 0;
		overflow: hidden; */
          display: none;
          color: #333;
          opacity: 0;
          font-size: 16px;
          font-weight: 500;
          font-family:
               "Pretendard Variable",
               Pretendard,
               -apple-system,
               BlinkMacSystemFont,
               system-ui,
               Roboto,
               "Helvetica Neue",
               "Segoe UI",
               "Apple SD Gothic Neo",
               "Noto Sans KR",
               "Malgun Gothic",
               "Apple Color Emoji",
               "Segoe UI Emoji",
               "Segoe UI Symbol",
               sans-serif !important;
     }
     #map .marker-wrap.active .marker-button .marker-button-name,
     #map .marker-wrap.hover .marker-button .marker-button-name {
          /* transition: all 400ms; */
          /* width: 100%; */
          display: inline-block;
          opacity: 1;
     }

     #map .marker-button-count {
          width: 20px;
          height: 15px;
          position: absolute;
          top: -6px;
          right: -4px;
          border-radius: 9px;
          background-color: #fff;
          vertical-align: middle;
          text-align: center;
          font-size: 11px;
          line-height: 15px;
          font-weight: bold;
     }
`;

export const PcHomeFilterOpenBtn = styled.div`
     cursor: pointer;
     position: fixed;
     display: flex;
     justify-content: center;
     align-items: center;
     z-index: 2;
     top: 34px;
     left: ${({ sidebarOpen }) => (sidebarOpen ? "515px" : "135px")};
     width: 39px;
     height: 39px;
     background: #ffffff;
     border-radius: 8px;
     border: 1.5px solid #dadce0;
     box-shadow:
          0px 1px 2px 0px rgba(0, 0, 0, 0.05),
          0px 1px 10px 0px rgba(0, 0, 0, 0.15);
`;
