import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import {
     postTobaccoStoreDistance,
     getBookmarkList,
     postTobaccoStoreInfo,
     tobaccoStoreDetailReset,
     postTobaccoStoreSearch,
     tobaccoSearchReset,
} from "../../../reducers/store";
import { use100vh } from "react-div-100vh";

import { userInfo } from "../../../reducers/user";
import { getEndMarkerIcon, getStartMarkerIcon, handleEndMarkerDelete, handleStartMarkerDelete, tobaccoMarkerImage } from "../../../utils/markerUtils";
import { INITIAL_LAT, INITIAL_LNG, INITIAL_TOBACCO_MAP_FILTER, TOBACCO_MAP_CONTROLLER } from "../../../constants/map";

import { Circle } from "../../../constants/naver";
import { TobaccoMobileHomeWrap } from "./TobaccoMobileHomeWrap.styled";
import MobileFilterWrap from "../../../components/mobileFilter/MobileFilterWrap";
import TobaccoFilter from "../../../components/mobileFilter/tobacco/TobaccoFilter";
import MobileBottomMenu from "../../../components/mobileBottomMenu/MobileBottomMenu";
import { updateTobacoLocalStorageData } from "../../../utils/localStorageUpdate";
import { createMarkerEventHandlers, tobaccoMarkerOverlap } from "../../../utils/tobaccoMapMarker";

import MobileTobaccoDirection from "../../../components/mobileTobaccoDirection/MobileTobaccoDirection";
import MobileActiveTobaccoStore from "../../../components/mobileActiveStore/MobileActiveTobaccoStore";
import { handleTobaccoMapLongTouch } from "../../../utils/tobaccoEventHandler";
import TobaccoMobileDetail from "../../../components/MobileDetail/TobaccoMobileDetail";
import MobileTobaccoSearch from "../../../components/mobileTobaccoSearch/MobileTobaccoSearch";

import {
     postChangeStoreAddress,
     postCheckAddressByCoord,
     postTobaccoStoreDistanceApi,
     postTobaccoStoreSearchApi,
     registerTobaccoAddressBookmark,
     registerTobaccoStoreBookmark,
     removeTobaccoStoreBookmark,
} from "../../../api/store";
import MobileUserInfoModal from "../../../components/modal/MobileUserInfo/MobileUserInfoModal";
import MobileNav from "../../../components/MobileHeader/MobileNav";
import MobileHomeTobaccoFilter from "../../../components/MobileFilterV2/MobileHomeTobaccoFilter";
import MobileTobaccoStoreRightButtons from "../../../components/mobileTobaccoStoreRightButtons/MobileTobaccoStoreRightButtons";
import MobileTobaccoStoreDistanceModal from "../../../components/modal/MobileTobaccoStoreDistanceModal/MobileTobaccoStoreDistanceModal";
import MobileTobaccoBookmarkedModal from "../../../components/modal/MobileTobaccoBookmarkedModal/MobileTobaccoStoreDistanceModal";

import MobileTobaccoPlaceChangeModal from "../../../components/modal/MobileTobaccoPlaceChangeModal/MobileTobaccoPlaceChangeModal";
import MobileActiveSelectedPosition from "../../../components/mobileActiveStore/MobileActiveSelectedPosition";
import TopMessageAlert from "../../../components/TopMessage/TopMessageAlert";
import NearestTobaccoStoreV2 from "../../../components/NearestStore/NearestTobaccoStoreV2";

const { naver } = window;

const TobaccoMobileHome = ({ setSnackbarMessage }) => {
     const { accounts } = useMsal();
     const height = use100vh();

     const dispatch = useDispatch();

     const { tobaccoStoreList, tobaccoBookmarkedList } = useSelector(state => state.store);
     const userInfoData = useSelector(state => state.user);

     const map = useRef();
     const listEl = useRef(null);
     const infoEl = useRef(null);
     const markers = useRef([]);
     const directionChangeFlagRef = useRef(false);
     const selectedPositionRef = useRef(null);
     const changeInfoWindowRef = useRef(null);
     const markerInfoWindowRef = useRef(null);
     const startMarkerRef = useRef(null);
     const endMarkerRef = useRef(null);
     const changePlaceRef = useRef(null);
     const directionWithPoint = useRef(null);

     const [reopen, setReopen] = useState(null);
     const [storePositionChangeModalOpen, setStorePositionChangeModalOpen] = useState(false);
     const [searchMode, setSearchMode] = useState(false);
     const [detailMode, setDetailMode] = useState(false);
     const [selectedPosition, setSelectedPosition] = useState(null);
     const [modalUserInfoOpen, setModalUserInfoOpen] = useState(false);
     const [modalDistanceOpen, setModalDistanceOpen] = useState(false);
     const [modalBookmarkedOpen, setModalbookmarkedOpen] = useState(false);

     const [changeNewAddressInfo, setChangeNewAddressInfo] = useState(null);
     const [showPlaceChangeMessage, setShowPlaceChangeMessage] = useState(false);
     const [showDirectionPointMessage, setShowDirectionPointMessage] = useState(false);
     const [directionDrawerOpen, setDirectionDrawerOpen] = useState(false);
     const [directionDrawerSearchMode, setDirectionDrawerSearchMode] = useState({ show: false, type: null });
     const [directionStart, setDirectionStart] = useState({
          isPlace: false,
          coordinate: null,
          name: null,
     });
     const [nearStoreFromActive, setNearStoreFromActive] = useState(null);

     const [selectedPositionInfo, setSelectedPositionInfo] = useState({
          open: false,
          position: null,
          data: null,
          updated: false,
     });

     const [directionEnd, setDirectionEnd] = useState({
          coordinate: null,
          name: null,
     });
     const [showFilterPage, setShowFilterPage] = useState(false);

     const [activeStore, setActiveStore] = useState({
          open: false,
          data: null,
     });
     const [tobaccoFilterOption, setTobaccoFilterOption] = useState(null);

     const [searchValue, setSearchValue] = useState("");

     const handleClickDirectionWithPoint = type => {
          setShowDirectionPointMessage(true);
          directionWithPoint.current = type;
     };

     const handleClickCloseDirectionWithPoint = () => {
          setShowDirectionPointMessage(false);
          directionWithPoint.current = null;
          setDirectionDrawerSearchMode({ show: false, type: null });
     };

     const handleClickSetDirectionWithPoint = async (type, coord) => {
          try {
               const data = await getAddressByCoords(coord.x, coord.y);
               const addressData = data.data;
               const address = addressData.fullRoadAddress ?? addressData.fullLocationAddress ?? "";

               if (type === "START") {
                    setDirectionStart({ coordinate: { x: coord.x, y: coord.y }, name: address, isPlace: true });
               }

               if (type === "END") {
                    setDirectionEnd({ coordinate: { x: coord.x, y: coord.y }, name: address, isPlace: true });
               }
               handleClickCloseDirectionWithPoint();
          } catch {
               handleClickCloseDirectionWithPoint();
          }
     };

     const updateTobaccoStoreList = useCallback((lat, lng, distance, includeClosedWithin) => {
          dispatch(
               postTobaccoStoreDistance({
                    lat,
                    lng,
                    distance,
                    account: accounts[0]["username"],
                    includeClosedWithin,
                    includeSuspendedStore: "Y",
               }),
          );
     }, []);

     useEffect(() => {
          if (accounts) {
               dispatch(getBookmarkList({ account: accounts[0]["username"] }));
          }
     }, [accounts, dispatch]);

     const handleChangeMoveStoreInfo = () => {
          directionChangeFlagRef.current = true;
          setShowPlaceChangeMessage(true);
     };
     const setCircle = useCallback(
          (y, x) => {
               Circle.setCenter(naver.maps.LatLng(y, x));
               Circle.setRadius(tobaccoFilterOption?.circleDistance ?? "75");
               Circle.setMap(map.current);
          },
          [tobaccoFilterOption?.circleDistance, map],
     );

     const onClickStore = useCallback(
          store => {
               setDirectionDrawerSearchMode({ show: false, type: null });
               selectedPositionRef?.current?.close();
               setSelectedPosition(null);
               if (store.managementNumber) {
                    onClickStopPositionMove();

                    dispatch(
                         postTobaccoStoreInfo({
                              account: accounts[0]["username"],
                              managementNumber: store.managementNumber,
                         }),
                    );
               }
               const { circleDistance, includeClosedWithin } = tobaccoFilterOption;

               updateTobaccoStoreList(store.ylatitude, store.xlongitude, circleDistance, includeClosedWithin);

               setActiveStore({ open: true, data: store });
               setCircle(store.ylatitude, store.xlongitude);
               map.current.setCenter(naver.maps.LatLng(store.ylatitude, store.xlongitude));
          },
          [dispatch, accounts, tobaccoFilterOption, setCircle],
     );

     const updateFromFavoriteStore = useCallback(
          store => {
               dispatch(
                    postTobaccoStoreInfo({
                         account: accounts[0]["username"],
                         managementNumber: store.managementNumber,
                    }),
               );
               const { circleDistance, includeClosedWithin } = tobaccoFilterOption;

               updateTobaccoStoreList(store.ylatitude, store.xlongitude, circleDistance, includeClosedWithin);
               setActiveStore({ open: true, data: { ...store, isBookmarked: store.isBookmarked === "Y" ? "N" : "Y" } });
          },
          [dispatch, accounts, tobaccoFilterOption],
     );

     useEffect(() => {
          const tobaccoMapFilterData = localStorage.getItem(TOBACCO_MAP_CONTROLLER);

          if (!tobaccoMapFilterData) {
               localStorage.setItem(TOBACCO_MAP_CONTROLLER, JSON.stringify(INITIAL_TOBACCO_MAP_FILTER));
               setTobaccoFilterOption(INITIAL_TOBACCO_MAP_FILTER);
          } else {
               try {
                    const parsedTobaccoMapFilterData = JSON.parse(tobaccoMapFilterData);
                    setTobaccoFilterOption(parsedTobaccoMapFilterData);
               } catch (error) {
                    console.error("Failed to parse tobaccoMapFilterData:", error);
                    localStorage.setItem(TOBACCO_MAP_CONTROLLER, JSON.stringify(INITIAL_TOBACCO_MAP_FILTER));
                    setTobaccoFilterOption(INITIAL_TOBACCO_MAP_FILTER);
               }
          }
     }, []);

     useEffect(() => {
          handleStartMarkerDelete(startMarkerRef, markerInfoWindowRef);

          if (directionStart?.isPlace && directionStart.coordinate?.x && directionStart.coordinate?.y && !startMarkerRef.current) {
               const startMarker = new naver.maps.Marker({
                    position: directionStart.coordinate,
                    map: map.current,
                    icon: getStartMarkerIcon(),
               });
               startMarkerRef.current = startMarker;
          }
     }, [directionStart]);

     useEffect(() => {
          handleEndMarkerDelete(endMarkerRef, markerInfoWindowRef);

          if (directionEnd?.isPlace && directionEnd.coordinate?.x && directionEnd.coordinate?.y && !endMarkerRef.current) {
               handleEndMarkerDelete(endMarkerRef, markerInfoWindowRef);
               const endMarker = new naver.maps.Marker({
                    position: directionEnd.coordinate,
                    map: map.current,
                    icon: getEndMarkerIcon(),
               });
               endMarkerRef.current = endMarker;
          }
     }, [directionEnd]);

     useEffect(() => {
          const tobaccoFilterData = localStorage.getItem(TOBACCO_MAP_CONTROLLER);
          const parsedTobaccoFilterData = JSON.parse(tobaccoFilterData);

          const { distance, includeClosedWithin } = parsedTobaccoFilterData;

          map.current = new naver.maps.Map("map", {
               center: new naver.maps.LatLng(INITIAL_LAT, INITIAL_LNG),
               zoom: 18,
          });

          if (navigator?.geolocation) {
               navigator.geolocation.getCurrentPosition(
                    position => {
                         const golocationPosition = new naver.maps.LatLng(position.coords.latitude, position.coords.longitude);
                         map.current.setCenter(golocationPosition);

                         updateTobaccoStoreList(position.coords.latitude, position.coords.longitude, distance, includeClosedWithin);
                    },
                    error => {
                         console.log("위치 정보 미동의");

                         updateTobaccoStoreList(INITIAL_LAT, INITIAL_LNG, distance, includeClosedWithin);

                         console.error(error);
                    },
                    {
                         enableHighAccuracy: false,
                         maximumAge: 0,
                         timeout: Infinity,
                    },
               );
          } else {
               console.error("GPS를 지원하지 않습니다");
               updateTobaccoStoreList(INITIAL_LAT, INITIAL_LNG, distance, includeClosedWithin);
          }
     }, [accounts, dispatch]);

     useEffect(() => {
          if (tobaccoStoreList) {
               if (markers.current.length > 0) {
                    markers.current.forEach(marker => {
                         marker["marker"].setMap(null);
                    });
                    markers.current = [];
               }

               let markerList = [...tobaccoStoreList];

               if (tobaccoFilterOption?.showFavorite) {
                    markerList = markerList.filter(el => {
                         return el.isBookmarked === "Y";
                    });
               } else {
                    if (!tobaccoFilterOption?.showOpenStore) {
                         markerList = markerList.filter(el => {
                              return el.businessStatusCode !== "01";
                         });
                    }

                    if (!tobaccoFilterOption?.showSuspendedStore) {
                         markerList = markerList.filter(el => {
                              return el.businessStatusCode !== "02";
                         });
                    }
               }

               const overlap = tobaccoMarkerOverlap(markerList, map);

               overlap.forEach(store => {
                    const content = tobaccoMarkerImage(store, activeStore);

                    const marker = new naver.maps.Marker({
                         map: map.current,
                         position: store.coord,
                         icon: {
                              content: content,
                              size: new naver.maps.Size(30, 30),
                              anchor: new naver.maps.Point(20, 45),
                         },
                    });

                    markers.current = [...markers.current, { ...store, marker }];

                    if (marker) {
                         const { mouseOverHandler, mouseOutHandler, clickHandler } = createMarkerEventHandlers(
                              marker,
                              store,
                              onClickStore,
                              deleteCircle,
                              map,
                              activeStore,
                         );
                    }
               });
          }
     }, [
          tobaccoStoreList,
          tobaccoFilterOption?.showFavorite,
          tobaccoFilterOption?.showOpenStore,
          tobaccoFilterOption?.showSuspendedStore,
          tobaccoFilterOption?.distance,
          tobaccoFilterOption?.shutdownInclude,
          tobaccoFilterOption?.shutdownPeriod,
          activeStore,
          onClickStore,
     ]);

     const onClickStoreDetailClose = useCallback(() => {
          const { xlongitude, ylatitude } = activeStore.data;

          setActiveStore({ open: false, data: null });
          deleteCircle();
          dispatch(tobaccoStoreDetailReset());

          const { distance, includeClosedWithin } = tobaccoFilterOption;
          updateTobaccoStoreList(ylatitude, xlongitude, distance, includeClosedWithin);

          directionChangeFlagRef.current = null;
          changePlaceRef.current = null;

          changeInfoWindowRef?.current?.close();

          if (activeStore?.data && activeStore.data.managementNumber) {
               const marker = document.getElementById(`${activeStore.data.managementNumber}`);

               marker && marker.parentElement.classList.remove("active");
          }
     }, [activeStore.data, dispatch]);

     const onChangeZoom = useCallback(
          e => {
               setNearStoreFromActive(null);
               if (e <= 10) {
                    if (markers.current.length > 0) {
                         markers.current.forEach(marker => {
                              marker["marker"].setMap(null);
                         });
                         markers.current = [];
                    }
                    onClickStoreDetailClose();
               } else if (!Circle.getMap()) {
                    const position = map.current.getCenter();

                    const { distance, includeClosedWithin } = tobaccoFilterOption;
                    updateTobaccoStoreList(position.y, position.x, distance, includeClosedWithin);
               }
          },
          [dispatch, tobaccoFilterOption?.distance, onClickStoreDetailClose],
     );

     /** 지도 드래그 이벤트 */
     const onChangeDragend = useCallback(
          e => {
               if (map.current.getZoom() > 10 && !Circle.getMap()) {
                    const position = map.current.getCenter();

                    updateTobaccoStoreList(position.y, position.x, tobaccoFilterOption?.distance, tobaccoFilterOption?.includeClosedWithin);
               }
          },
          [dispatch, tobaccoFilterOption?.includeClosedWithin, tobaccoFilterOption?.distance],
     );

     const onChangeSize = e => {
          setNearStoreFromActive(null);
          map.current.autoResize();
     };
     const onDragStart = e => {
          setNearStoreFromActive(null);
     };

     const deleteCircle = (y, x) => {
          Circle.setMap(null);
     };

     /** 지도 줌 & 드래그 변화 감지 */
     useEffect(() => {
          const dragStartEvent = naver.maps.Event.addListener(map.current, "dragstart", onDragStart);
          const centerEvent = naver.maps.Event.addListener(map.current, "dragend", onChangeDragend);
          const zoomEvent = naver.maps.Event.addListener(map.current, "zoom_changed", onChangeZoom);
          const sizeEvent = naver.maps.Event.addListener(map.current, "size_changed", onChangeSize);

          const { onTouchStart, onTouchEnd } = handleTobaccoMapLongTouch({
               map,
               directionChangeFlagRef,
               changePlaceRef,
               setStorePositionChangeModalOpen,
               changeInfoWindowRef,
               selectedPositionRef,
               longPressDuration: 2000, // 필요 시 조정
               setSelectedPosition,
               directionWithPoint,
               handleClickSetDirectionWithPoint,
          });

          const touchStartEvent = naver.maps.Event.addListener(map.current, "touchstart", onTouchStart);
          const touchEndEvent = naver.maps.Event.addListener(map.current, "touchend", onTouchEnd);

          return () => {
               naver.maps.Event.removeListener(centerEvent);
               naver.maps.Event.removeListener(zoomEvent);
               naver.maps.Event.removeListener(sizeEvent);
               naver.maps.Event.removeListener(dragStartEvent);

               naver.maps.Event.removeListener(touchStartEvent);
               naver.maps.Event.removeListener(touchEndEvent);
          };
     }, [accounts, tobaccoFilterOption?.distance, tobaccoFilterOption?.circleDistance, onChangeDragend, onChangeZoom]);

     useEffect(() => {
          map.current.autoResize();
     }, [searchMode, detailMode]);

     const onClickDistanceButton = distance => () => {
          if (!activeStore.open) {
               const position = map.current.getCenter();

               updateTobaccoStoreList(position.y, position.x, distance, tobaccoFilterOption?.includeClosedWithin);
          }
          setTobaccoFilterOption(prev => ({
               ...prev,
               distance,
          }));

          updateTobacoLocalStorageData({ distance });
     };

     const onClickCircleDistanceButton = circleDistance => () => {
          if (activeStore.open) {
               const position = Circle.getCenter();
               Circle.setRadius(circleDistance);
               updateTobaccoStoreList(position.y, position.x, circleDistance, tobaccoFilterOption?.includeClosedWithin);
          }

          setTobaccoFilterOption(prev => ({
               ...prev,
               circleDistance,
          }));

          updateTobacoLocalStorageData({ circleDistance });
     };

     const onSearch = type => () => {
          dispatch(
               postTobaccoStoreSearch({
                    storeName: searchValue,
                    account: accounts[0]["username"],
                    includeClosedWithin: tobaccoFilterOption?.includeClosedWithin,
                    includeSuspendedStore: "Y",
               }),
          );
     };

     const handleCheckIncludeShutDownStore = e => {
          const target = e.target.id;
          const isChecked = e.target.checked;

          let closeMonth = 0;

          if (target === "shutDown" && isChecked) {
               closeMonth = 3;
          }
          if (target === "sixMonths") {
               if (isChecked) {
                    closeMonth = 6;
               } else {
                    closeMonth = 3;
               }
          }

          const position = map.current.getCenter();
          setTobaccoFilterOption(prev => ({
               ...prev,
               includeClosedWithin: closeMonth,
          }));

          updateTobaccoStoreList(position.y, position.x, tobaccoFilterOption?.distance, closeMonth);

          updateTobacoLocalStorageData({ includeClosedWithin: closeMonth });
     };

     const onClickChangeFilterOption = key => {
          const newValue = {};

          if (key === "open") {
               newValue.showOpenStore = !tobaccoFilterOption?.showOpenStore;
          }
          if (key === "suspended") {
               newValue.showSuspendedStore = !tobaccoFilterOption?.showSuspendedStore;
          }
          if (key === "closed3") {
               newValue.includeClosedWithin = tobaccoFilterOption?.includeClosedWithin > 0 ? 0 : 3;
          }

          if (tobaccoFilterOption.showFavorite) {
               newValue.showFavorite = false;
          }

          const updatedFilterOption = {
               ...tobaccoFilterOption,
               ...newValue,
          };

          const position = map.current.getCenter();

          setTobaccoFilterOption(updatedFilterOption);

          updateTobacoLocalStorageData(updatedFilterOption);
          updateTobaccoStoreList(position.y, position.x, updatedFilterOption.distance, updatedFilterOption.includeClosedWithin);
     };

     const onClickFavorite = () => {
          const newValue = {};
          newValue.showFavorite = !tobaccoFilterOption?.showFavorite;
          const position = map.current.getCenter();
          setTobaccoFilterOption(prev => ({
               ...prev,
               ...newValue,
          }));

          updateTobacoLocalStorageData({
               ...tobaccoFilterOption,
               ...newValue,
          });

          updateTobaccoStoreList(position.y, position.x, tobaccoFilterOption?.distance, tobaccoFilterOption.includeClosedWithin);
     };

     /** 검색어 이벤트 */
     const onChangeSearchValue = e => {
          if (e.target.value.trim() === "") {
               dispatch(tobaccoSearchReset());
          }
          setSearchValue(e.target.value);
     };

     /** 리스트 클릭 이벤트 */
     const onClickStoreName = item => {
          map.current.setCenter(new naver.maps.LatLng(item.ylatitude, item.xlongitude));
          onClickStore(item);
          resetMode();
     };

     const resetMode = () => {
          setSearchMode(false);
          setDetailMode(false);
     };

     useEffect(() => {
          const sizeEvent = naver.maps.Event.addListener(map.current, "size_changed", e => {
               map.current.autoResize();
          });
          return () => {
               naver.maps.Event.removeListener(sizeEvent);
          };
     }, []);

     useEffect(() => {
          if (userInfoData?.kor_nm == null || userInfoData === undefined) {
               dispatch(
                    userInfo({
                         account: accounts[0]["username"],
                         kor_nm: accounts[0]["name"],
                    }),
               );
          }
     }, [accounts, dispatch, userInfoData]);

     const getAddressByCoords = async (lng, lat) => {
          try {
               const newAddrData = await postCheckAddressByCoord({
                    lng,
                    lat,
                    account: accounts[0]["username"],
               });

               return newAddrData.data;
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
               return null;
          }
     };

     const handleCloseActiveStore = () => {
          onClickStopPositionMove();
          setNearStoreFromActive(null);
          const { xlongitude, ylatitude } = activeStore.data;
          const { distance, includeClosedWithin } = tobaccoFilterOption;
          setActiveStore({
               open: false,
               data: null,
          });
          deleteCircle();
          setStorePositionChangeModalOpen(false);
          setShowPlaceChangeMessage(false);

          directionChangeFlagRef.current = null;
          changePlaceRef.current = null;

          changeInfoWindowRef?.current?.close();

          updateTobaccoStoreList(ylatitude, xlongitude, distance, includeClosedWithin);
          dispatch(tobaccoStoreDetailReset());
          // if (activeStore?.data?.managementNumber) {
          //      const marker = document.getElementById(`${activeStore.data.managementNumber}`);
          //      marker && marker.parentElement.classList.remove("active");
          // }
     };

     const handleChangeFilterPage = () => setShowFilterPage(prev => !prev);

     const addressChange = async () => {
          const username = accounts[0].name;
          const account = accounts[0].username;
          const storeData = activeStore.data;

          const requestParam = {
               username,
               account,
               managementNumber: storeData.managementNumber,
               ...changeNewAddressInfo.data,
               lng: changePlaceRef.current.x,
               lat: changePlaceRef.current.y,
          };

          try {
               const done = await postChangeStoreAddress(requestParam);

               if (done.data.resStatus === "success") {
                    setStorePositionChangeModalOpen(false);
                    setShowPlaceChangeMessage(false);

                    setChangeNewAddressInfo(null);
                    changeInfoWindowRef?.current?.close();
                    changePlaceRef.current = null;
                    directionChangeFlagRef.current = null;

                    const beforeActiveStore = activeStore.data;

                    // const marker = document.getElementById(`${activeStore.data.managementNumber}`);
                    // marker && marker.parentElement.classList.remove("active");
                    setActiveStore({ open: false, data: null });
                    deleteCircle();
                    setTimeout(() => {
                         setReopen(beforeActiveStore);
                    }, 300);
               }
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
          }
     };

     useEffect(() => {
          if (reopen !== null) {
               try {
                    postTobaccoStoreSearchApi({
                         storeName: reopen.businessName,
                         account: accounts[0].username,
                         includeClosedWithin: tobaccoFilterOption?.includeClosedWithin,
                         includeSuspendedStore: "Y",
                    }).then(data => {
                         const getBeforeStore = data.data?.data.find(el => el.managementNumber === reopen.managementNumber);
                         if (getBeforeStore) {
                              setTimeout(() => {
                                   onClickStore(getBeforeStore);
                                   setReopen(null);
                              }, 500);
                         }
                    });
               } catch (e) {
                    console.log("error!");
               }
          }
     }, [reopen, accounts, onClickStore, tobaccoFilterOption?.includeClosedWithin]);

     useEffect(() => {
          if (storePositionChangeModalOpen && changePlaceRef.current) {
               getAddressByCoords(changePlaceRef.current.x, changePlaceRef.current.y).then(data => setChangeNewAddressInfo(data));
          }
     }, [storePositionChangeModalOpen, getAddressByCoords]);

     /** 매장 활성화 ClassName 지정 */
     useEffect(() => {
          if (activeStore.data?.managementNumber) {
               setTimeout(() => {
                    const marker = document.getElementById(`${activeStore.data.managementNumber}`);
                    marker && marker.parentElement.classList.add("active");

                    const rect = marker?.getBoundingClientRect();
                    const currentStoreInfoFromMarker = markers.current.find(el => el.managementNumber === activeStore.data.managementNumber);

                    if (currentStoreInfoFromMarker && currentStoreInfoFromMarker.child.length > 0) {
                         setNearStoreFromActive({
                              stores: currentStoreInfoFromMarker.child,
                              position: {
                                   bottom: rect.bottom,
                                   left: rect.left,
                              },
                         });
                    } else {
                         setNearStoreFromActive(null);
                    }
               }, 800);
          }
     }, [activeStore]);

     const registerBookmark = async managementNumber => {
          try {
               const registerBookmarkRequest = await registerTobaccoStoreBookmark({
                    username: accounts[0].name,
                    account: accounts[0]["username"],
                    managementNumber,
               });

               return registerBookmarkRequest.data;
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
               return null;
          }
     };

     const registerBookmarkByAddress = async (fullRoadAddress, fullLocationAddress, lng, lat) => {
          try {
               const registerBookmarkRequest = await registerTobaccoAddressBookmark({
                    username: accounts[0].name,
                    account: accounts[0]["username"],
                    fullRoadAddress,
                    fullLocationAddress,
                    lng,
                    lat,
               });

               return registerBookmarkRequest.data;
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
               return null;
          }
     };

     const removeBookmark = async bookmarkId => {
          try {
               const registerBookmarkRequest = await removeTobaccoStoreBookmark({
                    account: accounts[0]["username"],
                    bookmarkId,
               });

               return registerBookmarkRequest.data;
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
               return null;
          }
     };

     const handleClickFavoriteBtn = store => {
          if (store.bookmarkId && !store.businessStatusCode) {
               removeBookmark(store.bookmarkId).then(() => {
                    dispatch(getBookmarkList({ account: accounts[0]["username"] }));
                    handleCloseActiveStore();
               });

               return;
          }

          const findStoreFromList = tobaccoBookmarkedList.find(el => el.managementNumber === store.managementNumber);

          if (findStoreFromList?.bookmarkId && findStoreFromList?.managementNumber) {
               removeBookmark(findStoreFromList.bookmarkId)
                    .then(() => {
                         updateFromFavoriteStore(store);
                    })
                    .finally(() => {
                         dispatch(getBookmarkList({ account: accounts[0]["username"] }));
                    });
          } else {
               registerBookmark(store.managementNumber)
                    .then(data => {
                         if (data.resStatus === "success") {
                              updateFromFavoriteStore(store);
                         }
                    })
                    .finally(() => {
                         dispatch(getBookmarkList({ account: accounts[0]["username"] }));
                    });
          }
     };

     const handleRemoveBookmarkedStoreByModal = bookmarkId => {
          removeBookmark(bookmarkId).then(() => {
               dispatch(getBookmarkList({ account: accounts[0]["username"] }));
          });
     };

     const searchBookmarkStore = async bookmarkItem => {
          try {
               postTobaccoStoreSearchApi({
                    storeName: bookmarkItem.businessName,
                    account: accounts[0].username,
                    includeClosedWithin: 6,
                    includeSuspendedStore: "Y",
               }).then(data => {
                    const storeList = data.data.data;
                    const selectedStore = storeList.find(el => el.managementNumber === bookmarkItem.managementNumber);

                    if (selectedStore) {
                         onClickStore(selectedStore);
                    }
               });
          } catch (e) {}
     };

     const searchBookmarkAddress = async bookmarkItem => {
          try {
               const findBookmark = tobaccoBookmarkedList.find(el => el.bookmarkId === bookmarkItem.bookmarkId);

               postTobaccoStoreDistanceApi({
                    account: accounts[0].username,
                    lat: findBookmark.ylatitude,
                    lng: findBookmark.xlongitude,
                    distance: tobaccoFilterOption.distance,
                    includeClosedWithin: 0,
                    includeSuspendedStore: "Y",
               }).then(data => {
                    const searchData = data.data.data;
                    const findStore = searchData.find(el => el.bookmarkId === findBookmark.bookmarkId);

                    if (findStore) {
                         onClickStore(findStore);
                    }
               });
          } catch (e) {}
     };

     const selectBookmarkItem = bookmarkItem => {
          if (bookmarkItem.bookmarkType === "STORE") {
               searchBookmarkStore(bookmarkItem);
               return;
          }
          searchBookmarkAddress(bookmarkItem);
     };

     const onClickStopPositionMove = () => {
          setShowPlaceChangeMessage(false);
          directionChangeFlagRef.current = null;
          changeInfoWindowRef.current?.close();
     };
     const onClickCloseDirectionWithPoint = () => {
          setShowDirectionPointMessage(false);
          directionWithPoint.current = null;
     };

     const handleCloseSelectedPositionInfo = () => {
          setSelectedPositionInfo({ open: false, data: null, position: null, updated: false });
          if (selectedPositionRef.current) {
               selectedPositionRef.current?.close();
               selectedPositionRef.current = null;
          }
     };

     useEffect(() => {
          if (selectedPosition) {
               getAddressByCoords(selectedPosition.x, selectedPosition.y).then(data => {
                    map.current.setCenter(naver.maps.LatLng(selectedPosition.y, selectedPosition.x));
                    if (activeStore.open) {
                         handleCloseActiveStore();
                         return;
                    } else {
                         updateTobaccoStoreList(selectedPosition.y, selectedPosition.x, tobaccoFilterOption.distance, tobaccoFilterOption.includeClosedWithin);
                    }

                    setSelectedPositionInfo({
                         open: true,
                         position: selectedPosition,
                         data,
                         updated: false,
                    });
               });
          }
     }, [selectedPosition, activeStore, tobaccoFilterOption]);

     const handleClickAddressBookmark = locationInfo => {
          registerBookmarkByAddress(locationInfo.fullRoadAddress, locationInfo.fullLocationAddress, selectedPosition.x, selectedPosition.y).then(() => {
               getBookmarkList({ account: accounts[0]["username"] });

               updateTobaccoStoreList(selectedPosition.y, selectedPosition.x, tobaccoFilterOption.distance, tobaccoFilterOption.includeClosedWithin);
               setSelectedPositionInfo(prev => ({ ...prev, open: false, updated: true }));
               setSelectedPosition(null);
          });
     };

     useEffect(() => {
          if (!tobaccoStoreList || !selectedPositionInfo.updated) return;

          const findStore = tobaccoStoreList.find(el => el.ylatitude === selectedPositionInfo.position.y && el.xlongitude === selectedPositionInfo.position.x);

          if (findStore) {
               onClickStore(findStore);
               handleCloseSelectedPositionInfo();
               return;
          }
     }, [selectedPositionInfo, tobaccoStoreList, onClickStore]);

     return (
          <>
               {showPlaceChangeMessage && (
                    <TopMessageAlert
                         message={"변경을 원하는 위치로 이동 후 1초간 꾹 누르세요!\n정확도를 위해 변경된 최종 주소를 꼭 확인하세요"}
                         onClose={onClickStopPositionMove}
                    />
               )}
               {showDirectionPointMessage && <TopMessageAlert message={"지도에서 위치를 선택해 주세요"} onClose={onClickCloseDirectionWithPoint} />}

               {!showDirectionPointMessage && !showPlaceChangeMessage && !detailMode && !searchMode && (
                    <MobileHomeTobaccoFilter
                         onClickFavorite={onClickFavorite}
                         tobaccoFilterOption={tobaccoFilterOption}
                         updateTobaccoStoreList={updateTobaccoStoreList}
                         onClickChangeFilterOption={onClickChangeFilterOption}
                    />
               )}

               {!showDirectionPointMessage && !showPlaceChangeMessage && !detailMode && !searchMode && (
                    <MobileTobaccoStoreRightButtons
                         handleOpenDistance={() => setModalDistanceOpen(true)}
                         handleOpenBookmarked={() => setModalbookmarkedOpen(true)}
                         handleChangeDirectionStatus={() => setDirectionDrawerOpen(prev => !prev)}
                         map={map}
                         tobaccoFilterOption={tobaccoFilterOption}
                         updateTobaccoStoreList={updateTobaccoStoreList}
                    />
               )}

               <MobileUserInfoModal open={modalUserInfoOpen} onClose={() => setModalUserInfoOpen(false)} />

               <MobileTobaccoStoreDistanceModal
                    distance={tobaccoFilterOption?.distance}
                    circleDistance={tobaccoFilterOption?.circleDistance}
                    open={modalDistanceOpen}
                    onClickDistanceButton={onClickDistanceButton}
                    onClickCircleDistanceButton={onClickCircleDistanceButton}
                    onClose={() => setModalDistanceOpen(false)}
               />

               <MobileTobaccoBookmarkedModal
                    handleRemoveBookmarkedStoreByModal={handleRemoveBookmarkedStoreByModal}
                    open={modalBookmarkedOpen}
                    onClose={() => setModalbookmarkedOpen(false)}
                    selectBookmarkItem={selectBookmarkItem}
               />
               <MobileTobaccoPlaceChangeModal
                    activeStore={activeStore}
                    open={storePositionChangeModalOpen}
                    onClose={() => {
                         setStorePositionChangeModalOpen(false);
                         setChangeNewAddressInfo(null);
                    }}
                    changeNewAddressInfo={changeNewAddressInfo}
                    addressChange={addressChange}
               />

               {directionDrawerOpen && !detailMode && !searchMode && (
                    <MobileTobaccoDirection
                         showDirectionPointMessage={showDirectionPointMessage}
                         map={map}
                         onClickCloseDirectionWithPoint={onClickCloseDirectionWithPoint}
                         directionDrawerClose={() => {
                              setDirectionDrawerOpen(false);
                         }}
                         account={accounts[0]["username"]}
                         filterOption={tobaccoFilterOption}
                         directionStart={directionStart}
                         directionEnd={directionEnd}
                         setDirectionStart={setDirectionStart}
                         setDirectionEnd={setDirectionEnd}
                         handleClickDirectionWithPoint={handleClickDirectionWithPoint}
                         directionDrawerSearchMode={directionDrawerSearchMode}
                         setDirectionDrawerSearchMode={setDirectionDrawerSearchMode}
                    />
               )}

               <MobileFilterWrap open={showFilterPage} onClose={handleChangeFilterPage}>
                    <TobaccoFilter
                         filterOption={tobaccoFilterOption}
                         onChangeDistance={onClickDistanceButton}
                         onChangeCircleDistance={onClickCircleDistanceButton}
                         handleCheckIncludeShutDownStore={handleCheckIncludeShutDownStore}
                    />
               </MobileFilterWrap>

               <TobaccoMobileHomeWrap isTabletOrMobile height={height}>
                    {!showDirectionPointMessage && !showPlaceChangeMessage && (
                         <MobileNav setSearchMode={setSearchMode} setModalUserInfoOpen={setModalUserInfoOpen} />
                    )}
                    <div id="map" style={{ width: "100%", height: `calc(${height} - 60px)}` }} />
                    {activeStore?.open && (
                         <MobileActiveTobaccoStore
                              directionDrawerOpen={directionDrawerOpen}
                              showPlaceChangeMessage={showPlaceChangeMessage}
                              handleChangeMoveStoreInfo={handleChangeMoveStoreInfo}
                              onClickDetailModeOn={() => setDetailMode(true)}
                              handleCloseActiveStore={handleCloseActiveStore}
                              activeStore={activeStore.data}
                              setDirectionStart={setDirectionStart}
                              setDirectionDrawerOpen={setDirectionDrawerOpen}
                              setDirectionEnd={setDirectionEnd}
                              handleClickFavoriteBtn={handleClickFavoriteBtn}
                         />
                    )}

                    {selectedPositionInfo.open && selectedPosition && (
                         <MobileActiveSelectedPosition
                              directionDrawerOpen={directionDrawerOpen}
                              handleClickAddressBookmark={handleClickAddressBookmark}
                              showPlaceChangeMessage={showPlaceChangeMessage}
                              selectedPosition={selectedPosition}
                              selectedPositionInfo={selectedPositionInfo}
                              handleCloseSelectedPositionInfo={handleCloseSelectedPositionInfo}
                              setDirectionStart={setDirectionStart}
                              setDirectionEnd={setDirectionEnd}
                              handleClickFavoriteBtn={handleClickFavoriteBtn}
                         />
                    )}
                    {searchMode && (
                         <MobileTobaccoSearch
                              listEl={listEl}
                              onClickClose={() => setSearchMode(prev => !prev)}
                              onClickStoreName={onClickStoreName}
                              onSearch={onSearch}
                              onChangeSearchValue={onChangeSearchValue}
                              searchValue={searchValue}
                         />
                    )}

                    {detailMode && (
                         <TobaccoMobileDetail
                              activeStore={activeStore}
                              handleSetDetailModeOn={() => setDetailMode(true)}
                              infoEl={infoEl}
                              handleCloseDetailMode={() => setDetailMode(false)}
                              setDirectionStart={setDirectionStart}
                              setDirectionEnd={setDirectionEnd}
                              handleClickFavoriteBtn={handleClickFavoriteBtn}
                              handleChangeMoveStoreInfo={handleChangeMoveStoreInfo}
                         />
                    )}
                    {nearStoreFromActive && <NearestTobaccoStoreV2 nearStoreFromActive={nearStoreFromActive} onClickStore={onClickStore} />}
               </TobaccoMobileHomeWrap>

               {!directionDrawerOpen && !detailMode && !searchMode && <MobileBottomMenu />}
          </>
     );
};

export default TobaccoMobileHome;
