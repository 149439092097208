import { memo, useState } from "react";

import { TobaccoBookmarkListWrap } from "./SidebarTobaccoBookmarkList.styled";
import { useSelector } from "react-redux";
import Modal from "../../modal";
import styled from "@emotion/styled";

const SidebarTobaccoBookmarkList = memo(({ handleRemoveBookmarkedStore, selectBookmarkItem }) => {
     const { tobaccoBookmarkedList } = useSelector(state => state.store);

     const [deleteInfo, setDeleteInfo] = useState({ store: null, showDeletePage: false });

     const handleClickDeleteBtn = bookmarkInfo => {
          handleRemoveBookmarkedStore(bookmarkInfo);

          setDeleteInfo({ store: bookmarkInfo, showDeletePage: true });
     };

     const handleClickBookmarkItem = bookmarkItem => {
          selectBookmarkItem(bookmarkItem);
     };

     if (!tobaccoBookmarkedList) {
          return null;
     }

     return (
          <>
               <TobaccoBookmarkListWrap>
                    <ul id="search-list" className="section">
                         {tobaccoBookmarkedList.length === 0 && (
                              <div className="empty">
                                   <div className="empty-text">{"즐겨찾기를 등록한 점포가 없어요\n즐겨찾기를 추가해 주세요"}</div>
                                   <img src="img/images/empty.png" alt="empty" width={190} />
                              </div>
                         )}

                         {tobaccoBookmarkedList.length > 0 &&
                              tobaccoBookmarkedList.map((item, index) => {
                                   const address = item.fullRoadAddress ?? item.fullLocationAddress ?? "";

                                   return (
                                        <li key={"search-item" + item.bookmarkId + index} className="search-item" onClick={() => handleClickBookmarkItem(item)}>
                                             <div>
                                                  <div className="search-item-title">
                                                       <h2>{item?.businessName ?? item?.fullRoadAddress ?? item?.fullLocationAddress ?? ""}</h2>
                                                  </div>

                                                  <p className="address">{address}</p>
                                             </div>
                                             <div className="bookmark-right">
                                                  <div
                                                       onClick={e => {
                                                            e.stopPropagation();

                                                            const name = item?.businessName ?? item?.fullRoadAddress ?? item?.fullLocationAddress ?? "";
                                                            if (window.confirm("즐겨찾기 : " + name + " 을(를) 삭제하시겠습니까?")) {
                                                                 handleClickDeleteBtn(item);
                                                            }
                                                       }}
                                                  >
                                                       <img width={24} src="img/icons/trash_round.png" alt="remove-bookmark" />
                                                  </div>
                                             </div>
                                        </li>
                                   );
                              })}
                    </ul>
               </TobaccoBookmarkListWrap>

               <Modal
                    open={deleteInfo.showDeletePage}
                    onClose={() => setDeleteInfo({ store: null, showDeletePage: false })}
                    noHeader
                    headCloseButton={false}
                    width={320}
               >
                    {deleteInfo.showDeletePage && (
                         <DeleteInfoWrap>
                              <div className="text">{`${deleteInfo?.store?.businessName ?? deleteInfo?.store.fullRoadAddress ?? deleteInfo.store.fullLocationAddress}이\n즐겨찾기에서 삭제 되었어요`}</div>
                              <div className="close-btn" onClick={() => setDeleteInfo({ store: null, showDeletePage: false })}>
                                   확인
                              </div>
                         </DeleteInfoWrap>
                    )}
               </Modal>
          </>
     );
});

export default SidebarTobaccoBookmarkList;

const DeleteInfoWrap = styled.div`
     box-sizing: border-box;
     display: flex;
     flex-direction: column;
     align-items: center;

     justify-content: space-between;
     width: 444px;
     height: 365px;
     padding: 20px;

     .text {
          color: #3d4043;
          font-size: 25px;
          font-style: normal;
          font-weight: 700;
          line-height: 35px; /* 140% */
          letter-spacing: 0.25px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          white-space: pre-line;
     }
     .close-btn {
          cursor: pointer;
          box-sizing: border-box;
          color: #fff;
          font-size: 25px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.12px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 370px;
          height: 81px;
          padding: 8px 11px;
          flex-shrink: 0;
          border-radius: 8px;
          border: 1px solid #dadce0;
          background: #3d4043;
     }
`;
