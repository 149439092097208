import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { getBookmarkList, postTobaccoStoreDistance, postTobaccoStoreInfo, tobaccoStoreDetailReset } from "../../reducers/store";

import { OnIsTabletOrMobile } from "../../hook/widthSize";

import { userInfo } from "../../reducers/user";

import { DISTANCE_500, INITIAL_LAT, INITIAL_LNG, INITIAL_TOBACCO_MAP_FILTER, TOBACCO_MAP_CONTROLLER } from "../../constants/map";
import { TobaccoHomeWrap } from "./HomeWrap.styled";

import { Circle } from "../../constants/naver";
import { TAB } from "../../constants/menu";
import PcSidebar from "../../components/sidebar/PcSideBar";
import TobaccoSidebarContents from "../../components/tobaccoSideBar/TobaccoSidebarContents";
import { getEndMarkerIcon, getStartMarkerIcon, handleEndMarkerDelete, handleStartMarkerDelete, tobaccoMarkerImage } from "../../utils/markerUtils";

import { handleMapClick, handleTobaccoRightClick } from "../../utils/tobaccoEventHandler";
import { createMarkerEventHandlers, tobaccoMarkerOverlap } from "../../utils/tobaccoMapMarker";
import { updateTobacoLocalStorageData } from "../../utils/localStorageUpdate";
import {
     postChangeStoreAddress,
     postCheckAddressByCoord,
     postTobaccoStoreDistanceApi,
     postTobaccoStoreSearchApi,
     registerTobaccoAddressBookmark,
     registerTobaccoStoreBookmark,
     removeTobaccoStoreBookmark,
} from "../../api/store";
import PcTobaccoRightButtons from "../../components/pcTobaccoRightButtons/PcTobaccoRightButtons";
import PcHomeTobaccoFilter from "../../components/pcHomeTobaccoFilter/PcHomeFilter";
import TobaccoStoreDetail from "../../containers/tobaccoStoreDetail";
import NearestTobaccoStoreV2 from "../../components/NearestStore/NearestTobaccoStoreV2";
import PcDirection from "../../components/pcDirection/PcDirection";

import TopMessageAlertPc from "../../components/TopMessage/TopMessageAlertPc";
import MobileTobaccoPlaceChangeModal from "../../components/modal/MobileTobaccoPlaceChangeModal/MobileTobaccoPlaceChangeModal";

const { naver } = window;

const TobaccoPage = ({ sidebarOpen, handleChangeSidebarOpen }) => {
     const { accounts } = useMsal();
     const dispatch = useDispatch();

     const isTabletOrMobile = OnIsTabletOrMobile();

     const { tobaccoSearchStoreList, tobaccoStoreList, tobaccoBookmarkedList } = useSelector(state => state.store);
     const userInfoData = useSelector(state => state.user);

     const map = useRef();
     const markers = useRef([]);

     const directionChangeFlagRef = useRef(false);

     const markerInfoWindowRef = useRef(null);
     const selectedPositionRef = useRef(null);
     const rightClickInfoWindowRef = useRef(null);
     const startMarkerRef = useRef(null);
     const endMarkerRef = useRef(null);
     const changePlaceRef = useRef(null);
     const [directionDrawerOpen, setDirectionDrawerOpen] = useState(false);

     const [tab, setTab] = useState(TAB.LIST);

     const [activeStore, setActiveStore] = useState({
          open: false,
          data: null,
     });

     const [showPlaceChangeMessage, setShowPlaceChangeMessage] = useState(false);
     const [changeNewAddressInfo, setChangeNewAddressInfo] = useState(null);

     const [tobaccoFilterOption, setTobaccoFilterOption] = useState(null);

     const [nearStoreFromActive, setNearStoreFromActive] = useState(null);
     const [findDirectionAddress, setFindDirectionAddress] = useState({ type: null, coordinate: null });

     const [selectedPosition, setSelectedPosition] = useState(null);
     const [selectedPositionInfo, setSelectedPositionInfo] = useState({
          open: false,
          position: null,
          data: null,
          updated: false,
     });

     const [directionStart, setDirectionStart] = useState({
          coordinate: null,
          name: null,
     });
     const [directionEnd, setDirectionEnd] = useState({
          coordinate: null,
          name: null,
     });

     const updateTobaccoStoreList = useCallback((lat, lng, distance, includeClosedWithin) => {
          dispatch(
               postTobaccoStoreDistance({
                    lat,
                    lng,
                    distance,
                    account: accounts[0]["username"],
                    includeClosedWithin,
                    includeSuspendedStore: "Y",
               }),
          );
     }, []);

     useEffect(() => {
          if (accounts) {
               dispatch(getBookmarkList({ account: accounts[0]["username"] }));
          }
     }, [accounts]);

     const handleChangeMoveStoreInfo = () => {
          directionChangeFlagRef.current = true;
          setShowPlaceChangeMessage(true);
     };

     const setCircle = useCallback(
          (y, x) => {
               Circle.setCenter(naver.maps.LatLng(y, x));
               Circle.setRadius(tobaccoFilterOption?.circleDistance);
               Circle.setMap(map.current);
          },
          [tobaccoFilterOption?.circleDistance, map],
     );

     const onClickStore = useCallback(
          store => {
               dispatch(
                    postTobaccoStoreInfo({
                         account: accounts[0]["username"],
                         managementNumber: store.managementNumber,
                    }),
               );

               const { circleDistance, includeClosedWithin } = tobaccoFilterOption;

               updateTobaccoStoreList(store.ylatitude, store.xlongitude, circleDistance, includeClosedWithin);

               setActiveStore({ open: true, data: store });
               setCircle(store.ylatitude, store.xlongitude);
               map.current.setCenter(naver.maps.LatLng(store.ylatitude, store.xlongitude - 0.0009));
          },
          [dispatch, accounts, tobaccoFilterOption?.distance, setCircle],
     );

     useEffect(() => {
          const tobaccoMapFilterData = localStorage.getItem(TOBACCO_MAP_CONTROLLER);

          if (!tobaccoMapFilterData) {
               localStorage.setItem(TOBACCO_MAP_CONTROLLER, JSON.stringify(INITIAL_TOBACCO_MAP_FILTER));
               setTobaccoFilterOption(INITIAL_TOBACCO_MAP_FILTER);
          } else {
               try {
                    const parsedTobaccoMapFilterData = JSON.parse(tobaccoMapFilterData);
                    setTobaccoFilterOption(parsedTobaccoMapFilterData);
               } catch (error) {
                    console.error("Failed to parse tobaccoMapFilterData:", error);
                    localStorage.setItem(TOBACCO_MAP_CONTROLLER, JSON.stringify(INITIAL_TOBACCO_MAP_FILTER));
                    setTobaccoFilterOption(INITIAL_TOBACCO_MAP_FILTER);
               }
          }
     }, []);

     useEffect(() => {
          handleStartMarkerDelete(startMarkerRef, markerInfoWindowRef);

          if (directionStart.isPlace && directionStart.coordinate?.x && directionStart.coordinate?.y && !startMarkerRef.current) {
               const startMarker = new naver.maps.Marker({
                    position: directionStart.coordinate,
                    map: map.current,
                    icon: getStartMarkerIcon(),
               });
               startMarkerRef.current = startMarker;
          }
          if (directionStart?.name) {
               setDirectionDrawerOpen(true);
          }
     }, [directionStart]);

     useEffect(() => {
          handleEndMarkerDelete(endMarkerRef, markerInfoWindowRef);

          if (directionEnd.isPlace && directionEnd.coordinate?.x && directionEnd.coordinate?.y && !endMarkerRef.current) {
               handleEndMarkerDelete(endMarkerRef, markerInfoWindowRef);
               const endMarker = new naver.maps.Marker({
                    position: directionEnd.coordinate,
                    map: map.current,
                    icon: getEndMarkerIcon(),
               });
               endMarkerRef.current = endMarker;
          }
          if (directionEnd?.name) {
               setDirectionDrawerOpen(true);
          }
     }, [directionEnd]);

     useEffect(() => {
          const tobaccoFilterData = localStorage.getItem(TOBACCO_MAP_CONTROLLER);
          const parsedTobaccoFilterData = JSON.parse(tobaccoFilterData);

          const { distance, includeClosedWithin } = parsedTobaccoFilterData;

          map.current = new naver.maps.Map("map", {
               center: new naver.maps.LatLng(INITIAL_LAT, INITIAL_LNG),
               zoom: 18,
          });

          if (navigator?.geolocation) {
               navigator.geolocation.getCurrentPosition(
                    position => {
                         const golocationPosition = new naver.maps.LatLng(position.coords.latitude, position.coords.longitude);
                         map.current.setCenter(golocationPosition);

                         updateTobaccoStoreList(position.coords.latitude, position.coords.longitude, distance, includeClosedWithin);
                    },
                    error => {
                         console.log("위치 정보 미동의");

                         updateTobaccoStoreList(INITIAL_LAT, INITIAL_LNG, distance, includeClosedWithin);

                         console.error(error);
                    },
                    {
                         enableHighAccuracy: false,
                         maximumAge: 0,
                         timeout: Infinity,
                    },
               );
          } else {
               console.error("GPS를 지원하지 않습니다");
               updateTobaccoStoreList(INITIAL_LAT, INITIAL_LNG, distance, includeClosedWithin);
          }
     }, [accounts, dispatch]);

     useEffect(() => {
          if (tobaccoStoreList) {
               if (markers.current.length > 0) {
                    markers.current.forEach(marker => {
                         marker["marker"].setMap(null);
                    });
                    markers.current = [];
               }

               let markerList = [...tobaccoStoreList];

               if (tobaccoFilterOption?.showFavorite) {
                    markerList = markerList.filter(el => {
                         return el.isBookmarked === "Y";
                    });
               } else {
                    if (!tobaccoFilterOption?.showOpenStore) {
                         markerList = markerList.filter(el => {
                              return el.businessStatusCode !== "01";
                         });
                    }

                    if (!tobaccoFilterOption?.showSuspendedStore) {
                         markerList = markerList.filter(el => {
                              return el.businessStatusCode !== "02";
                         });
                    }
               }

               const overlap = tobaccoMarkerOverlap(markerList, map);

               overlap.forEach(store => {
                    const content = tobaccoMarkerImage(store, activeStore);

                    const marker = new naver.maps.Marker({
                         map: map.current,
                         position: store.coord,
                         icon: {
                              content: content,
                              size: new naver.maps.Size(30, 30),
                              anchor: new naver.maps.Point(20, 45),
                         },
                    });

                    markers.current = [...markers.current, { ...store, marker }];

                    if (marker) {
                         const { mouseOverHandler, mouseOutHandler, clickHandler } = createMarkerEventHandlers(
                              marker,
                              store,
                              onClickStore,

                              deleteCircle,
                              map,
                              activeStore,
                         );
                    }
               });
          }
     }, [
          tobaccoStoreList,
          tobaccoFilterOption?.showFavorite,
          tobaccoFilterOption?.showOpenStore,
          tobaccoFilterOption?.showSuspendedStore,
          tobaccoFilterOption?.distance,
          tobaccoFilterOption?.shutdownInclude,
          tobaccoFilterOption?.shutdownPeriod,
          activeStore,
          onClickStore,
     ]);

     const onClickStoreDetailClose = () => {
          // onClickStopPositionMove();
          setNearStoreFromActive(null);
          if (activeStore.data && activeStore.open) {
               const { xlongitude, ylatitude } = activeStore.data;
               const { distance, includeClosedWithin } = tobaccoFilterOption;

               setActiveStore({ open: false, data: null });
               deleteCircle();
               // setStorePositionChangeModalOpen(false);
               setShowPlaceChangeMessage(false);

               directionChangeFlagRef.current = null;
               changePlaceRef.current = null;

               updateTobaccoStoreList(ylatitude, xlongitude, distance, includeClosedWithin);

               dispatch(tobaccoStoreDetailReset());

               // if (activeStore?.data && activeStore.data.managementNumber) {
               //      const marker = document.getElementById(`${activeStore.data.managementNumber}`);

               //      marker && marker.parentElement.classList.remove("active");
               // }
          }
     };

     const onChangeZoom = useCallback(
          e => {
               setNearStoreFromActive(null);
               if (e <= 10) {
                    if (markers.current.length > 0) {
                         markers.current.forEach(marker => {
                              marker["marker"].setMap(null);
                         });
                         markers.current = [];
                    }
                    onClickStoreDetailClose();
               } else if (!Circle.getMap()) {
                    const position = map.current.getCenter();

                    const { distance, includeClosedWithin } = tobaccoFilterOption;
                    updateTobaccoStoreList(position.y, position.x, distance, includeClosedWithin);
               }
          },
          [accounts, dispatch, tobaccoFilterOption?.distance, onClickStoreDetailClose],
     );

     const onChangeDragend = useCallback(
          e => {
               if (map.current.getZoom() > 10 && !Circle.getMap()) {
                    const position = map.current.getCenter();

                    updateTobaccoStoreList(position.y, position.x, tobaccoFilterOption?.distance ?? DISTANCE_500, tobaccoFilterOption?.includeClosedWithin);
               }
          },
          [accounts, dispatch, tobaccoFilterOption?.includeClosedWithin, tobaccoFilterOption?.distance],
     );

     const onChangeSize = e => {
          setNearStoreFromActive(null);
          map.current.autoResize();
     };

     const onDragStart = e => {
          setNearStoreFromActive(null);
     };

     const deleteCircle = (y, x) => {
          Circle.setMap(null);
     };

     useEffect(() => {
          const clickEvent = handleMapClick(rightClickInfoWindowRef, map, directionChangeFlagRef, changePlaceRef, setModalOpen);
          const rightClickEvent = handleTobaccoRightClick(rightClickInfoWindowRef, map, setFindDirectionAddress, setSelectedPosition);

          const dragStartEvent = naver.maps.Event.addListener(map.current, "dragstart", onDragStart);
          const centerEvent = naver.maps.Event.addListener(map.current, "dragend", onChangeDragend);
          const zoomEvent = naver.maps.Event.addListener(map.current, "zoom_changed", onChangeZoom);
          const sizeEvent = naver.maps.Event.addListener(map.current, "size_changed", onChangeSize);

          return () => {
               naver.maps.Event.removeListener(clickEvent);
               naver.maps.Event.removeListener(rightClickEvent);
               naver.maps.Event.removeListener(centerEvent);
               naver.maps.Event.removeListener(zoomEvent);
               naver.maps.Event.removeListener(sizeEvent);
               naver.maps.Event.removeListener(dragStartEvent);
          };
     }, [accounts, tobaccoFilterOption?.distance, tobaccoFilterOption?.circleDistance, onChangeDragend, onChangeZoom]);

     // useEffect(() => {
     //      map.current.autoResize();
     // }, [activeStore.open]);

     const onClickDistanceButton = distance => () => {
          if (!activeStore?.open) {
               const position = map.current.getCenter();

               updateTobaccoStoreList(position.y, position.x, distance, tobaccoFilterOption?.includeClosedWithin);
          }

          setTobaccoFilterOption(prev => ({
               ...prev,
               distance,
          }));

          updateTobacoLocalStorageData({ distance });
     };

     const onClickCircleDistanceButton = circleDistance => () => {
          if (activeStore?.open) {
               const position = Circle.getCenter();
               Circle.setRadius(circleDistance);
               updateTobaccoStoreList(position.y, position.x, circleDistance, tobaccoFilterOption?.includeClosedWithin);
          }

          setTobaccoFilterOption(prev => ({
               ...prev,
               circleDistance,
          }));

          updateTobacoLocalStorageData({ circleDistance });
     };

     const handleCheckIncludeShutDownStore = e => {
          const target = e.target.id;
          const isChecked = e.target.checked;

          let closeMonth = 0;

          if (target === "shutDown" && isChecked) {
               closeMonth = 3;
          }
          if (target === "sixMonths") {
               if (isChecked) {
                    closeMonth = 6;
               } else {
                    closeMonth = 3;
               }
          }

          const position = map.current.getCenter();
          setTobaccoFilterOption(prev => ({
               ...prev,
               includeClosedWithin: closeMonth,
          }));

          updateTobaccoStoreList(position.y, position.x, tobaccoFilterOption?.distance, closeMonth);

          updateTobacoLocalStorageData({ includeClosedWithin: closeMonth });
     };

     const onClickChangeFilterOption = key => {
          const newValue = {};

          if (key === "open") {
               newValue.showOpenStore = !tobaccoFilterOption?.showOpenStore;
          }
          if (key === "suspended") {
               newValue.showSuspendedStore = !tobaccoFilterOption?.showSuspendedStore;
          }
          if (key === "closed3") {
               newValue.includeClosedWithin = tobaccoFilterOption?.includeClosedWithin > 0 ? 0 : 3;
          }

          if (tobaccoFilterOption.showFavorite) {
               newValue.showFavorite = false;
          }

          const updatedFilterOption = {
               ...tobaccoFilterOption,
               ...newValue,
          };

          const position = map.current.getCenter();

          setTobaccoFilterOption(updatedFilterOption);

          updateTobacoLocalStorageData(updatedFilterOption);
          updateTobaccoStoreList(position.y, position.x, updatedFilterOption.distance, updatedFilterOption.includeClosedWithin);
     };

     const onClickFavorite = () => {
          const newValue = {};
          newValue.showFavorite = !tobaccoFilterOption?.showFavorite;
          const position = map.current.getCenter();
          setTobaccoFilterOption(prev => ({
               ...prev,
               ...newValue,
          }));

          updateTobacoLocalStorageData({
               ...tobaccoFilterOption,
               ...newValue,
          });

          updateTobaccoStoreList(position.y, position.x, tobaccoFilterOption?.distance, tobaccoFilterOption.includeClosedWithin);
     };

     useEffect(() => {
          if (userInfoData?.kor_nm == null) {
               dispatch(
                    userInfo({
                         account: accounts[0]["username"],
                         kor_nm: accounts[0]["name"],
                    }),
               );
          }
     }, [accounts, dispatch, userInfoData]);

     const handleChangeTab = tab => setTab(tab);

     const onChangeSideBar = () => {
          handleChangeSidebarOpen(prev => !prev);
          const timeOut = setTimeout(() => {
               map.current.autoResize();
               clearTimeout(timeOut);
          }, 350);
     };

     const handleCloseSelectedPositionInfo = () => {
          setSelectedPositionInfo({ open: false, data: null, position: null, updated: false });
          if (selectedPositionRef.current) {
               selectedPositionRef.current?.close();
               selectedPositionRef.current = null;
          }
     };

     useEffect(() => {
          if (userInfoData?.kor_nm == null) {
               dispatch(
                    userInfo({
                         account: accounts[0]["username"],
                         kor_nm: accounts[0]["name"],
                    }),
               );
          }
     }, [accounts, dispatch, userInfoData]);

     const getAddressByCoords = async (lng, lat) => {
          try {
               const newAddrData = await postCheckAddressByCoord({
                    lng,
                    lat,
                    account: accounts[0]["username"],
               });

               return newAddrData.data;
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
               return null;
          }
     };

     const handleCloseActiveStore = () => {
          // onClickStopPositionMove();
          setNearStoreFromActive(null);
          const { xlongitude, ylatitude } = activeStore.data;
          const { distance, includeClosedWithin } = tobaccoFilterOption;
          setActiveStore({
               open: false,
               data: null,
          });
          deleteCircle();
          // setStorePositionChangeModalOpen(false);
          setShowPlaceChangeMessage(false);

          directionChangeFlagRef.current = null;
          changePlaceRef.current = null;

          updateTobaccoStoreList(ylatitude, xlongitude, distance, includeClosedWithin);
          dispatch(tobaccoStoreDetailReset());
          // if (activeStore?.data?.managementNumber) {
          //      const marker = document.getElementById(`${activeStore.data.managementNumber}`);
          //      marker && marker.parentElement.classList.remove("active");
          // }
     };

     const [modalOpen, setModalOpen] = useState(false);

     useEffect(() => {
          if (activeStore.data?.managementNumber) {
               setTimeout(() => {
                    const marker = document.getElementById(`${activeStore.data.managementNumber}`);
                    marker && marker.parentElement.classList.add("active");

                    const rect = marker?.getBoundingClientRect();
                    const currentStoreInfoFromMarker = markers.current.find(el => el.managementNumber === activeStore.data.managementNumber);

                    if (currentStoreInfoFromMarker && currentStoreInfoFromMarker.child.length > 0) {
                         setNearStoreFromActive({
                              stores: currentStoreInfoFromMarker.child,
                              position: {
                                   bottom: rect.bottom,
                                   left: rect.left,
                              },
                         });
                    } else {
                         setNearStoreFromActive(null);
                    }
               }, 800);
          }
     }, [activeStore]);

     const [reopen, setReopen] = useState(null);

     const addressChange = async () => {
          const username = accounts[0].name;
          const account = accounts[0].username;
          const storeData = activeStore.data;

          const requestParam = {
               username,
               account,
               managementNumber: storeData.managementNumber,
               ...changeNewAddressInfo.data,
               lng: changePlaceRef.current.x,
               lat: changePlaceRef.current.y,
          };

          try {
               const done = await postChangeStoreAddress(requestParam);

               if (done.data.resStatus === "success") {
                    setModalOpen(false);
                    setShowPlaceChangeMessage(false);

                    setChangeNewAddressInfo(null);

                    changePlaceRef.current = null;

                    const beforeActiveStore = activeStore.data;

                    const marker = document.getElementById(`${activeStore.data.managementNumber}`);
                    marker && marker.parentElement.classList.remove("active");

                    setActiveStore({ open: false, data: null });
                    deleteCircle();
                    setTimeout(() => {
                         setReopen(beforeActiveStore);
                    }, 300);
               }
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
          }
     };

     useEffect(() => {
          if (reopen !== null) {
               try {
                    postTobaccoStoreSearchApi({
                         storeName: reopen.businessName,
                         account: accounts[0].username,
                         includeClosedWithin: tobaccoFilterOption?.includeClosedWithin,
                         includeSuspendedStore: "Y",
                    }).then(data => {
                         const getBeforeStore = data.data?.data.find(el => el.managementNumber === reopen.managementNumber);
                         if (getBeforeStore) {
                              setTimeout(() => {
                                   onClickStore(getBeforeStore);
                                   setReopen(null);
                              }, 500);
                         }
                    });
               } catch (e) {
                    console.log("error!");
               }
          }
     }, [reopen]);

     useEffect(() => {
          if (modalOpen && changePlaceRef.current) {
               getAddressByCoords(changePlaceRef.current.x, changePlaceRef.current.y).then(data => setChangeNewAddressInfo(data));
          }
     }, [modalOpen]);

     const registerBookmark = async managementNumber => {
          try {
               const registerBookmarkRequest = await registerTobaccoStoreBookmark({
                    username: accounts[0].name,
                    account: accounts[0]["username"],
                    managementNumber,
               });

               return registerBookmarkRequest.data;
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
               return null;
          }
     };

     const handleRemoveBookmarkedStore = bookmarkInfo => {
          removeBookmark(bookmarkInfo.bookmarkId).then(() => {
               dispatch(getBookmarkList({ account: accounts[0]["username"] }));

               const position = map.current.getCenter();

               const { distance, includeClosedWithin } = tobaccoFilterOption;
               updateTobaccoStoreList(position.y, position.x, distance, includeClosedWithin);

               onClickStoreDetailClose();
          });
     };

     const registerBookmarkByAddress = async (fullRoadAddress, fullLocationAddress, lng, lat) => {
          try {
               const registerBookmarkRequest = await registerTobaccoAddressBookmark({
                    username: accounts[0].name,
                    account: accounts[0]["username"],
                    fullRoadAddress,
                    fullLocationAddress,
                    lng,
                    lat,
               });

               return registerBookmarkRequest.data;
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
               return null;
          }
     };

     const removeBookmark = async bookmarkId => {
          try {
               const registerBookmarkRequest = await removeTobaccoStoreBookmark({
                    account: accounts[0]["username"],
                    bookmarkId,
               });

               return registerBookmarkRequest.data;
          } catch (error) {
               console.error("검색 API 호출 중 오류 발생:", error);
               return null;
          }
     };

     const selectBookmarkItem = bookmarkItem => {
          if (bookmarkItem.bookmarkType === "STORE") {
               searchBookmarkStore(bookmarkItem);
               return;
          }
          searchBookmarkAddress(bookmarkItem);
     };

     const searchBookmarkStore = async bookmarkItem => {
          try {
               postTobaccoStoreSearchApi({
                    storeName: bookmarkItem.businessName,
                    account: accounts[0].username,
                    includeClosedWithin: 6,
                    includeSuspendedStore: "Y",
               }).then(data => {
                    const storeList = data.data.data;
                    const selectedStore = storeList.find(el => el.managementNumber === bookmarkItem.managementNumber);

                    if (selectedStore) {
                         onClickStore(selectedStore);
                    }
               });
          } catch (e) {}
     };

     const searchBookmarkAddress = async bookmarkItem => {
          try {
               const findBookmark = tobaccoBookmarkedList.find(el => el.bookmarkId === bookmarkItem.bookmarkId);

               postTobaccoStoreDistanceApi({
                    account: accounts[0].username,
                    lat: findBookmark.ylatitude,
                    lng: findBookmark.xlongitude,
                    distance: tobaccoFilterOption.distance,
                    includeClosedWithin: 0,
                    includeSuspendedStore: "Y",
               }).then(data => {
                    const searchData = data.data.data;
                    const findStore = searchData.find(el => el.bookmarkId === findBookmark.bookmarkId);

                    if (findStore) {
                         onClickStore(findStore);
                    }
               });
          } catch (e) {}
     };

     const updateFromFavoriteStore = useCallback(
          store => {
               dispatch(
                    postTobaccoStoreInfo({
                         account: accounts[0]["username"],
                         managementNumber: store.managementNumber,
                    }),
               );
               const { circleDistance, includeClosedWithin } = tobaccoFilterOption;

               updateTobaccoStoreList(store.ylatitude, store.xlongitude, circleDistance, includeClosedWithin);
               setActiveStore({ open: true, data: { ...store, isBookmarked: store.isBookmarked === "Y" ? "N" : "Y" } });
          },
          [dispatch, accounts, tobaccoFilterOption],
     );

     const handleClickFavoriteBtn = store => {
          if (store.bookmarkId && !store.businessStatusCode) {
               removeBookmark(store.bookmarkId).then(() => {
                    dispatch(getBookmarkList({ account: accounts[0]["username"] }));
                    handleCloseActiveStore();
               });

               return;
          }

          const findStoreFromList = tobaccoBookmarkedList.find(el => el.managementNumber === store.managementNumber);

          if (findStoreFromList?.bookmarkId && findStoreFromList?.managementNumber) {
               removeBookmark(findStoreFromList.bookmarkId)
                    .then(() => {
                         updateFromFavoriteStore(store);
                    })
                    .finally(() => {
                         dispatch(getBookmarkList({ account: accounts[0]["username"] }));
                    });
          } else {
               registerBookmark(store.managementNumber)
                    .then(data => {
                         if (data.resStatus === "success") {
                              updateFromFavoriteStore(store);
                         }
                    })
                    .finally(() => {
                         dispatch(getBookmarkList({ account: accounts[0]["username"] }));
                    });
          }
     };

     const handleClickSetDirectionWithPoint = async (type, coord) => {
          try {
               const data = await getAddressByCoords(coord.x, coord.y);
               const addressData = data.data;

               const address = addressData.fullRoadAddress ?? addressData.fullLocationAddress ?? "";

               if (type === "START") {
                    setDirectionStart({ coordinate: { x: coord.x, y: coord.y }, name: address, isPlace: true });
                    setDirectionDrawerOpen(true);
               }

               if (type === "END") {
                    setDirectionEnd({ coordinate: { x: coord.x, y: coord.y }, name: address, isPlace: true });
                    setDirectionDrawerOpen(true);
               }
               // handleClickCloseDirectionWithPoint();
          } catch {
               // handleClickCloseDirectionWithPoint();
          }
     };

     useEffect(() => {
          if (findDirectionAddress.type) {
               handleClickSetDirectionWithPoint(findDirectionAddress.type, findDirectionAddress.coordinate);
          }
     }, [findDirectionAddress]);

     const onClickStopPositionMove = () => {
          setShowPlaceChangeMessage(false);
          directionChangeFlagRef.current = null;
     };

     const handleClickAddressBookmark = locationInfo => {
          registerBookmarkByAddress(locationInfo.fullRoadAddress, locationInfo.fullLocationAddress, selectedPosition.x, selectedPosition.y).then(() => {
               setSelectedPositionInfo({ data: locationInfo, position: selectedPosition, open: false, updated: true });
               setSelectedPosition(null);
          });
     };

     useEffect(() => {
          if (selectedPosition) {
               getAddressByCoords(selectedPosition.x, selectedPosition.y).then(data => {
                    const addressInfo = data.data;
                    handleClickAddressBookmark(addressInfo);
                    rightClickInfoWindowRef?.current?.close();
                    rightClickInfoWindowRef.current = null;
                    map.current.setCenter(naver.maps.LatLng(selectedPosition.y, selectedPosition.x));
                    updateTobaccoStoreList(selectedPosition.y, selectedPosition.x, tobaccoFilterOption.circleDistance, tobaccoFilterOption.includeClosedWithin);
               });
          }
     }, [selectedPosition, tobaccoFilterOption]);

     useEffect(() => {
          if (!tobaccoStoreList || !selectedPositionInfo.updated) return;

          const findStore = tobaccoStoreList.find(el => el.ylatitude === selectedPositionInfo.position.y && el.xlongitude === selectedPositionInfo.position.x);

          if (findStore) {
               onClickStore(findStore);
               handleCloseSelectedPositionInfo();

               setSelectedPosition(null);
               setSelectedPositionInfo({ data: null, position: null, open: false, updated: false });
               return;
          }
     }, [selectedPositionInfo, tobaccoStoreList, onClickStore]);

     useEffect(() => {
          if (selectedPositionInfo.updated) {
               dispatch(getBookmarkList({ account: accounts[0]["username"] }));
          }
     }, [selectedPositionInfo]);

     return (
          <>
               {showPlaceChangeMessage && (
                    <TopMessageAlertPc
                         sidebarOpen={sidebarOpen}
                         message={"변경을 원하는 위치로 이동 후 클릭해 주세요\n정확도를 위해 변경된 최종 주소를 꼭 확인하세요"}
                         onClose={onClickStopPositionMove}
                    />
               )}

               <MobileTobaccoPlaceChangeModal
                    activeStore={activeStore}
                    open={modalOpen}
                    onClose={() => {
                         setModalOpen(false);
                         setChangeNewAddressInfo(null);
                    }}
                    addressChange={addressChange}
                    changeNewAddressInfo={changeNewAddressInfo}
               />

               {!activeStore.open && (
                    <PcHomeTobaccoFilter
                         onClickFavorite={onClickFavorite}
                         sidebarOpen={sidebarOpen}
                         tobaccoFilterOption={tobaccoFilterOption}
                         onClickChangeFilterOption={onClickChangeFilterOption}
                    />
               )}

               <PcTobaccoRightButtons
                    handleChangeDirectionStatus={() => setDirectionDrawerOpen(prev => !prev)}
                    updateTobaccoStoreList={updateTobaccoStoreList}
                    onClickDistanceButton={onClickDistanceButton}
                    onClickCircleDistanceButton={onClickCircleDistanceButton}
                    map={map}
                    filterOption={tobaccoFilterOption}
               />
               <TobaccoHomeWrap isTabletOrMobile={isTabletOrMobile}>
                    <div className="left-container">
                         <PcSidebar open={sidebarOpen} onChangeSideBar={onChangeSideBar}>
                              <TobaccoSidebarContents
                                   activeStore={activeStore}
                                   map={map}
                                   tab={tab}
                                   account={accounts[0]["username"]}
                                   tobaccoSearchStoreList={tobaccoSearchStoreList}
                                   tobaccoStoreList={tobaccoStoreList}
                                   onClickStore={onClickStore}
                                   handleChangeTab={handleChangeTab}
                                   tobaccoFilterOption={tobaccoFilterOption}
                                   handleRemoveBookmarkedStore={handleRemoveBookmarkedStore}
                                   selectBookmarkItem={selectBookmarkItem}
                              />
                         </PcSidebar>

                         {activeStore?.open && !isTabletOrMobile && (
                              <TobaccoStoreDetail
                                   activeStore={activeStore.data}
                                   sidebarOpen={sidebarOpen}
                                   setDirectionStart={setDirectionStart}
                                   setDirectionEnd={setDirectionEnd}
                                   close={onClickStoreDetailClose}
                                   handleClickFavoriteBtn={handleClickFavoriteBtn}
                                   handleChangeMoveStoreInfo={handleChangeMoveStoreInfo}
                              />
                         )}
                    </div>
                    <div id="map" style={{ width: "100%", height: "100%" }} />

                    {directionDrawerOpen && (
                         <PcDirection
                              directionStart={directionStart}
                              directionEnd={directionEnd}
                              setDirectionStart={setDirectionStart}
                              setDirectionEnd={setDirectionEnd}
                         />
                    )}

                    {nearStoreFromActive && <NearestTobaccoStoreV2 nearStoreFromActive={nearStoreFromActive} onClickStore={onClickStore} />}
               </TobaccoHomeWrap>
          </>
     );
};

export default TobaccoPage;
