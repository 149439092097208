import { SetStateAction, useEffect, useState } from "react";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "../../auth/authConfig";

const msalInstance = new PublicClientApplication(msalConfig);

interface IUserPhoto {
     size?: number;
}

const UserPhoto = ({ size = 45 }: IUserPhoto) => {
     const [photoUrl, setPhotoUrl] = useState(null);
     const [loading, setLoading] = useState(true);
     const [error, setError] = useState(null);

     useEffect(() => {
          const account = msalInstance.getAllAccounts()[0];

          async function fetchUserPhoto() {
               try {
                    const tokenResponse = await msalInstance.acquireTokenSilent({
                         scopes: ["User.Read"],
                         account: account,
                    });

                    setLoading(true);
                    const response = await fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
                         headers: {
                              Authorization: `Bearer ${tokenResponse.accessToken}`, // accessToken은 인증 후 받은 토큰
                         },
                    });

                    if (!response.ok) throw new Error("Failed to fetch photo");

                    const blob = await response.blob();
                    const imageUrl = URL.createObjectURL(blob);

                    setPhotoUrl(imageUrl as unknown as SetStateAction<null>);
               } catch (err: unknown) {
                    if (err instanceof Error) setError(err.message as unknown as SetStateAction<null>);
                    else setError("An unknown error occurred" as unknown as SetStateAction<null>);
               } finally {
                    setLoading(false);
               }
          }

          fetchUserPhoto();

          // cleanup function
          return () => {
               if (photoUrl) URL.revokeObjectURL(photoUrl);
          };
     }, []);

     if (loading) return <img src={"img/user-large.png"} alt="user-large" width={size} />;
     if (error) return <img src={"img/user-large.png"} alt="user-large" width={size} />;

     return (
          <div>
               {photoUrl ? (
                    <img
                         src={photoUrl}
                         alt="User profile"
                         style={{
                              width: size,
                              height: size,
                              borderRadius: "50%",
                              border: "1px solid #505050",
                              objectFit: "cover",
                         }}
                    />
               ) : (
                    <img src={"img/user-large.png"} alt="user-large" width={40} />
               )}
          </div>
     );
};

export default UserPhoto;
