const IconStore = ({ active = false }) => {
     const color = active ? "#3871E0" : "#000000";

     return (
          <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
               <mask id="mask0_109_1128" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="22">
                    <rect x="0.666504" width="22" height="22" fill="#D9D9D9" />
               </mask>
               <g mask="url(#mask0_109_1128)">
                    <path
                         d="M11.6663 11C12.1705 11 12.6021 10.8205 12.9611 10.4615C13.3202 10.1025 13.4997 9.67087 13.4997 9.16671C13.4997 8.66254 13.3202 8.23094 12.9611 7.87192C12.6021 7.51289 12.1705 7.33337 11.6663 7.33337C11.1622 7.33337 10.7306 7.51289 10.3715 7.87192C10.0125 8.23094 9.83301 8.66254 9.83301 9.16671C9.83301 9.67087 10.0125 10.1025 10.3715 10.4615C10.7306 10.8205 11.1622 11 11.6663 11ZM11.6663 20.1667C9.20662 18.0737 7.36947 16.1296 6.15488 14.3344C4.9403 12.5393 4.33301 10.8778 4.33301 9.35004C4.33301 7.05837 5.07016 5.23268 6.54447 3.87296C8.01877 2.51324 9.72606 1.83337 11.6663 1.83337C13.6066 1.83337 15.3139 2.51324 16.7882 3.87296C18.2625 5.23268 18.9997 7.05837 18.9997 9.35004C18.9997 10.8778 18.3924 12.5393 17.1778 14.3344C15.9632 16.1296 14.1261 18.0737 11.6663 20.1667Z"
                         fill={color}
                    />
               </g>
          </svg>
     );
};

export default IconStore;
