import { SearchWrap } from "./MobileSearh.styled";
import NavigateBeforeIcon from "@mui/icons-material/ArrowBack";
import { ReactComponent as Search } from "../../assets/search.svg";
import { RiPushpin2Fill } from "react-icons/ri";
import { useSelector } from "react-redux";

const MobileTobaccoSearch = ({ onClickClose, listEl, onClickStoreName, onSearch, searchValue, onChangeSearchValue }) => {
     const { tobaccoSearchStoreList, tobaccoStoreList } = useSelector(state => state.store);
     return (
          <SearchWrap>
               <div className="search-header">
                    <div className="search-header-contents">
                         <button className="search-header-back" onClick={onClickClose}>
                              <NavigateBeforeIcon fontSize="large" sx={{ paddingRight: 2, width: 24, height: 24 }} />
                         </button>
                         <div className="search-input-wrap">
                              <input
                                   placeholder="점포명, 주소 검색"
                                   value={searchValue}
                                   onChange={onChangeSearchValue}
                                   onKeyDown={e => {
                                        if (e.key === "Enter") {
                                             onSearch()();
                                        }
                                   }}
                              />
                         </div>
                         <button onClick={onSearch()}>
                              <Search />
                         </button>
                    </div>
               </div>

               <ul id="search-list" className="section" ref={listEl}>
                    {(tobaccoSearchStoreList ? tobaccoSearchStoreList : tobaccoStoreList)?.map((item, index) => (
                         <li
                              key={"search-item" + item.id + index}
                              className="search-item"
                              onClick={() => {
                                   onClickStoreName(item);
                              }}
                         >
                              <div className="search-item-title">
                                   <h2>{item?.businessName}</h2>
                                   {item?.distance && item.distance !== 0 ? (
                                        <span className="dst">{item.distance.toLocaleString()} m</span>
                                   ) : item?.dst && item.dst === 0 ? (
                                        <span className="dst">
                                             <RiPushpin2Fill />
                                        </span>
                                   ) : null}
                              </div>

                              <div className="search-item-sub">
                                   <p className="category">{item?.detailedBusinessStatusName}</p>
                              </div>
                              <p className="address">지번 : {item?.fullLocationAddress}</p>
                              <p className="address">도로명 :{item?.fullRoadAddress}</p>
                         </li>
                    ))}
               </ul>
          </SearchWrap>
     );
};

export default MobileTobaccoSearch;
