import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import storeReducer from '../reducers/store';
import postReducer from '../reducers/post';
import reviewReducer from '../reducers/review';
import userReducer from '../reducers/user';
// import logger from 'redux-logger';

export const store = configureStore({
	reducer: {
		post: postReducer,
		store: storeReducer,
		review: reviewReducer,
		user: userReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}).concat(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
