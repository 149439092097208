import styled from "@emotion/styled";
import Modal from "..";

const MobileTobaccoPlaceChangeModal = ({ activeStore, open, onClose, addressChange, changeNewAddressInfo }) => {
     if (!changeNewAddressInfo?.data) {
          return null;
     }

     const changeAddress = changeNewAddressInfo?.data?.fullRoadAddress ?? changeNewAddressInfo?.data?.fullLocationAddress ?? "";

     return (
          <Modal open={open} onClose={onClose} noHeader headCloseButton={false} width={240}>
               <ModalContents>
                    <div className="text-wrap">
                         <div className="change-title">{activeStore?.data?.businessName}</div>
                         <div className="address">{changeAddress}</div>
                         <div className="address bold">주소로 변경할까요?</div>
                    </div>

                    <div className="btns-wrap">
                         <div className="cancel-btn" onClick={onClose}>
                              취소
                         </div>
                         <div className="change-btn" onClick={addressChange}>
                              변경
                         </div>
                    </div>
               </ModalContents>
          </Modal>
     );
};

export default MobileTobaccoPlaceChangeModal;

const ModalContents = styled.div`
     box-sizing: border-box;
     width: 240px;
     height: 197px;
     padding: 20px;
     display: flex;
     flex-direction: column;
     justify-content: space-between;

     gap: 25px;
     .text-wrap {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
     }
     .change-title {
          text-align: center;
          color: #3d4043;
          text-align: center;

          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px; /* 133.333% */
          letter-spacing: 0.12px;
     }

     .address {
          color: #71757a;
          text-align: center;

          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 160% */
     }

     .bold {
          font-weight: 700;
     }

     .btns-wrap {
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.12px;
          display: flex;
          justify-content: space-between;

          > div {
               cursor: pointer;
               width: 95px;
               height: 44px;
               display: flex;
               justify-content: center;
               align-items: center;
               border-radius: 8px;
          }
     }

     .cancel-btn {
          color: #3d4043;
          background: #f4f4f4;
          border: 1px solid #dadce0;
     }

     .change-btn {
          font-size: 14px;
          cursor: pointer;
          color: #fff;
          background: #3d4043;
     }
`;
