import { useState, useEffect, useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import styled from 'styled-components';
// hook

type SnackbarProps = {
	message: string;
	ms?: number;
};

const Snackbar = ({ ms = 2.5, message }: SnackbarProps) => {
	const [snackbarMessage, setSnackbarMessage] = useState<string>('');
	const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

	useEffect(() => {
		setSnackbarMessage(message);
	}, [message]);

	useUpdateEffect(() => {
		if (timer.current) clearTimeout(timer.current);
		timer.current = setTimeout(() => {
			setSnackbarMessage('');
		}, ms * 1000);
	}, [message]);

	useEffect(() => {
		return () => {
			clearTimeout(timer.current as ReturnType<typeof setTimeout>);
			setSnackbarMessage('');
		};
	}, []);

	if (snackbarMessage) {
		return (
			<SnackbarWrap>
				<div>{snackbarMessage}</div>
			</SnackbarWrap>
		);
	} else {
		return null;
	}
};

const SnackbarWrap = styled.div`
	position: fixed;
	top: 50px;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 10000;
	padding: 12px 18px 12px 16px;
	background: rgba(30, 37, 48, 0.8);
	box-shadow: 0px 2px 4px rgba(6, 6, 6, 0.08);
	border-radius: 8px;
	color: #fff;
`;

export default Snackbar;
