import { isStoreOverlap } from "./validator";

const { naver } = window;

export const storeMarkerOverlap = (storeList, map, codeFilter) => {
     const proj = map.current.getProjection();
     const overlap = [];

     storeList?.forEach((store, i) => {
          const coord = new naver.maps.LatLng(store.y, store.x);
          const markerOffset = proj.fromCoordToOffset(coord);

          if (isStoreOverlap(store, codeFilter)) {
               if (overlap.length <= 0) {
                    overlap.push({
                         ...store,
                         coord,
                         offset: markerOffset,
                         child: [],
                    });
               } else {
                    const resultIndex = overlap.findIndex(m => Math.abs(m.offset.x - markerOffset.x) <= 35 && Math.abs(m.offset.y - markerOffset.y) <= 35);

                    if (resultIndex !== -1) {
                         overlap[resultIndex].child.push({ ...store });
                    } else {
                         overlap.push({
                              ...store,
                              coord,
                              offset: markerOffset,
                              child: [],
                         });
                    }
               }
          }
     });

     return overlap;
};

export const createStoreMarkerEventHandlers = (marker, store, onClickStore, deleteCircle, map, activeStore) => {
     const mouseOverHandler = naver.maps.Event.addListener(marker, "mouseover", function (e) {
          marker.eventTarget.classList.add("hover");
     });

     const mouseOutHandler = naver.maps.Event.addListener(marker, "mouseout", () => {
          marker.eventTarget.classList.remove("hover");
     });

     const clickHandler = naver.maps.Event.addListener(marker, "click", e => {
          if (store.child.length <= 0) {
               onClickStore(store);
          } else {
               deleteCircle();

               map.current.setCenter(naver.maps.LatLng(store.y, store.x));

               const markerElement = document.getElementById(`${store.name}`);
               if (activeStore?.data) {
                    markerElement && markerElement.parentElement.classList.remove("active");
               }

               onClickStore(store);
          }
     });

     return {
          mouseOverHandler,
          mouseOutHandler,
          clickHandler,
     };
};
