import { useMediaQuery } from 'react-responsive';

// export const isTabletOrMobile = useMediaQuery({
// 	query: '(max-width: 768px)',
// });
export const OnIsTabletOrMobile = () => {
	return useMediaQuery({
		query: '(max-width: 1024px)',
	});
};

// export const isTabletOrMobile = false;
