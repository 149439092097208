import styled from "@emotion/styled";

interface TobaccoFilterControllerWrap {
     fixed: boolean;
}

export const TobaccoFilterControllerWrap = styled.div<TobaccoFilterControllerWrap>`
     position: ${({ fixed }) => (fixed ? "fixed" : "static")};
     right: 30px;
     top: 30px;
     width: 200px;
     height: 100px;
     background: #ffffff;
     border-radius: 8px;
     box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
`;

export const TobaccoFilterControlTop = styled.div``;
export const TobaccoFilterControlBottom = styled.div`
     display: flex;
`;
