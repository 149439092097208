import axios from "axios";

// const url = 'https://gsr-dt-app-d002.azurewebsites.net';
// const url = 'http://localhost:8080';
const url = "https://gsr-dt-app-d002.azurewebsites.net/";

const NewApi = axios.create({
     baseURL: url,
     withCredentials: true,
});

NewApi.defaults.withCredentials = true;

NewApi.interceptors.request.use(
     function (config) {
          const token = sessionStorage.getItem("idToken");

          if (token) {
               config.headers.Authorization = `Bearer ${token}`;
          }

          return config;
     },
     function (error) {
          return Promise.reject(error);
     },
);

NewApi.interceptors.response.use(
     function (response) {
          return response;
     },
     function (error) {
          return Promise.reject(error);
     },
);

NewApi.defaults.headers.post["Content-Type"] = "application/json";

export default NewApi;
