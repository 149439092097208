import styled from "@emotion/styled";
import { ReactComponent as Close } from "../../assets/close.svg";
import IconStar from "../../assets/svgComponents/IconStar";
import IconStarFill from "../../assets/svgComponents/IconStarFill";
import { useSelector } from "react-redux";
import { useMemo } from "react";

const MobileActiveTobaccoStore = ({
     directionDrawerOpen,
     handleChangeMoveStoreInfo,
     onClickDetailModeOn,
     activeStore,
     handleCloseActiveStore,
     setDirectionStart,
     setDirectionEnd,
     handleClickFavoriteBtn,
     showPlaceChangeMessage,
     setDirectionDrawerOpen,
}) => {
     const address = activeStore.fullRoadAddress ?? activeStore.fullLocationAddress;
     const { tobaccoBookmarkedList } = useSelector(state => state.store);

     const isStoreSelected = useMemo(() => {
          if (!activeStore || !tobaccoBookmarkedList) return false;

          const storeInfo = tobaccoBookmarkedList.find(el => el.managementNumber === activeStore.managementNumber);

          if (storeInfo) {
               return true;
          } else {
               return false;
          }
     }, [tobaccoBookmarkedList, activeStore]);

     return (
          <MobileActiveStoreInfoWrap directionDrawerOpen={directionDrawerOpen}>
               <div
                    onClick={() => {
                         if (!showPlaceChangeMessage && activeStore.managementNumber) {
                              onClickDetailModeOn(true);
                         }
                    }}
               >
                    <div className="active-store-name">
                         <h2>{activeStore?.businessName}</h2>
                    </div>
                    {activeStore.contactNumber && <p className="active-store-address">연락처: {activeStore.contactNumber && "없음"}</p>}{" "}
                    <p className="active-store-address">{address}</p>
                    <BottomBtnsWrap>
                         {!showPlaceChangeMessage && (
                              <>
                                   <LeftBtns>
                                        <FavoriteBtn
                                             onClick={e => {
                                                  e.stopPropagation();

                                                  handleClickFavoriteBtn(activeStore);
                                             }}
                                             active={isStoreSelected}
                                        >
                                             {isStoreSelected ? <IconStarFill fillColor="#2971EE" /> : <IconStar lineColor="#F1F4F9" />}
                                        </FavoriteBtn>
                                        {activeStore.managementNumber && (
                                             <FavoriteBtn
                                                  onClick={e => {
                                                       e.stopPropagation();
                                                       handleChangeMoveStoreInfo();
                                                  }}
                                                  active={true}
                                             >
                                                  <img src="img/icons/svg/u_edit.svg" alt="fix-position" width={20} />
                                             </FavoriteBtn>
                                        )}
                                   </LeftBtns>

                                   <RightBtns>
                                        <ItemDirection
                                             onClick={e => {
                                                  e.stopPropagation();
                                                  setDirectionStart({
                                                       coordinate: { x: activeStore?.xlongitude, y: activeStore?.ylatitude },
                                                       name: activeStore?.businessName,
                                                       isPlace: false,
                                                  });
                                                  handleCloseActiveStore();
                                                  setDirectionDrawerOpen(true);
                                             }}
                                        >
                                             출발
                                        </ItemDirection>
                                        <ItemDirection
                                             onClick={e => {
                                                  e.stopPropagation();
                                                  setDirectionEnd({
                                                       coordinate: { x: activeStore?.xlongitude, y: activeStore?.ylatitude },
                                                       name: activeStore?.businessName,
                                                       isPlace: false,
                                                  });
                                                  handleCloseActiveStore();
                                                  setDirectionDrawerOpen(true);
                                             }}
                                        >
                                             도착
                                        </ItemDirection>
                                   </RightBtns>
                              </>
                         )}
                    </BottomBtnsWrap>
               </div>

               <button
                    className="active-store-info-close"
                    onClick={e => {
                         e.preventDefault();
                         handleCloseActiveStore();
                    }}
               >
                    <Close />
               </button>
          </MobileActiveStoreInfoWrap>
     );
};

export default MobileActiveTobaccoStore;

const FavoriteBtn = styled.div`
     width: 36px;
     height: 36px;
     border-radius: 50%;
     background-color: ${({ active }) => (active ? "#D6E2FB" : "#B7B7B5")};
     display: flex;
     justify-content: center;
     align-items: center;
`;

const LeftBtns = styled.div`
     display: flex;
     gap: 8px;
`;

const RightBtns = styled.div`
     display: flex;
     gap: 5px;
`;

const BottomBtnsWrap = styled.div`
     display: flex;
     width: 100%;
     padding-top: 10px;
     justify-content: space-between;
     gap: 6px;
     min-height: 44px;
`;
const ItemDirection = styled.div`
     cursor: pointer;
     width: 62px;
     height: 38px;
     display: flex;
     justify-content: center;
     align-items: center;
     border-radius: 999px;
     border: 1px solid #dadce0;
     background: #216cf0;
     box-shadow: 0px 0px 40px 0px rgba(179, 198, 207, 0.5);
     color: #f1f4f9;
     text-align: center;
     -webkit-text-stroke-width: 1;
     -webkit-text-stroke-color: #f1f4f9;
     font-size: 14px;
     font-style: normal;
     font-weight: 500;
     line-height: 12px; /* 100% */
`;

const MobileActiveStoreInfoWrap = styled.div`
     box-sizing: border-box;
     position: absolute;
     bottom: ${({ directionDrawerOpen }) => (directionDrawerOpen ? "220px" : "80px")};
     left: 0;
     width: 100%;
     padding: 0 20px 25px;
     z-index: 500;
     > div {
          box-sizing: border-box;
          width: 100%;
          padding: 16px 14px 18px;
          box-shadow: rgb(0 0 0 / 15%) 3px 3px 15px;
          border-radius: 10px;
          background-color: #fff;

          .active-store-name {
               display: flex;
               align-items: center;
               justify-content: space-between;
               margin-bottom: 8px;
          }

          .active-store-name h2 {
               color: #1e2530;
               font-size: 14px;
               font-style: normal;
               font-weight: 700;
               line-height: 18px; /* 150% */
               letter-spacing: -0.24px;
          }

          .active-store-details {
               display: flex;
               align-items: center;
               color: #71757a;
               font-size: 12px;
               font-style: normal;
               font-weight: 500;
               line-height: 16px; /* 160% */
          }

          .active-store-details p {
               &:nth-of-type(2)::before {
                    display: inline-block;
                    width: 1px;
                    height: 8px;
                    background-color: rgb(226, 226, 226);
                    margin: -1px 6px 1px;
                    vertical-align: -1px;
                    content: "";
               }
          }
     }
     .active-store-address {
          color: #71757a;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 160% */
     }

     .active-store-info-close {
          position: absolute;
          top: 11.5px;
          right: 35px;
          padding: 6px;

          > svg {
               width: 14px;
               height: 14px;
          }
     }
`;
