import { NearestStoreListWrap, ImageWrap, NewWrap } from "./NearestStore.styled";

const NearestTobaccoStoreV2 = ({ nearStoreFromActive, onClickStore }) => {
     const getIcon = (managementNumber, businessStatusCode) => {
          const svgName = businessStatusCode === "01" ? "check" : businessStatusCode === "02" ? "suspended" : "closed";

          if (managementNumber === null) {
               return (
                    <div
                         className="checked"
                         style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: 2,
                              width: 26,
                              height: 26,
                              background: "#57CC98",
                              borderRadius: "50%",
                         }}
                    >
                         <img src={`img/icons/svg/u_edit_white.svg`} alt={svgName} />
                    </div>
               );
          }

          return <img src={`img/icons/svg/u_${svgName}.svg`} alt={svgName} />;
     };

     return (
          <NearestStoreListWrap left={nearStoreFromActive?.position?.left ?? 0} bottom={nearStoreFromActive?.position?.bottom ?? 0}>
               <ul>
                    {nearStoreFromActive?.stores.map((store, i) => {
                         const newColor = !store.managementNumber
                              ? "#57CC98"
                              : store.businessStatusCode === "01"
                                ? "#216CF0"
                                : store.businessStatusCode === "02"
                                  ? "#666666"
                                  : "#FC0402";

                         const isNewStore = store.recentMonthUpdated === "Y";

                         let showName = "";
                         if (store.businessName) {
                              showName = store.businessName;
                         } else {
                              showName = store?.fullRoadAddress ?? store?.fullLocationAddress;
                         }

                         if (showName.length > 12) {
                              showName = `${showName.slice(0, 11)}...`;
                         }

                         return (
                              <li
                                   key={store.managementNumber}
                                   className="marker-button-child-item"
                                   onClick={() => {
                                        onClickStore(store);
                                   }}
                              >
                                   <ImageWrap>
                                        {getIcon(store.managementNumber, store.businessStatusCode)}
                                        {isNewStore && <NewWrap color={newColor}>N</NewWrap>}
                                   </ImageWrap>

                                   <div>{showName}</div>
                              </li>
                         );
                    })}
               </ul>
          </NearestStoreListWrap>
     );
};

export default NearestTobaccoStoreV2;
