import styled from "@emotion/styled";
import { INITIAL_LAT, INITIAL_LNG } from "../../constants/map";
import IconCurrent from "../MobileFilterV2/svgComponents/IconCurrent";
import IconDirection from "../MobileFilterV2/svgComponents/IconDirection";

const { naver } = window;

const MobileTobaccoStoreRightButtons = ({
     handleChangeDirectionStatus,
     handleOpenBookmarked,
     map,
     tobaccoFilterOption,
     handleOpenDistance,
     updateTobaccoStoreList,
}) => {
     const handleClickCurrent = tobaccoFilterOption => {
          if (navigator?.geolocation) {
               navigator.geolocation.getCurrentPosition(
                    position => {
                         const golocationPosition = new naver.maps.LatLng(position.coords.latitude, position.coords.longitude);
                         map.current.setCenter(golocationPosition);

                         updateTobaccoStoreList(
                              position.coords.latitude,
                              position.coords.longitude,
                              tobaccoFilterOption.distance,
                              tobaccoFilterOption.includeClosedWithin,
                         );
                    },
                    error => {
                         window.alert("위치 정보 미동의: 현재 위치를 확인할 수 없습니다.");
                         return;
                         // console.log("위치 정보 미동의");

                         // updateTobaccoStoreList(INITIAL_LAT, INITIAL_LNG, tobaccoFilterOption.distance, tobaccoFilterOption.includeClosedWithin);

                         // console.error(error);
                    },
                    {
                         enableHighAccuracy: false,
                         maximumAge: 0,
                         timeout: Infinity,
                    },
               );
          } else {
               console.error("GPS를 지원하지 않습니다");
               updateTobaccoStoreList(INITIAL_LAT, INITIAL_LNG, tobaccoFilterOption.distance, tobaccoFilterOption.includeClosedWithin);
          }
     };

     return (
          <>
               <MobileStoreRightButtonsWrap>
                    <MobileBtnIconsWrap onClick={handleOpenBookmarked}>
                         <img src="img/icons/svg/u_star.svg" alt="star" />
                    </MobileBtnIconsWrap>
                    <MobileBtnIconsWrap onClick={handleChangeDirectionStatus}>
                         <IconDirection size={21} />
                    </MobileBtnIconsWrap>
                    <MobileBtnIconsWrap onClick={handleOpenDistance}>
                         <img src="img/icons/svg/meter.svg" alt="goToCurrentPosition" />
                    </MobileBtnIconsWrap>
                    <MobileBtnIconsWrap onClick={() => handleClickCurrent(tobaccoFilterOption)}>
                         <IconCurrent color={"black"} size={21} />
                    </MobileBtnIconsWrap>
               </MobileStoreRightButtonsWrap>
          </>
     );
};

export default MobileTobaccoStoreRightButtons;

const MobileStoreRightButtonsWrap = styled.div`
     position: fixed;
     top: 120px;
     right: 10px;
     z-index: 3;
     gap: 10px;
     display: flex;
     flex-direction: column;
`;

const MobileBtnIconsWrap = styled.div`
     width: 36px;
     height: 36px;
     background-color: #ffffff;
     border-radius: 50%;
     display: flex;
     justify-content: center;
     align-items: center;
     box-shadow: 0px 0.5px 10px 0px rgba(0, 0, 0, 0.18);
`;
