import styled from "@emotion/styled";
// svg
import { ReactComponent as Close } from "../../assets/close_white.svg";

const TopMessageAlertPc = ({ message, onClose, sidebarOpen }) => {
     return (
          <>
               <AlertWrap sidebarOpen={sidebarOpen}>
                    <ArrowBack onClick={onClose}>
                         <Close />
                    </ArrowBack>
                    {message}
               </AlertWrap>
          </>
     );
};

export default TopMessageAlertPc;

const ArrowBack = styled.div`
     cursor: pointer;
     display: flex;
     position: absolute;
     right: 10px;
     top: 10px;
`;

const AlertWrap = styled.div`
     box-sizing: border-box;
     position: fixed;
     top: 55px;
     left: ${({ sidebarOpen }) => (sidebarOpen ? "60%" : "40%")};
     z-index: 3;
     width: 500px;
     height: 70px;
     color: #fff;
     text-align: center;

     font-size: 12px;
     font-style: normal;
     font-weight: 700;
     line-height: 16px; /* 133.333% */
     letter-spacing: 0.12px;

     background-color: rgba(0, 0, 0, 0.5);
     text-align: center;
     white-space: pre-line;
     display: flex;
     justify-content: center;
     align-items: center;
`;
