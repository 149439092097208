import { MobileTobaccoDirectionWrap, DirectionResultWrap, MobileTobaccoDirectionBtnWrap } from "./PcDirection.styled";

const PcDirection = ({ directionStart, directionEnd, setDirectionStart, setDirectionEnd }) => {
     const handleClickDirection = () => {
          if (directionStart.coordinate && directionEnd.coordinate) {
               const url = `http://map.naver.com/index.nhn?slng=${directionStart.coordinate.x}&slat=${directionStart.coordinate.y}&stext=${directionStart.name ?? "출발지"}&elng=${directionEnd.coordinate.x}&elat=${directionEnd.coordinate.y}&etext=${directionEnd.name ?? "도착지"}&menu=route&pathType=3`;
               window.open(url, "_blank");
          }
     };

     const handleChangeDirection = () => {
          const currentStart = { ...directionStart };
          const currentEnd = { ...directionEnd };

          setDirectionStart(currentEnd);
          setDirectionEnd(currentStart);
     };

     return (
          <>
               <MobileTobaccoDirectionWrap>
                    <div className="top">
                         <div>도보거리 계산하기</div>
                    </div>

                    <>
                         <DirectionResultWrap>
                              <div className="results">
                                   <div className="start">
                                        <div className="title">출발</div>
                                        <div className="contents">{directionStart.name ?? "지도에서 출발지를 선택해 주세요"}</div>
                                   </div>
                                   <div className="end">
                                        <div className="title">도착</div>
                                        <div className="contents">{directionEnd.name ?? "지도에서 도착지를 선택해 주세요"}</div>
                                   </div>
                              </div>

                              <div className="change-btn" onClick={handleChangeDirection}>
                                   <img src="img/icons/svg/u_exchange.svg" alt="exchange" width={22} />
                              </div>
                         </DirectionResultWrap>
                         <MobileTobaccoDirectionBtnWrap>
                              <div onClick={handleClickDirection}>길찾기</div>
                         </MobileTobaccoDirectionBtnWrap>
                    </>
               </MobileTobaccoDirectionWrap>
          </>
     );
};

export default PcDirection;
